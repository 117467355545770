import React, { Fragment } from "react";

export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.292893 22.2383L6.11369 16.4175L7.52791 17.8317L1.70711 23.6525C1.31658 24.0431 0.683418 24.0431 0.292893 23.6525C-0.097631 23.262 -0.0976312 22.6289 0.292893 22.2383Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9998 5.00122C8.13378 5.00122 4.99978 8.13523 4.99978 12.0012C4.99978 15.8672 8.13378 19.0012 11.9998 19.0012C15.8658 19.0012 18.9998 15.8672 18.9998 12.0012C18.9998 8.13523 15.8658 5.00122 11.9998 5.00122ZM11.9998 3.00122C7.02921 3.00122 2.99978 7.03066 2.99978 12.0012C2.99978 16.9718 7.02921 21.0012 11.9998 21.0012C16.9703 21.0012 20.9998 16.9718 20.9998 12.0012C20.9998 7.03066 16.9703 3.00122 11.9998 3.00122Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.706882 18.2928C1.09741 17.9023 1.73057 17.9023 2.1211 18.2928L5.70688 21.8786C6.09741 22.2691 6.09741 22.9023 5.70688 23.2928C5.31636 23.6834 4.68319 23.6834 4.29267 23.2928L0.706882 19.707C0.316358 19.3165 0.316358 18.6834 0.706882 18.2928Z"
    />
  </>
);
