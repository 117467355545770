import React, { Fragment } from "react";

export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.60958 11.6877C6.78209 11.472 7.09674 11.4371 7.31237 11.6096L12 15.3597L16.6877 11.6096C16.9033 11.4371 17.2179 11.472 17.3905 11.6877C17.563 11.9033 17.528 12.2179 17.3124 12.3905L12.3124 16.3905C12.1298 16.5365 11.8703 16.5365 11.6877 16.3905L6.68767 12.3905C6.47204 12.2179 6.43708 11.9033 6.60958 11.6877Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0557 16C11.7795 16 11.5557 15.7761 11.5557 15.5L11.5557 6.5C11.5557 6.22386 11.7795 6 12.0557 6C12.3318 6 12.5557 6.22386 12.5557 6.5L12.5557 15.5C12.5557 15.7761 12.3318 16 12.0557 16Z"
    />
  </>
);
