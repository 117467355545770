import { observable, action } from "mobx"

class ToastStore {
  @observable
  toasts = []

  @action addToast(toast) {
    this.toasts = [...this.toasts, toast]
  }

  @action clearToasts() {
    this.toasts = []
  }

  @action deleteToast(toastId) {
    const toastRemoved = this.toasts.filter(toast => toast.id !== toastId)
    this.toasts = toastRemoved
  }
}

export default new ToastStore()
