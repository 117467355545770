import { useEffect, useState } from "react"
import gql from "graphql-tag"

const VIDEO_SEARCH = gql`
  query VIDEO_SEARCH(
    $query: SearchPostsInput!
    $sort: SearchSort
    $limit: Int!
    $offset: Int!
  ) {
    searchPosts(query: $query, sort: $sort, limit: $limit, offset: $offset) {
      rows
      entities {
        ... on Post {
          title
          tags
          thumbnail
          durationInSeconds
          provider
          postId
          views
          postUrl
          engagement
          creator {
            ... on Creator {
              title
              thumbnail
            }
          }
        }
      }
    }
  }
`

const useVideoSearch = function({
  client,
  searchTerm,
  topicIds,
  minEngagement,
  publishedAt,
  limit,
  offset,
  sortBy,
  country,
  providers = [
    {
      label: "Instagram",
      value: "in"
    },
    {
      label: "Youtube",
      value: "yt"
    }
  ],
  detectedLanguage = null,
  notInTopicId = null
}) {
  const [videos, setVideos] = useState([])
  const [loadingVideos, setLoadingVideos] = useState(false)
  const [minEngagementState, setMinEngagement] = useState(minEngagement)
  const [rows, setRows] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [noResults, setNoResults] = useState(false)

  useEffect(() => {
    setMinEngagement(minEngagement)
  }, [minEngagement])

  useEffect(() => {
    async function getVideosQuery() {
      try {
        if (!searchTerm) return
        if (loadingVideos) return
        if (minEngagementState === null) return
        setNoResults(false)
        setHasMore(true)
        setVideos([])
        setRows(0)

        const query = VIDEO_SEARCH
        const variables = {
          ...(sortBy.value && { sort: { field: sortBy.value, order: "desc" } }),
          query: {
            ...(detectedLanguage !== "none" ? { detectedLanguage } : null),
            ...(notInTopicId && { notInTopicId }),
            ...(country && { country }),
            ...(providers.length && { providers: providers.map(p => p.value) }),
            ...(publishedAt && { publishedAt }),
            title: searchTerm,
            topicIds
          },
          limit,
          offset: 0
        }

        setLoadingVideos(true)

        const {
          data: { searchPosts }
        } = await client.query({
          query,
          variables
        })

        setLoadingVideos(false)

        const { entities, rows } = searchPosts
        if (!entities.length) setNoResults(true)
        setRows(rows)
        setVideos(entities || [])

        minEngagement >= 0 && setMinEngagement(null)
      } catch (e) {
        console.error("error getting Videos >>>>>>>>>>>", e)
        setLoadingVideos(false)
      }
    }
    getVideosQuery()
  }, [
    searchTerm,
    topicIds,
    minEngagementState,
    sortBy,
    notInTopicId,
    detectedLanguage,
    providers,
    publishedAt,
    country
  ])

  useEffect(() => {
    if (!searchTerm) return

    async function paginateVideoSearch() {
      try {
        setLoadingVideos(true)

        const query = VIDEO_SEARCH
        const variables = {
          ...(sortBy.value && { sort: { field: sortBy.value, order: "desc" } }),
          query: {
            ...(detectedLanguage !== "none" ? { detectedLanguage } : null),
            ...(notInTopicId && { notInTopicId }),
            ...(country && { country }),
            ...(providers.length && { providers: providers.map(p => p.value) }),
            title: searchTerm,
            topicIds,
            publishedAt
          },
          limit,
          offset
        }

        const {
          data: { searchPosts }
        } = await client.query({
          query,
          variables
        })
        setLoadingVideos(false)

        const { entities } = searchPosts

        const hasMore = !(offset > 0 && entities.length === 0)

        setHasMore(hasMore)
        setVideos([...videos, ...entities])
      } catch (e) {
        console.error("error @searchPosts >>>>>>>>>>>", e)
        setLoadingVideos(false)
      }
    }
    paginateVideoSearch()
  }, [offset])

  return {
    videos,
    loadingVideos,
    rows,
    hasMore,
    noResults
  }
}

export default useVideoSearch
