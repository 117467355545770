import Keycloak from "./keycloak"
import getEnvFromLocation from "./getEnvFromLocation"
import { REALMS, CLIENT_IDS, ROLES, ENDPOINT } from "../consts"

const env = getEnvFromLocation()

export const keycloak = new Keycloak({
  url: ENDPOINT[env],
  realm: REALMS[env],
  clientId: CLIENT_IDS[env]
})

export const keycloakProviderProps = {
  keycloak,
  initConfig: { onLoad: "check-sso" }
}

export const getRole = () => {
  const userRoles = keycloak.realmAccess.roles
  const defaultRole = ROLES[2] // Viewer
  const role = ROLES.find(role => userRoles.includes(role.value)) || defaultRole

  return role
}
