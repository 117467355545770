import React, { Fragment } from "react";

export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 1H2C1.44772 1 1 1.44772 1 2V4C1 4.55229 1.44772 5 2 5H22C22.5523 5 23 4.55228 23 4V2C23 1.44772 22.5523 1 22 1ZM2 0C0.895431 0 0 0.89543 0 2V4C0 5.10457 0.89543 6 2 6H22C23.1046 6 24 5.10457 24 4V2C24 0.895431 23.1046 0 22 0H2Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 10H2C1.44772 10 1 10.4477 1 11V13C1 13.5523 1.44772 14 2 14H22C22.5523 14 23 13.5523 23 13V11C23 10.4477 22.5523 10 22 10ZM2 9C0.895431 9 0 9.89543 0 11V13C0 14.1046 0.89543 15 2 15H22C23.1046 15 24 14.1046 24 13V11C24 9.89543 23.1046 9 22 9H2Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 19H2C1.44772 19 1 19.4477 1 20V22C1 22.5523 1.44772 23 2 23H22C22.5523 23 23 22.5523 23 22V20C23 19.4477 22.5523 19 22 19ZM2 18C0.895431 18 0 18.8954 0 20V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V20C24 18.8954 23.1046 18 22 18H2Z"
    />
  </>
);
