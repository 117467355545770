import { dissoc } from "ramda"
import dayjs from "dayjs"
import formatDuration from "./formatDuration"
import Consts from "../constants"
import LinkTo from "./link-to"

const { Providers } = Consts

const keysMap = {
  rank: "Rank",
  name: "Name",
  title: "Title",
  topic: "Topic",
  topics: "Topics",
  topicId: "Topic Id",
  mainTopic: "Main Topic",
  index: "Index",
  audienceIndex: "Topic Audience Size",
  category: "Category",
  avgViews: "Average Views",
  average_views: "Average Views",
  avgEngagement: "Average Engagement",
  average_engagement: "Average Engagement",
  engagement: "Engagement",
  posts: "Posts",
  views: "Views",
  likes: "Likes",
  dislikes: "Dislikes",
  comments: "Comments",
  creators: "Creators",
  brands: "Brands",
  subscribers: "Subscribers",
  videos: "Videos",
  related_topics: "Related Topics",
  postUrl: "Post URL",
  publishedAt: "Published At",
  durationInSeconds: "Duration",
  provider: "Platform",
  creatorUrl: "Creator URL",
  audienceFilter: "Selected Audience",
  dateRange: "Date Range",
  language: "Language",
  topicUrl: "Topic URL",
  detectedLanguage: "Language",
  creatorName: "Creator",
  detailing: "Detailing",
  formats: "Formats",
  keyword: "Keywords",
  categories: "Categories",
  otherTopics: "Other topics"
}

const renameKeys = (_keysMap, obj) => {
  Object.keys(_keysMap).map(oldKey => {
    const renamedKey = _keysMap[oldKey]
    const value = obj[oldKey]
    if (obj.hasOwnProperty(oldKey)) {
      delete obj[oldKey]
      obj[renamedKey] = value
    }
    return obj
  })
  return obj
}

const FormatCSVRows = list => {
  if (!Array.isArray(list)) return
  return list
    .filter(item => !!Object.keys(item).length)
    .map(post => {
      let row = { ...dissoc("__typename", post) }

      if (row.avgEngagement) {
        row.avgEngagement = Number(row.avgEngagement).toFixed(0)
      }
      if (row.avgViews) {
        row.avgViews = Number(row.avgViews).toFixed(0)
      }
      if (row.audienceIndex) {
        row.audienceIndex = Number(row.audienceIndex).toFixed(1)
      }
      if (row.average_views) {
        row.average_views = Number(row.average_views).toFixed(0)
      }
      if (row.engagement) {
        row.engagement = Number(row.engagement).toFixed(0)
      }
      if (row.publishedAt) {
        row.publishedAt = dayjs(post.publishedAt).format("DD-MM-YYYY")
      }
      if (row.durationInSeconds) {
        row.durationInSeconds = formatDuration(row.durationInSeconds)
      }
      if (row.provider) {
        row.provider = Providers[row.provider] && Providers[row.provider].NAME
      }

      if (row.topics) {
        const { topics } = row
        row.topics = topics.map(t => t.title).join(" | ")
        // put all cat on array
        row.categories = topics.reduce((acc, t) => {
          if (t.category && t.category.title) {
            acc.push(t.category.title)
          }
          return acc
        }, [])
        // filter unique values
        row.categories = row.categories
          .filter((cat, i, self) => self.indexOf(cat) === i)
          .join(" | ")
      }

      if (row.formats) {
        row.formats = row.formats.map(f => f.name).join(" | ")
      }

      if (row.mainTopic) {
        row.mainTopic = row.mainTopic.title
      }

      // quando vo estiver em dúvida do que foi feito aqui, pergunte a galera de estratégia
      if (row.otherTopics) {
        if (row.otherTopics.length === 1) {
          row.mainTopic = row.otherTopics[0]
          if (!row.categories) {
            row.categories = row.otherTopics[0].split(" - ")[0]
          }
        } else {
          row.otherTopics = row.otherTopics.join(" | ")
        }
      }

      if (row.keyword) {
        row.keyword = row.keyword.join(" | ")
      }

      if (Object.keys(row).indexOf("creator") > 0) {
        const hasCreatorUrl =
          row.creator && !!row.creator.groupId && !!row.creator.creatorId
        if (hasCreatorUrl) {
          row.creatorUrl = `https://insights.winnin.com${LinkTo.Creator(
            row.creator
          )}`
        } else {
          row.creatorUrl = "-"
        }
        row = dissoc("creator", row)
      }
      row = renameKeys(keysMap, row)
      return row
    })
}

export default FormatCSVRows
