import gql from "graphql-tag"

export const SUBTOPIC_QUERY = gql`
  query getSubtopics($subtopicIds: [Int!]!) {
    getSubtopics(subtopicIds: $subtopicIds) {
      subtopicId
      name
      term
      title
      language

      topic {
        title
        topicId

        category {
          title
        }
      }

      tags {
        title
      }
    }
  }
`

export const POSTS_QUERY = gql`
  query searchPosts(
    $query: SearchPostsInput!
    $lastValue: String
    $lastPostId: String
  ) {
    searchPosts(
      query: $query
      lastValue: $lastValue
      lastPostId: $lastPostId
      sort: { field: "views", order: "desc" }
      offset: 0
      limit: 36
    ) {
      entities {
        ... on Post {
          postUrl
          postId
          thumbnail
          title
          creatorTitle
          topics {
            title
          }
          publishedAt
          provider
          engagement
          views
          likes
        }
      }

      rows
    }
  }
`

export const SUBTOPIC_EDIT_MUTATION = gql`
  mutation subtopicEdit(
    $id: Int!
    $name: String!
    $subtopicId: Int!
    $searchTerm: String!
  ) {
    renameSubtopic(id: $id, name: $name) {
      success
      message
    }

    editSearchTerm(subtopicId: $subtopicId, searchTerm: $searchTerm) {
      success
      message
    }
  }
`
