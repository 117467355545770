import React, { Fragment } from "react";

export default (
  <>
    <path
      id="move-icon"
      d="M8.32 13L3 18.333l5.32 5.333v-4h9.347V17H8.32zM27 10.333L21.68 5v4h-9.347v2.667h9.347v4z"
      transform="translate(-3 0)"
    />
  </>
);
