import { observable, action } from "mobx"
import path from "ramda/src/path"

class UserStore {
  @observable userInfo = {}

  @observable userLanguageOptions = []
  @observable languageDefault = null
  @observable isFirstAccess = false

  @action setUser({ user, permissions }) {
    const userActiveBrands = user?.brands?.filter(brand => brand.status) || []

    this.userInfo = {
      ...user,
      brands: userActiveBrands
    }
  }

  @action updateUserInfo(newInfo) {
    this.userInfo = {
      ...this.userInfo,
      ...newInfo
    }
  }

  @action hasUserInfo() {
    return !!Object.keys(this.userInfo).length
  }

  @action get(key) {
    if (typeof key === "string") return path([key], this.userInfo)
    return path(key, this.userInfo)
  }
}

export default new UserStore()
