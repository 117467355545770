import React from "react"
import { useMutation } from "@apollo/react-hooks"
import PropTypes from "prop-types"
import { Button } from "../../shared/shared.ui.button"
import { Header } from "../../shared/shared.ui.header"
import CenteredModal from "../CenteredModal"
import ToastStore from "../../stores/ToastStore"
import { queryStatus } from "../../consts/queryStatus"
import { DELETE_QUERY_MUTATION } from "./query"
import { client } from "../../ApolloProvider"
import "./index.scss"

const QueryDeleteModal = ({ history, location: { state } }) => {
  // consts
  const { backgroundPage, queryObj } = state

  // queries and mutations
  const [deleteQuery, { data, loading, error }] = useMutation(
    DELETE_QUERY_MUTATION,
    {
      client,
      onCompleted: () => {
        history.push({
          pathname: backgroundPage.pathname,
          state: { refetch: true }
        })

        ToastStore.clearToasts()
        ToastStore.addToast({
          title: "Query deleted.",
          id: "delete-query-success"
        })
      },
      onError: error => {
        console.error(`QueryDeleteModal @ deleteQuery >>>>> ${error}`)
      }
    }
  )

  // functions
  const closeModal = () => {
    history.push({
      pathname: backgroundPage.pathname
    })
  }

  const handleQueryDelete = () => {
    const variables = {
      queryIdStr: queryObj.id,
      status: queryStatus.deleted
    }

    deleteQuery({ variables })
  }

  // rendering
  return (
    <CenteredModal className="QueryDeleteModal" closeModal={closeModal}>
      <Header className="delete-title" size="md">
        Delete query?
      </Header>
      <p>You will not be able to recover it</p>

      <div className="delete-actions">
        <Button disabled={loading} onClick={handleQueryDelete}>
          {loading ? "Deleting..." : "Delete query"}
        </Button>
        <Button disabled={loading} appearance="outline" onClick={closeModal}>
          Cancel
        </Button>
      </div>

      {(error || data?.updateTopicQueryClassifier?.success === false) && (
        <p className="delete-error">
          Oops! An error occurred. Please, try deleting again.
        </p>
      )}
    </CenteredModal>
  )
}

QueryDeleteModal.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default QueryDeleteModal
