import React, { useState, useEffect } from "react"
import { compose, withApollo } from "react-apollo"
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks"
import { Loading } from "../../shared/shared.ui.loading"

import { SUBTOPIC_QUERY, POSTS_QUERY, SUBTOPIC_EDIT_MUTATION } from "./query.js"
import { LANGUAGE_OPTIONS, PAGINATION } from "../../consts"
import { getRole } from "../../utils"
import AppHeader from "../../components/AppHeader"
import SimplePostGrid from "../../components/SimplePostGrid"
import SubtopicEditHeader from "../../components/SubtopicEditHeader"
import ToastStore from "../../stores/ToastStore"
import "./index.scss"

const SubtopicEditPage = ({ client, history, location, match }) => {
  const subtopicId = Number(match.params.subtopicId)

  // local state
  const [hasMore, setHasMore] = useState(true)
  const [inputState, setInputState] = useState({ name: "", searchTerm: "" })
  const [initialName, setInitialName] = useState("")
  const [initialTerm, setInitialTerm] = useState("")
  const [searchTerm, setSearchTerm] = useState("")

  // queries and mutations
  const {
    data: subtopicData,
    loading: subtopicLoading,
    error: subtopicError,
    refetch: subtopicRefetch
  } = useQuery(SUBTOPIC_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    client,
    variables: {
      subtopicIds: [subtopicId]
    },
    onCompleted: ({ getSubtopics }) => {
      const { name, term } = getSubtopics[0]

      setInitialName(name)
      setInitialTerm(term)
      setSearchTerm(term)
      setInputState({
        name,
        searchTerm: term
      })
    }
  })
  const [
    loadPosts,
    {
      data: postsData,
      loading: postsLoading,
      error: postsError,
      networkStatus: postsStatus,
      fetchMore: postsFetchMore
    }
  ] = useLazyQuery(POSTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    client,
    onCompleted: ({ searchPosts: { entities, rows } }) => {
      const postsCount = entities.length
      const limit = PAGINATION.PAGE_SIZE
      const hasMore = postsCount >= limit && postsCount < rows

      setHasMore(hasMore)
    }
  })
  const [subtopicEdit] = useMutation(SUBTOPIC_EDIT_MUTATION, {
    client,
    onError(e) {
      console.error("SubtopicEditPage @ subtopicEdit >>>>>", e)

      ToastStore.clearToasts()
      ToastStore.addToast({
        title: "Oops! An error ocurred. Please try again.",
        id: "error"
      })
    },
    onCompleted({
      renameSubtopic: { success: nameUpdated },
      editSearchTerm: { success: searchTermUpdated }
    }) {
      if (!nameUpdated || !searchTermUpdated) {
        const notUpdated =
          !nameUpdated && !searchTermUpdated
            ? "Subtopic"
            : !nameUpdated
            ? "Name"
            : "Search Term"

        ToastStore.clearToasts()
        ToastStore.addToast({
          title: `Oops! ${notUpdated} was not updated. Please try again.`,
          id: "edit-error"
        })
      } else {
        history.push({
          pathname: "/subtopic/success/change",
          state: { backgroundPage: location }
        })
      }
    }
  })

  // effects
  useEffect(() => {
    // on search term change, fetch posts
    if (subtopicLoading) return

    try {
      const title = searchTerm
      const topicIds = [subtopicData?.getSubtopics?.[0]?.topic?.topicId]
      const variables = {
        query: { title, topicIds }
      }
      loadPosts({ variables })
    } catch (e) {
      console.error("SubtopicEditPage @ on subtopic load useEffect >>>>>", e)
    }
  }, [searchTerm, subtopicLoading])

  useEffect(() => {
    // on "successful change modal" closing, refetch subtopic
    if (location?.state?.refetch) {
      subtopicRefetch()
    }
  }, [location])

  // functions
  const loadMore = async () => {
    if (postsLoading || postsError || !hasMore) return

    const posts = postsData.searchPosts.entities
    const lastPostIndex = posts.length - 1
    const lastPost = posts[lastPostIndex]

    const lastPostId = lastPost.postId
    const lastValue = lastPost.views.toString()

    const variables = {
      lastPostId,
      lastValue
    }

    try {
      await postsFetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.searchPosts?.entities?.length) {
            return prev
          }

          if (
            fetchMoreResult.searchPosts.entities.length < PAGINATION.PAGE_SIZE
          ) {
            setHasMore(false)
          }

          return Object.assign({}, prev, {
            searchPosts: {
              ...prev.searchPosts,
              entities: [
                ...prev.searchPosts.entities,
                ...fetchMoreResult.searchPosts.entities
              ]
            }
          })
        }
      })
    } catch (e) {
      console.error("SubtopicEditPage @ loadMore >>>>>", e)
    }
  }
  const getSubtopicLanguage = languageInitials => {
    const languageOption = LANGUAGE_OPTIONS.find(
      languageOption => languageOption.value === languageInitials
    )

    return languageOption?.label || "Global"
  }
  const handleInputChange = (value, name) => {
    setInputState(inputState => ({
      ...inputState,
      [name]: value
    }))
  }
  const handlePostsSearch = value => {
    setSearchTerm(value)
  }
  const handleDelete = () => {
    const { name, subtopicId } = subtopicData?.getSubtopics?.[0]

    history.push({
      pathname: "/subtopic/delete",
      state: { backgroundPage: location, subtopicId, name }
    })
  }
  const handleSave = () => {
    const variables = {
      id: subtopicId,
      name: inputState.name,
      subtopicId,
      searchTerm: inputState.searchTerm
    }

    subtopicEdit({
      variables
    })
  }
  const onReset = () => {
    setSearchTerm(initialTerm)
    setInputState({
      name: initialName,
      searchTerm: initialTerm
    })
  }

  // rendering
  const renderPage = () => {
    if (subtopicLoading) return <Loading />
    if (subtopicError) return <p>Error :(</p>

    const role = getRole()
    const posts = postsData?.searchPosts?.entities
    const postsTotal = postsData?.searchPosts?.rows
    const subtopic = subtopicData.getSubtopics[0]
    const {
      tags,
      language,
      topic: {
        title: topicTitle,
        category: { title: categoryTitle }
      }
    } = subtopic
    const subtopicLanguage = getSubtopicLanguage(language)
    const tagList = tags.map(tag => tag.title).join(", ")
    const isResetDisabled =
      inputState.name === initialName && inputState.searchTerm === initialTerm
    const isSaveDisabled =
      isResetDisabled ||
      !inputState.name.length ||
      !inputState.searchTerm.length

    return (
      <>
        <div className="page-header">
          <SubtopicEditHeader
            {...{
              handleInputChange,
              handlePostsSearch,
              handleDelete,
              handleSave,
              onReset,
              initialName,
              initialTerm,
              isResetDisabled,
              isSaveDisabled,
              tagList,
              topicTitle,
              categoryTitle,
              subtopicLanguage,
              inputState,
              role
            }}
          />
        </div>

        <div className="page-content">
          <SimplePostGrid
            {...{
              posts,
              postsTotal,
              loadMore,
              hasMore,
              loading: postsLoading,
              networkStatus: postsStatus,
              error: postsError
            }}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <AppHeader />
      <div className="SubtopicEditPage">{renderPage()}</div>
    </>
  )
}

export default compose(withApollo)(SubtopicEditPage)
