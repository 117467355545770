import gql from "graphql-tag"

export const GET_POSTS_TO_VERIFY = gql`
  query($topicId: Int!, $limit: Int! = 36) {
    getPostsToVerify(topicId: $topicId, limit: $limit) {
      postId
      title
      provider
      creatorId
      creatorTitle
      thumbnail
      publishedAt
      durationInSeconds
      tags
      postUrl
    }
  }
`
