import gql from "graphql-tag"

export const QUERIES_LIST_QUERY = gql`
  query statusTab_queriesListQuery($status: [String!]!) {
    listTopicQueryClassifiers(status: $status) {
      id
      topic {
        title
        topicId
      }
      user {
        id
        name
      }
      query
      status
      createdAt
      updatedAt
      status
      feedback
    }
  }
`
