import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

const Textarea = ({ className, ...props }) => {
  return (
    <div className={classnames("Textarea Insights-ui__input", className)}>
      <textarea {...props}></textarea>
    </div>
  )
}

Textarea.propTypes = {
  className: PropTypes.string
}

export default Textarea
