import gql from "graphql-tag"

const allTopicsQuery = (fields, customVariables) => `
    query allTopicsQuery($language: String! ${customVariables &&
      customVariables} ){
        allTopics(
            language: $language
        ){
            entities {
                ${fields}
            }
        }
    }
`

const allTopicGroupsQuery = (fields, customVariables) => `
  query allTopicGroupsQuery($language: String! ${customVariables &&
    customVariables}) {
    allTopicGroups(language: $language) {
        ${fields}
    }
  }
`

const topicByGroupQuery = (fields, customVariables) => `
  query topicByGroupQuery(
    $groupIds: [Int!]! ${customVariables && customVariables}
  ){
    topicByGroup(groupIds: $groupIds){
      ${fields}
    }
  }
`

const searchPostsQuery = (fields, customVariables) => `
query searchPostsQuery(
    $query: SearchPostsInput!
    $sort: SearchSort
    $withAggregation: Boolean! = false
    $limit: Int = 50
    $offset: Int! = 0
    ${customVariables && customVariables}
){
  searchPosts(
    query: $query,
    sort: $sort,
    withAggregation: $withAggregation,
    limit: $limit,
    offset: $offset
  ){
    ${fields}
  }
}
`

const creatorsQuery = (fields, customVariables) => `
query creatorsQuery(
    $creatorsInfo: [ExternalEntityInput!]!
    ${customVariables && customVariables}
){
  creators(
    creatorsInfo: $creatorsInfo
    ){
      ${fields}
    }
  }
`

const postsQuery = (fields, customVariables) => `
query postsQuery(
    $postsInfo: [ExternalEntityInput!]!
    ${customVariables && customVariables}
){
  posts(
    postsInfo: $postsInfo
    ){
      ${fields}
    }
  }
`

const createProjectMutation = (fields, customVariables) => `
mutation createProjectMutation(
  $title: String!
  $summary: String
  $createdBy: String!
  ${customVariables && customVariables}
){
  createProject(
    title: $title
    summary: $summary
    createdBy: $createdBy
  ){
      ${fields}
    }
  }
`

const formatGroupsQuery = fields => `
  query formatGroupsQuery{
    formatGroups{
        ${fields}
      }
    }
  `

const tagPostMutation = () => `
  mutation tagPostMutation(
    $postId: String!
    $provider: String!
    $detectedLanguage: String!
    $topicIds: [Int!]
    $formatIds: [Int!]
    $projectId: Int!
    $mainTopic: Int
    $keywords: [String]
    $detailing: String = ""
    $otherTopics: [String] = []
  ){
    tagPost(
      postId: $postId
      provider: $provider
      detectedLanguage: $detectedLanguage
      topicIds: $topicIds
      formatIds: $formatIds
      projectId: $projectId
      mainTopic: $mainTopic
      keywords: $keywords
      detailing: $detailing
      otherTopics: $otherTopics
    )
    }
  `

const renewSessionMutation = fields => `
  mutation renewSessionMutation($token: String!) {
    renewSession(token: $token) {
        ${fields}
    }
  }
`
const loginMutation = fields => `
  mutation loginMutation($provider: String!, $credentials: SocialLoginCredentials!) {
    login(provider: $provider, credentials: $credentials) {
        ${fields}
    }
  }
`

const logoutMutation = () => `
  mutation logoutMutation {
    logout
  }
`

const signMutation = fields => `
  mutation signMutation($email: String!, $password: String!, $type: String! = "signIn", $accessToken: String) {
    sign(type: $type, accessToken: $accessToken, email: $email, password: $password) {
        ${fields}
    }
  }
`

const taggedVideosFromProjectQuery = fields => `
  query taggedVideosFromProjectQuery($projectId: Int!) {
    taggedVideosFromProject(projectId: $projectId){
      ${fields}
    }
  }
`

const generateFields = (fields, customVariables, query) => gql`
  ${query(fields, customVariables)}
`

class GqlOperation {
  constructor({ type, query }) {
    this.type = type
    this.query = query
  }

  fields(fields, customVariables = "") {
    return generateFields(fields, customVariables, this.query)
  }
}

export default {
  allTopics: new GqlOperation({ type: "query", query: allTopicsQuery }),
  allTopicGroups: new GqlOperation({
    type: "query",
    query: allTopicGroupsQuery
  }),
  topicByGroup: new GqlOperation({ type: "query", query: topicByGroupQuery }),
  searchPosts: new GqlOperation({ type: "query", query: searchPostsQuery }),
  creators: new GqlOperation({ type: "query", query: creatorsQuery }),
  posts: new GqlOperation({ type: "query", query: postsQuery }),
  createProject: new GqlOperation({
    type: "mutation",
    query: createProjectMutation
  }),
  formatGroups: new GqlOperation({ type: "query", query: formatGroupsQuery }),
  tagPost: new GqlOperation({ type: "mutation", query: tagPostMutation }),
  renewSession: new GqlOperation({
    type: "mutation",
    query: renewSessionMutation
  }),
  login: new GqlOperation({ type: "mutation", query: loginMutation }),
  logout: new GqlOperation({ type: "mutation", query: logoutMutation }),
  sign: new GqlOperation({ type: "mutation", query: signMutation }),
  taggedVideosFromProject: new GqlOperation({
    type: "query",
    query: taggedVideosFromProjectQuery
  })
}
