import React, { Fragment } from "react";

export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 17.4V7C7 5.89543 7.89543 5 9 5H15C16.1046 5 17 5.89543 17 7V17.4C17 18.4713 15.777 19.0828 14.92 18.44L15.52 17.64C15.7178 17.7883 16 17.6472 16 17.4V7C16 6.44772 15.5523 6 15 6H9C8.44772 6 8 6.44772 8 7V17.4C8 17.6472 8.28223 17.7883 8.48 17.64L11.4 15.45C11.7556 15.1833 12.2444 15.1833 12.6 15.45L15.52 17.64L14.92 18.44L12 16.25L9.08 18.44C8.22299 19.0828 7 18.4713 7 17.4Z"
    />
  </>
);
