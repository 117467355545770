import React, { useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import { Loading } from "../../shared/shared.ui.loading"

import CenteredRows from "../../components/CenteredRows"
import AppHeader from "../../components/AppHeader"
import { TopicContext } from "../../context/TopicContext"
import localStorageUtils from "../../utils/localStorageUtils"
import tagCicle from "../../assets/tag-circle.svg"
import "./index.scss"

const TAGGER_STATUS_KEY = "winninTaggerStatus"
const LIMIT = 250

const Top = () => {
  return (
    <div className="group top">
      <h1 className="heading color green">Are you Ready?</h1>
      <span className="sub-heading color white">Topic to tag:</span>
    </div>
  )
}

const Middle = () => {
  return (
    <div className="group middle">
      <img src={tagCicle} className="circle" width="250" height="250" alt="" />
    </div>
  )
}

const Bottom = title => {
  return (
    <div className="group bottom">
      <h3 className="heading color green light">{title}</h3>
      <Link to={`/tagging`} className="btn green block">
        Start
      </Link>
    </div>
  )
}

const HomePage = () => {
  const { topic, loading, error, getNewTopic, updateTopic } = useContext(
    TopicContext
  )

  useEffect(() => {
    const taggerStatus = localStorageUtils.getObject(TAGGER_STATUS_KEY)

    if (!taggerStatus || !taggerStatus.topicId) {
      getNewTopic()
    }

    if (taggerStatus && taggerStatus.topicId) {
      const { topicId, count = 0, title } = taggerStatus

      if (topicId && count < LIMIT) {
        updateTopic({ topicId, count, title })
      }

      if (count >= LIMIT) {
        getNewTopic()
      }
    }
  }, [])

  return (
    <>
      <AppHeader fixed />
      {!error && loading && (
        <div className="home-page-loading bg-purple">
          <Loading medium />
        </div>
      )}

      {!error && !loading && (
        <CenteredRows
          className="home-page-container bg-purple"
          top={Top}
          middle={Middle}
          bottom={() => Bottom(topic.title)}
        />
      )}

      {error && !loading && (
        <div className="home-page-loading bg-purple">
          <h1
            style={{ display: "block", width: "100%", textAlign: "center" }}
            className="heading color green"
          >
            An error ocurred :(
          </h1>
        </div>
      )}
    </>
  )
}

export default HomePage
