import React from "react";

export default (
  <>
    <circle cx="10" cy="10" r="7" />
    <path
      d="M9.50284 12.6002C9.39884 12.6002 9.34684 12.5482 9.34684 12.4442V11.7722C9.34684 11.4282 9.43084 11.1482 9.59884 10.9322C9.76684 10.7162 10.0228 10.4722 10.3668 10.2002C10.5988 10.0162 10.7828 9.86019 10.9188 9.73219C11.0548 9.59619 11.1668 9.43619 11.2548 9.25219C11.3508 9.06819 11.3988 8.85219 11.3988 8.60419C11.3988 8.20419 11.2708 7.88419 11.0148 7.64419C10.7588 7.39619 10.4188 7.27219 9.99484 7.27219C9.56284 7.27219 9.21484 7.39619 8.95084 7.64419C8.69484 7.89219 8.56684 8.21619 8.56684 8.61619V8.95219C8.56684 9.04819 8.51484 9.09619 8.41084 9.09619L7.87084 9.07219C7.76684 9.07219 7.71484 9.02019 7.71484 8.91619V8.61619C7.71484 7.98419 7.92684 7.47219 8.35084 7.08019C8.77484 6.68819 9.33084 6.49219 10.0188 6.49219C10.6908 6.49219 11.2308 6.68419 11.6388 7.06819C12.0468 7.45219 12.2508 7.96419 12.2508 8.60419C12.2508 8.95619 12.1908 9.26019 12.0708 9.51619C11.9588 9.77219 11.8228 9.98019 11.6628 10.1402C11.5028 10.3002 11.2908 10.4842 11.0268 10.6922C10.7548 10.9002 10.5508 11.0842 10.4148 11.2442C10.2788 11.4042 10.2108 11.5962 10.2108 11.8202V12.4442C10.2108 12.5482 10.1588 12.6002 10.0548 12.6002H9.50284ZM9.82684 14.9762C9.62684 14.9762 9.45884 14.9122 9.32284 14.7842C9.18684 14.6482 9.11884 14.4842 9.11884 14.2922C9.11884 14.1082 9.18684 13.9482 9.32284 13.8122C9.46684 13.6762 9.63484 13.6082 9.82684 13.6082C10.0188 13.6082 10.1868 13.6762 10.3308 13.8122C10.4748 13.9482 10.5468 14.1082 10.5468 14.2922C10.5468 14.4842 10.4748 14.6482 10.3308 14.7842C10.1868 14.9122 10.0188 14.9762 9.82684 14.9762Z"
      fill="white"
    />
  </>
);
