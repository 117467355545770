import gql from "graphql-tag"

export const TOPICS_QUERY = gql`
  query allTopics(
    $title: String
    $categoryId: Int
    $language: String
    $offset: Int
    $limit: Int
    $sort: SearchSort
  ) {
    allTopics(
      title: $title
      categoryId: $categoryId
      language: $language
      offset: $offset
      limit: $limit
      sort: $sort
    ) {
      entities {
        analytics {
          creators
          posts
        }

        title
        topicId

        category {
          title
          topicGroupId
        }
      }

      rows
    }
  }
`

export const CATEGORIES_QUERY = gql`
  query allTopicGroups($language: String!) {
    allTopicGroups(language: $language) {
      topicGroupId
      title
      topics {
        topicId
      }
    }
  }
`
