import gql from "graphql-tag"

export const CREATOR_QUERY = gql`
  query CREATOR_QUERY($creatorsInfo: [ExternalEntityInput!]!) {
    creators(creatorsInfo: $creatorsInfo) {
      country
      title
      thumbnail
      provider
    }
  }
`

export const CREATOR_VIDEOS_QUERY = gql`
  query CREATOR_VIDEOS_QUERY(
    $query: SearchPostsInput!
    $sort: SearchSort!
    $lastValue: String
    $lastPostId: String
  ) {
    searchPosts(
      query: $query
      sort: $sort
      limit: 36
      offset: 0
      lastValue: $lastValue
      lastPostId: $lastPostId
    ) {
      entities {
        ... on Post {
          postId
          postUrl
          title
          views
          likes
          comments
          publishedAt
          categoryId
          tags
          thumbnail
          provider

          topics {
            title
            topicId
          }
        }
      }

      rows
    }
  }
`

export const ALL_TOPICS_QUERY = gql`
  query ALL_TOPICS_QUERY {
    allTopicsWithoutLanguage {
      title
      topicId
      language
    }
  }
`

export const TOPICS_BY_LANGUAGE_QUERY = gql`
  query TOPICS_BY_LANGUAGE_QUERY($language: String) {
    allTopics(language: $language) {
      entities {
        title
        topicId
        language
      }
    }
  }
`

export const TAG_POSTS_MUTATION = gql`
  mutation TAG_POSTS_MUTATION(
    $addPostIds: [String!]
    $removePostIds: [String!]
    $provider: String!
    $topicId: Int!
  ) {
    addListPostsToTopic(
      postIds: $addPostIds
      provider: $provider
      topicId: $topicId
    ) {
      postId
      topicId
      approved
    }

    removeListPostsFromTopic(
      postIds: $removePostIds
      provider: $provider
      topicId: $topicId
    ) {
      postId
      topicId
      approved
    }
  }
`
