import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./index.scss";

export class Input extends Component {
  input = React.createRef();

  onKeyUp = e => {
    const { onEnter } = this.props;
    if (e.key === "Enter") {
      if (onEnter) onEnter(e.target.value, this.input);
    }
  };

  createRef = i => {
    const { inputRef } = this.props;
    this.input = i;
    inputRef(i);
  };

  render() {
    const { className, type, onChange, placeholder } = this.props;
    const classes = classNames("Insights-ui__input", className);

    return (
      <div className={classes}>
        {type === "textarea" ? (
          <textarea
            placeholder={placeholder}
            onKeyUp={this.onKeyUp}
            onChange={onChange}
            ref={this.createRef}
          />
        ) : (
          <input
            type={type}
            placeholder={placeholder}
            onKeyUp={this.onKeyUp}
            onChange={onChange}
            ref={this.createRef}
          />
        )}
      </div>
    );
  }
}

Input.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  inputRef: PropTypes.func,
};

Input.defaultProps = {
  inputRef: () => {},
  className: "",
  onChange: () => {},
  onEnter: () => {},
  type: "text",
  placeholder: "",
};
