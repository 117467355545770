import gql from "graphql-tag"

export const CREATE_QUERY_MUTATION = gql`
  mutation topicPage_createQueryMutation(
    $topicId: Int!
    $language: String!
    $query: String!
  ) {
    createTopicQueryClassifier(
      topicId: $topicId
      language: $language
      query: $query
      runToTitle: true
      runToTag: true
    ) {
      success
      message
    }
  }
`
export const LIST_QUERIES_QUERY = gql`
  query topicPage_listQueriesQuery(
    $language: String!
    $status: [String!]!
    $topicId: Int!
  ) {
    listTopicQueryClassifiers(
      language: $language
      status: $status
      topicId: $topicId
    ) {
      id
      query
      status

      topic {
        title
        topicId
      }
    }
  }
`
