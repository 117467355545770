import gql from "graphql-tag"

export const EDIT_QUERY_MUTATION = gql`
  mutation topicEditPage_editQueryMutation(
    $queryIdStr: String!
    $query: String!
    $status: String!
  ) {
    updateTopicQueryClassifier(
      queryIdStr: $queryIdStr
      query: $query
      status: $status
    ) {
      success
      message
    }
  }
`
