global.BUILD_ENV = process.env.REACT_APP_ENV

const urls = {
  development: "https://s3.us-east-2.amazonaws.com/dev-insights-assets",
  local: "https://s3.us-east-2.amazonaws.com/dev-insights-assets",
  production: "https://static.insights.winnin.com",
  staging: "https://static.insights.winnin.com"
}

function files() {
  if (urls[global.BUILD_ENV]) return urls[global.BUILD_ENV]
  return urls.development
}

export default {
  files: files()
}
