import React, { Fragment } from "react";

export default (
  <>
    <g fill="none" fillRule="nonzero">
      <path
        fill="#167EE6"
        d="M23.094 9.926H13.3a.782.782 0 0 0-.782.78v3.12c0 .432.35.781.782.781h5.515a7.347 7.347 0 0 1-3.169 3.704l2.351 4.06C21.77 20.194 24 16.376 24 12.103c0-.608-.045-1.043-.135-1.533a.786.786 0 0 0-.771-.645z"
      />
      <path
        fill="#12B347"
        d="M12.006 19.293c-2.695 0-5.048-1.477-6.311-3.663L1.63 17.98C3.698 21.578 7.573 24 12.006 24c2.175 0 4.226-.587 5.994-1.611v-.006l-2.348-4.078a7.224 7.224 0 0 1-3.646.988z"
      />
      <path
        fill="#0F993E"
        d="M18 22.386v-.005l-2.35-4.085a7.23 7.23 0 0 1-3.65.99V24c2.177 0 4.23-.588 6-1.614z"
      />
      <path
        fill="#FFD500"
        d="M4.714 12a7.24 7.24 0 0 1 .99-3.652L1.619 6A11.86 11.86 0 0 0 0 12c0 2.184.588 4.235 1.62 6l4.084-2.348A7.24 7.24 0 0 1 4.714 12z"
      />
      <path
        fill="#FF4B26"
        d="M12.017 4.707a7.26 7.26 0 0 1 4.637 1.668.778.778 0 0 0 1.05-.047l2.215-2.22a.792.792 0 0 0-.045-1.157A11.948 11.948 0 0 0 12.017 0C7.579 0 3.7 2.422 1.63 6.02l4.069 2.35c1.265-2.186 3.62-3.663 6.318-3.663z"
      />
      <path
        fill="#D93F21"
        d="M16.647 6.339a.784.784 0 0 0 1.051-.047l2.22-2.208a.783.783 0 0 0-.045-1.15A12.027 12.027 0 0 0 12 0v4.68a7.31 7.31 0 0 1 4.647 1.659z"
      />
    </g>
  </>
);
