import Env from "./env"

const files = {
  local: "https://s3.us-east-2.amazonaws.com/dev-insights-assets",
  development: "https://s3.us-east-2.amazonaws.com/dev-insights-assets",
  production: "https://static.insights.winnin.com"
}

const graphql = {
  production: "/graphql",
  local: "https://dev.tagger.insights.winnin.com/graphql",
  development: "https://dev.tagger.insights.winnin.com/graphql",
  // development: 'http://localhost:8081/graphql',
}

export default {
  Files: Env(files),
  Graphql: Env(graphql)
}
