import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import "./index.scss"

const HighlightedQuery = ({ query, className, ...props }) => {
  // consts
  const highlightedQuery = query.replace(
    /\bOR\b|\bAND\b|\bNOT\b|\(|\)|"/g,
    match => `<span class='muted'>${match}</span>`
  )

  // rendering
  return (
    <div
      className={classnames("HighlightedQuery", className)}
      dangerouslySetInnerHTML={{ __html: highlightedQuery }}
      {...props}
    />
  )
}

HighlightedQuery.propTypes = {
  query: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default HighlightedQuery
