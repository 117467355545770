import React from "react"
import "./index.scss"
import Select from "react-select"

const theme = _theme => ({
  ..._theme,
  colors: {
    ..._theme.colors,
    primary25: "rgba(2, 156, 150, .25)",
    primary: "#029c96"
  }
})

const customStyles = {
  control: provided => ({
    ...provided,
    boxShadow: "none"
  })
}

const ReactSelect = props => {
  const { label, ...passProps } = props
  return (
    <div className="select-group">
      <div className="select-label">{label || " "}</div>
      <Select
        className="filter"
        theme={theme}
        styles={customStyles}
        menuPlacement="bottom"
        {...passProps}
      />
    </div>
  )
}

export default ReactSelect
