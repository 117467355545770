import React from "react"
import { Redirect, Route } from "react-router-dom"
import { useKeycloak } from "@react-keycloak/web"

const PrivateRoute = ({ component: Component, location, ...props }) => {
  const { keycloak } = useKeycloak()

  return (
    <Route
      {...props}
      render={props =>
        keycloak.authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  )
}

export default PrivateRoute
