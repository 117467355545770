import { useState, useEffect } from "react"
import gql from "graphql-tag"

const BLOCK_CREATOR_QUERY = gql`
  mutation BLOCK_CREATOR_QUERY($limit: Int!, $offset: Int!) {
    creatorBlocked(limit: $limit, offset: $offset) {
      languages
      creator {
        title
        thumbnail
      }
      topics {
        title
      }
    }
  }
`

const useBlockCreatorList = function({
  client,
  searchCreatorTermList,
  languagesOptionsCreatorsFilter,
  limit,
  offset
}) {
  const [blockCreators, setBlockCreators] = useState([])
  const [loadingBlockCreators, setLoadingBlockCreators] = useState(false)
  const [noBlockCreators, setNoBlockCreators] = useState(false)
  const [filterTopics, setFilterTopics] = useState([])
  const [hasMore, setHasMore] = useState(true)

  const variables = {
    limit,
    offset
  }

  useEffect(() => {
    function getBlockCreatorsQuery() {
      if (loadingBlockCreators) return

      const mutation = BLOCK_CREATOR_QUERY
      setLoadingBlockCreators(true)

      client
        .mutate({
          mutation,
          variables
        })
        .then(({ data: { creatorBlocked } }) => {
          if (!creatorBlocked.length) {
            setNoBlockCreators(true)
            setLoadingBlockCreators(false)
            return
          }
          const newCreatorBlocked = !!blockCreators.length
            ? blockCreators.concat(creatorBlocked)
            : creatorBlocked

          setFilteredCreators(newCreatorBlocked)
          setBlockCreators(newCreatorBlocked)
          setNoBlockCreators(false)

          const creatorTopics = newCreatorBlocked.map(creator => creator.topics)

          const creatorTopicsFlatten = []
            .concat(...creatorTopics)
            .map(creator => creator.title)

          const creatorsWithoutDuplicateValues = [
            ...new Set(creatorTopicsFlatten)
          ]

          const allTopicsListCreators = creatorsWithoutDuplicateValues.map(
            topic => ({
              label: topic,
              value: topic
            })
          )

          const hasMore = !(offset > 0 && creatorBlocked.length === 0)

          setHasMore(hasMore)
          setLoadingBlockCreators(false)

          setFilterTopics([
            { label: "All", value: "all" },
            ...allTopicsListCreators
          ])
        })
        .catch(e => {
          console.error("error getting Block Topics >>>>>>>>>>>", e)
          setLoadingBlockCreators(false)
          setNoBlockCreators(true)
        })
    }
    getBlockCreatorsQuery()
  }, [offset])

  const [filteredCreators, setFilteredCreators] = useState([])
  useEffect(() => {
    if (searchCreatorTermList) {
      const listFiltered = blockCreators.filter(
        blockCreator =>
          blockCreator.creator.title
            .toLowerCase()
            .trim()
            .indexOf(searchCreatorTermList.toLowerCase().trim()) > -1
      )
      setFilteredCreators(listFiltered)
      return
    }
    setFilteredCreators(blockCreators)
  }, [searchCreatorTermList])

  const [languageListFilterCreator, setLanguageListFilterCreator] = useState(
    languagesOptionsCreatorsFilter[0]
  )
  useEffect(() => {
    if (Array.isArray(languageListFilterCreator.value)) {
      setFilteredCreators(blockCreators)
      return
    }

    const listFilteredByLanguage = blockCreators.filter(blockCreator => {
      return blockCreator.languages.includes(languageListFilterCreator.value)
    })

    setFilteredCreators(listFilteredByLanguage)
  }, [languageListFilterCreator])

  const [topicsFilterListCreator, setTopicsFilterListCreator] = useState({
    label: "All",
    value: "all"
  })
  useEffect(() => {
    if (topicsFilterListCreator.value === "all") {
      setFilteredCreators(blockCreators)
      return
    }

    const listFilteredByTopics = blockCreators.filter(creator => {
      const topics = creator.topics.map(topic => topic.title)
      return topics.includes(topicsFilterListCreator.value)
    })

    setFilteredCreators(listFilteredByTopics)
  }, [topicsFilterListCreator])

  return {
    blockCreators,
    noBlockCreators,
    loadingBlockCreators,
    filteredCreators,
    languageListFilterCreator,
    setLanguageListFilterCreator,
    filterTopics,
    topicsFilterListCreator,
    setTopicsFilterListCreator,
    hasMore
  }
}

export default useBlockCreatorList
