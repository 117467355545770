const humanizeLargeNumber = (num, digits = 0, justRounded) => {
  const number = Number(num)
  if (!number || Number.isNaN(number)) return "0"
  if (number < 0) return `-${humanizeLargeNumber(Math.abs(number), digits)}`
  if (number < 1 && number > 0) return `${number}`.slice(0, 4)
  const si = [
    { value: 1e18, symbol: "E" },
    { value: 1e15, symbol: "P" },
    { value: 1e12, symbol: "T" },
    { value: 1e9, symbol: "B" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "k" },
    { value: 1, symbol: "" }
  ]
  const n = parseInt(number, 10)
  const regex = /\.0+$|(\.[0-9]*[1-9])0+$/
  const { symbol, value } = si.filter(s => n >= s.value)[0]

  if (justRounded) {
    return (n / value).toFixed(digits) * value
  }

  return (n / value).toFixed(digits).replace(regex, "$1") + symbol
}

export default humanizeLargeNumber
