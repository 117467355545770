import React, { Fragment } from "react";

export default (
  <>
    <path
      fill="#7cd6bb"
      d="M12 24c6.6 0 12-5.4 12-12S18.6 0 12 0 0 5.4 0 12s5.4 12 12 12z"
    />
    <path
      fill="#fff"
      d="M16.9 5.4l-7.1 7.9L7 10.4 4.4 13l5.5 5.7L19.7 8l-2.8-2.6z"
    />
  </>
);
