import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import { ReactComponent as DotsIcon } from "../../assets/icon/dots.svg"
import "./index.scss"

const OptionItem = ({ option: { onClick, icon = null, label } }) => {
  const IconComponent = icon

  return (
    <li className="card-options-item" onClick={onClick}>
      {icon && <IconComponent className="card-options-icon" />} {label}
    </li>
  )
}

const OptionsList = ({ options }) => {
  return (
    <div className="card-options">
      <DotsIcon className="card-options-button" />

      <div className="card-options-list-wrapper">
        <ul className="card-options-list">
          {options.map((option, index) => (
            <OptionItem key={index} option={option} />
          ))}
        </ul>
      </div>
    </div>
  )
}

const CardWithOptions = ({ children, options = [], className, isDisabled }) => {
  const shouldRenderOptions = !isDisabled && options?.length

  return (
    <div
      className={classnames("CardWithOptions", className, {
        disabled: isDisabled
      })}
    >
      <div className="card-content">{children}</div>

      {shouldRenderOptions && <OptionsList options={options} />}
    </div>
  )
}

const optionShape = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.any,
  label: PropTypes.node.isRequired
}

OptionItem.propTypes = {
  option: PropTypes.shape(optionShape)
}

OptionsList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape(optionShape))
}

CardWithOptions.propTypes = {
  children: PropTypes.node.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape(optionShape)),
  className: PropTypes.string,
  isDisabled: PropTypes.bool
}

export default CardWithOptions
