import { useState } from "react"
import gql from "graphql-tag"

const CREATE_SUBTOPIC = gql`
  mutation($name: String!, $term: String!, $topicId: Int!) {
    createSubtopic(name: $name, term: $term, topicId: $topicId, dateRange: 2)
  }
`

const useSubtopicCreation = function({ client, name, term, topicId }) {
  const [isSaved, setIsSaved] = useState(false)
  const [loadingSubtopicCreation, setLoading] = useState(false)
  const createSubtopic = async () => {
    setLoading(true)
    const mutation = CREATE_SUBTOPIC
    const variables = {
      name,
      term,
      topicId: topicId[0]
    }

    if (!topicId.length || topicId.length > 1)
      return alert("You should put the subtopic in only ONE topic")

    if (!name) return alert("You should give the subtopic a name")
    if (!term) return alert("You should give the subtopic search term")

    try {
      const { data } = await client.mutate({
        mutation,
        variables
      })
      if (data.createSubtopic) setIsSaved(true)
      setLoading(false)
    } catch (e) {
      console.error("error creating subtopic >>>>>>>>>>>", e)
      setLoading(false)
    }
  }

  return {
    createSubtopic,
    loadingSubtopicCreation,
    isSaved
  }
}

export default useSubtopicCreation
