const Env = obj => {
  const ENV = process.env.NODE_ENV.toLowerCase()
  if (obj) {
    if (obj[ENV]) {
      return obj[ENV]
    }
    return obj.development
  }
  return ENV
}

export default Env
