import React, { Fragment } from "react";

// viewBoxWidth={105} viewBoxHeight={200}
export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.5 141C62.3172 141 69.6114 147.521 70.8232 156.003C77.2989 156.12 82.9156 159.755 85.8435 165.077C86.3888 165.026 86.9413 165 87.5 165C97.165 165 105 172.835 105 182.5C105 191.66 97.9628 199.176 89 199.937V200H87.5H17.5H16V199.937C7.0372 199.176 0 191.66 0 182.5C0 172.835 7.83502 165 17.5 165C18.3816 165 19.2479 165.065 20.0945 165.191C23.0526 159.718 28.8421 156 35.5 156C35.7262 156 35.9513 156.004 36.1754 156.013C37.383 147.526 44.6794 141 53.5 141Z"
      fill="#E5E5E5"
    />
    <path
      d="M30.0183 77C20.6558 86.8589 14.6436 107.797 12.0505 118.501C11.9563 118.922 11.9945 119.362 12.1599 119.759C12.3253 120.155 12.6092 120.489 12.971 120.71C13.3328 120.932 13.754 121.03 14.174 120.992C14.594 120.954 14.9913 120.78 15.309 120.497C23.1111 113.337 39.1055 100.638 51.566 103.83C59.7812 105.943 30.0183 77 30.0183 77Z"
      fill="#602F8E"
    />
    <path
      d="M77.9759 77C87.359 86.855 93.3468 107.785 95.9392 118.485C96.0435 118.905 96.013 119.348 95.8521 119.749C95.6912 120.149 95.4084 120.487 95.0456 120.71C94.6829 120.934 94.2593 121.032 93.8377 120.991C93.4161 120.949 93.0187 120.77 92.7044 120.479C84.8813 113.323 68.891 100.629 56.4337 103.82C48.2205 105.932 77.9759 77 77.9759 77Z"
      fill="#602F8E"
    />
    <path
      d="M76.9624 40.587C74.1567 27.6812 69.076 15.3738 61.9565 4.23712C61.082 2.93344 59.8985 1.86476 58.5108 1.1257C57.1231 0.386643 55.5739 0 54.0005 0C52.4272 0 50.8781 0.386643 49.4904 1.1257C48.1026 1.86476 46.9191 2.93344 46.0446 4.23712C38.9252 15.3738 33.8445 27.6812 31.0387 40.587C25.9981 62.2117 31.5497 94.695 34.3372 108.471C34.7172 110.315 35.7246 111.973 37.1891 113.162C38.6536 114.352 40.4852 115.001 42.3744 115H65.6034C67.491 115.005 69.3217 114.356 70.7832 113.165C72.2447 111.975 73.2464 110.316 73.6174 108.471C76.4514 94.695 82.0031 62.2117 76.9624 40.587Z"
      fill="#18B2A3"
    />
    <path
      d="M53.5 51C58.1944 51 62 46.9706 62 42C62 37.0294 58.1944 33 53.5 33C48.8055 33 45 37.0294 45 42C45 46.9706 48.8055 51 53.5 51Z"
      fill="white"
    />
    <path
      d="M63.3705 115H44.6059C44.1602 123.96 39 127.835 39 135.883C39 151.226 54.0117 159 54.0117 159C54.0117 159 69 151.226 69 135.883C68.9765 127.835 63.8397 123.96 63.3705 115Z"
      fill="#1F83DD"
    />
    <path
      d="M61.4958 115H46.4818C46.1008 122.533 42 125.807 42 132.561C42 145.452 54.0112 152 54.0112 152C54.0112 152 66 145.452 66 132.561C65.9776 125.807 61.8543 122.533 61.4958 115Z"
      fill="#FCD559"
    />
    <path
      d="M59.6381 115H48.3852C48.0817 120.479 45 122.87 45 127.815C45 137.101 54.0116 142 54.0116 142C54.0116 142 63 137.218 63 127.815C63 122.87 59.9183 120.479 59.6381 115Z"
      fill="#FFC700"
    />
    <path
      d="M66.7163 12.6197C65.0551 9.40616 63.4401 6.63831 61.9173 4.29267C61.0487 2.9719 59.8732 1.88921 58.4948 1.14046C57.1165 0.391712 55.5778 0 54.015 0C52.4522 0 50.9136 0.391712 49.5352 1.14046C48.1569 1.88921 46.9814 2.9719 46.1127 4.29267C44.3612 6.99556 42.7516 9.79077 41.2906 12.6666C37.5991 19.7973 70.3618 19.7739 66.7163 12.6197Z"
      fill="#602F8E"
    />
  </>
);
