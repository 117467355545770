const SAMPLE_QUALITY_COLORS = {
  bad: "#E92151",
  good: "#01ADA6",
  regular: "#FFB800",
  low: "#E92151",
  high: "#01ADA6",
  medium: "#FFB800"
}

export default SAMPLE_QUALITY_COLORS
