import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Card } from "../../../shared/shared.ui.card"

import Player from "../Player"
import "./index.scss"

const defaultPost = {
  title: "Loading video player",
  creatorTitle: "Please wait...",
  postUrl: null,
  tags: null,
  postId: "defaultpost"
}

const PlayerCard = props => {
  const { title, provider, videoUrl, creatorTitle, tags } = props.post
  const containerClass = classNames("Insights-ui__playercard", {
    blank: !videoUrl
  })

  return (
    <Card className={containerClass}>
      <div className="player-container">
        {videoUrl && <Player url={videoUrl} provider={provider} />}
      </div>
      <div className="content-container">
        <div className="title-and-creator">
          {title && <h3 className="title">{title}</h3>}
          {creatorTitle && (
            <>
              <small>by</small>
              <small className="creator-name">{creatorTitle}</small>
            </>
          )}
        </div>
        {tags && (
          <div className="tags-container">
            <span>Tags:</span>{" "}
            {tags.map(t => (
              <span className="post-tag" key={t}>
                {t}
              </span>
            ))}
          </div>
        )}
      </div>
    </Card>
  )
}

PlayerCard.propTypes = {
  post: PropTypes.object
}

PlayerCard.defaultProps = {
  post: defaultPost
}

export default PlayerCard
