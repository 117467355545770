export default {
  POSTED_ON: "The day the content was created",
  SHARES: "Amount of times this content was shared",
  COMMENTS: "Total comments on this content",
  ENGAGEMENT_FACEBOOK: "The sum of shares, comments and reactions",
  ENGAGEMENT_INSTAGRAM: "The sum of comments, views, subscribers, videos",
  ENGAGEMENT_YOUTUBE: "The sum of shares, comments, reactions and views",
  REACTIONS: "All the reactions, including likes, of this content",
  REACTION: "Facebook's like, love or one of the 'smiley' reactions",
  POST_TYPE:
    "Type of the content, can be one of Image, Video, Text, Link, Offer or Other",
  THUMBNAIL: "Thumbnail of the content",
  LINK: "A link leading to the content on it's native platform",
  VIEW: "Times this specific content was viewed",
  LIKE: "Total likes on this content",
  DISLIKE: "Total dislikes on this content",
  SUBSCRIBERS: "Users subscribed to this creator",
  VIDEOS: "Videos this creator has released"
}
