import gql from "graphql-tag"

export const APPROVE_QUERY_MUTATION = gql`
  mutation queryCard_approveQueryMutation($queryIdStr: String!) {
    updateTopicQueryClassifier(queryIdStr: $queryIdStr, status: "approved") {
      success
      message
    }
  }
`
