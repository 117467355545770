const localStorageUtils = {
  setObject: (key, value) => {
    window.localStorage.setItem(key, JSON.stringify(value))
  },

  delete: key => {
    window.localStorage.removeItem(key)
  },

  getObject: key => {
    const value = window.localStorage.getItem(key)
    return value && JSON.parse(value)
  }
}

export default localStorageUtils
