import React from "react"
import "./index.scss"
import Select from "react-select"
import { mergeDeepRight } from "ramda"

const theme = _theme => ({
  ..._theme,
  colors: {
    ..._theme.colors,
    primary25: "rgba(2, 156, 150, .25)",
    primary: "#029c96"
  }
})

const defaultStyles = {
  control: provided => ({
    ...provided,
    boxShadow: "none"
  })
}

const formatOptionLabel = ({ label, subLabel }, { context, selectValue }) => {
  return !subLabel ? (
    label
  ) : (
    <>
      {label}
      {context === "menu" && (
        <p
          className={`reactSelect-sublabel ${
            selectValue[0]?.label === label ? "selected" : ""
          }`}
        >
          {subLabel}
        </p>
      )}
    </>
  )
}

const ReactSelect = ({ label, customStyles = {}, ...passProps }) => {
  const styles = mergeDeepRight(defaultStyles, customStyles)

  return (
    <div className="select-group">
      <div className="select-label">{label || " "}</div>
      <Select
        className="filter"
        theme={theme}
        styles={styles}
        formatOptionLabel={formatOptionLabel}
        menuPlacement="bottom"
        {...passProps}
      />
    </div>
  )
}

export default ReactSelect
