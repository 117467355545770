import React, { Fragment } from "react";

export default (
  <>
    <path
      transform="translate(6,12)"
      fill="none"
      fillRule="evenodd"
      stroke="#555A62"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M1 1l5.03 5 4.963-5"
    />
  </>
);
