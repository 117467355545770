import React, { Fragment } from "react";

export default (
  <>
    <ellipse cx="12" cy="11.9" fill="#ffda6b" rx="12" ry="11.9" />
    <path d="M8.1 12.8c0 .8-.7 1.6-1.5 1.6s-1.5-.8-1.5-1.6.7-1.7 1.5-1.7 1.5.9 1.5 1.7zm10.6 0c0 .8-.7 1.6-1.5 1.6s-1.5-.8-1.5-1.6.7-1.7 1.5-1.7c.8.1 1.5.9 1.5 1.7zM14.8 19c-.1 0-.1 0-.2-.1-.8-.8-1.7-1.2-2.6-1.2-.8 0-1.5.3-1.9.6-.5.3-.8.6-.8.6 0 .1-.2.1-.3 0-.1-.1-.1-.3 0-.4 0 0 .3-.4.9-.7.3-.2.6-.4 1-.5.4-.1.8-.2 1.3-.2h.1c1.1 0 2.1.5 3 1.4.1.1.1.3 0 .4-.4.1-.5.1-.5.1zM4.6 10.7s-.1 0 0 0c-.3-.1-.3-.3-.3-.4 0 0 .2-.5.7-.9.4-.4 1.2-.8 2.4-.6.2 0 .3.2.2.3-.1.1-.1.2-.3.2-.8-.1-1.4 0-1.9.5-.4.3-.5.7-.5.7-.1.1-.2.2-.3.2zm14.4-.2s-.1-.4-.5-.7c-.5-.4-1.1-.6-1.9-.5-.1 0-.3-.1-.3-.2s.1-.3.2-.3c1.2-.2 2 .2 2.4.6.5.4.7.9.7.9 0 .1 0 .3-.1.4h-.1c-.2 0-.3-.1-.4-.2z" />
    <path
      fill="#6485c3"
      d="M20.5 21.2s-.8-2.3-1.6-3.7v.1s-.9 1.6-1.6 3.5c-.1.4-.2.8-.2 1.2 0 .7.4 1.7 1.9 1.7 0-.1 2.3-.2 1.5-2.8z"
    />
  </>
);
