import React from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";

export const Tooltip = ({
  className,
  containerClassName,
  targetId,
  delayShow,
  type,
  effect,
  place,
  title,
  TooltipContent,
  children,
  ...rest
}) => {
  return (
    <div
      data-tip
      data-for={`tooltip-${targetId}`}
      className={`custom-toltip-container ${containerClassName}`}
      title={title}
      {...rest}
    >
      <div>{children}</div>
      <ReactTooltip
        className={className}
        id={`tooltip-${targetId}`}
        place={place}
        delayShow={delayShow}
        type={type}
        effect={effect}
      >
        {TooltipContent}
      </ReactTooltip>
    </div>
  );
};

Tooltip.propTypes = {
  targetId: PropTypes.string,
  delayShow: PropTypes.number,
  type: PropTypes.string,
  effect: PropTypes.string,
  place: PropTypes.string,
  title: PropTypes.string,
  TooltipContent: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
};

Tooltip.defaultProps = {
  targetId: "targetId",
  delayShow: 0,
  type: "dark",
  effect: "solid",
  place: "top",
  title: "",
  TooltipContent: null,
  children: null,
  className: "",
  containerClassName: "",
};

Tooltip.displayName = "Tooltip";
