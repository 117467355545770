import React, { Fragment } from "react";

export default (
  <>
    <path d="M7.37995 17C6.86662 17 6.45595 16.846 6.14795 16.538C5.84928 16.23 5.69995 15.8147 5.69995 15.292V11.568C5.69995 10.98 5.85395 10.5273 6.16195 10.21C6.46995 9.88334 6.91795 9.72 7.50595 9.72H9.05995V10.56H7.53395C7.31928 10.56 7.14662 10.6347 7.01595 10.784C6.88528 10.924 6.81995 11.106 6.81995 11.33V15.39C6.81995 15.614 6.88528 15.8007 7.01595 15.95C7.15595 16.09 7.33328 16.16 7.54795 16.16H9.05995V17H7.37995Z" />
    <path d="M9.61722 16.16H10.8072C11.2086 16.16 11.4979 16.0527 11.6752 15.838C11.8619 15.614 11.9552 15.3247 11.9552 14.97C11.9552 14.69 11.8712 14.452 11.7032 14.256C11.5352 14.06 11.2832 13.8313 10.9472 13.57C10.6579 13.3367 10.4246 13.136 10.2472 12.968C10.0792 12.7907 9.92988 12.576 9.79922 12.324C9.67788 12.0627 9.61722 11.764 9.61722 11.428C9.61722 10.9333 9.78055 10.5273 10.1072 10.21C10.4432 9.88334 10.8912 9.72 11.4512 9.72H12.6692V10.56H11.6612C10.9985 10.56 10.6672 10.8727 10.6672 11.498C10.6672 11.7407 10.7419 11.9507 10.8912 12.128C11.0406 12.3053 11.2739 12.5153 11.5912 12.758C11.8806 12.982 12.1186 13.1873 12.3052 13.374C12.4919 13.5513 12.6506 13.78 12.7812 14.06C12.9119 14.3307 12.9772 14.6527 12.9772 15.026C12.9772 15.6887 12.7999 16.1833 12.4452 16.51C12.0999 16.8367 11.6099 17 10.9752 17H9.61722V16.16Z" />
    <path d="M15.2705 17L13.6045 9.72H14.7665L15.8445 15.586L16.9085 9.72H18.0705L16.4045 17H15.2705Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 22H5C4.44772 22 4 21.5523 4 21V3C4 2.44772 4.44772 2 5 2H14.5V5C14.5 6.38071 15.6193 7.5 17 7.5H20V21C20 21.5523 19.5523 22 19 22ZM19.9445 6.5C19.8954 6.3588 19.8148 6.22904 19.7071 6.12132L15.8787 2.29289C15.771 2.18518 15.6412 2.10457 15.5 2.05547V5C15.5 5.82843 16.1716 6.5 17 6.5H19.9445ZM3 3C3 1.89543 3.89543 1 5 1H15.1716C15.702 1 16.2107 1.21071 16.5858 1.58579L20.4142 5.41421C20.7893 5.78929 21 6.29799 21 6.82843V21C21 22.1046 20.1046 23 19 23H5C3.89543 23 3 22.1046 3 21V3Z"
    />
  </>
);
