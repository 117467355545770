import React, { Component } from "react"
import { Button } from "../../shared/shared.ui.button"

import Input from "../Input"
import Insights from "../../frontend_commons"
import "./index.scss"

const { ReactSelect } = Insights.Components

class StatusBar extends Component {
  input = null

  search = () => {
    const { value } = this.input.current
    const { onSubmit } = this.props
    if (value && onSubmit) {
      onSubmit(value)
    }
  }

  render() {
    const {
      filters,
      search = false,
      onSubmit,
      topic = "No Topic",
      to = 0,
      from = 0,
      positives = 0,
      negatives = 0,
      placeholder = 'You can use " ", (  ), OR, AND or NOT to improve your search'
    } = this.props
    return (
      <div className="status-bar">
        {search && (
          <>
            <div className="input-search-container">
              <Input
                className="first-level"
                label="Keyword"
                getRef={i => {
                  this.input = i
                }}
                icon="search"
                onChange={null}
                onSubmit={onSubmit}
                placeholder={placeholder}
              />
              <Button onClick={this.search}>Search videos</Button>
            </div>

            <div className="grid-container">
              {filters?.sortBy && (
                <div className="grid-column field-container">
                  <ReactSelect
                    label="Sort By"
                    placeholder="Select a date range"
                    value={filters.sortBy.selected}
                    onChange={filters.sortBy.change}
                    options={filters.sortBy.options}
                  />
                </div>
              )}
              {filters?.publishedAt && (
                <div className="grid-column field-container">
                  <ReactSelect
                    label="Upload Time"
                    placeholder="Select a date range"
                    value={filters.publishedAt.selected}
                    onChange={filters.publishedAt.change}
                    options={filters.publishedAt.options}
                  />
                </div>
              )}
              {filters?.providers && (
                <div className="grid-column field-container">
                  <ReactSelect
                    label="Provider"
                    placeholder="Select provider"
                    value={filters.providers.selecteds}
                    isMulti
                    onChange={filters.providers.change}
                    options={filters.providers.options}
                  />
                </div>
              )}
              {filters?.language && (
                <div className="grid-column field-container">
                  <ReactSelect
                    label="Language"
                    placeholder="Select a language of topic"
                    value={filters.language.selected}
                    onChange={filters.language.change}
                    options={filters.language.options}
                  />
                </div>
              )}
              {filters?.topicCategories && (
                <div className="grid-column field-container">
                  <ReactSelect
                    label={filters.topicCategories.label}
                    isDisabled={filters.topicCategories.loading}
                    placeholder="Select a category"
                    value={filters.topicCategories.selected}
                    onChange={filters.topicCategories.change}
                    options={filters.topicCategories.options}
                  />
                </div>
              )}
              {filters?.topic && (
                <div className="grid-column field-container">
                  <ReactSelect
                    label={filters.topic.label}
                    isDisabled={filters.topic.loading}
                    placeholder="Videos not in topic"
                    value={filters.topic.selected}
                    onChange={filters.topic.change}
                    options={filters.topic.options}
                  />
                </div>
              )}

              <div className="grid-column status-container">
                {positives > 0 && <span>{positives} Added</span>}
                {negatives > 0 && <span>{negatives} Removed</span>}
                {to > 0 && <span>{to} Tagged</span>}
                {/*from > 0 && (
                                        <span>{ from } videos</span>
                                    )*/}
              </div>
            </div>
          </>
        )}

        {!search && (
          <>
            <div className="grid-column topic-container">
              <span>Tag videos from topic:</span>
              <strong className="color green">{topic}</strong>
            </div>
            <div className="grid-column status-container">
              <span>{positives} Added</span>
              <span>{negatives} Removed</span>
              <span>{to} Tagged</span>
              <span>{from} videos</span>
            </div>
          </>
        )}
      </div>
    )
  }
}

export default StatusBar
