import { useState, useEffect } from "react"
import gql from "graphql-tag"
import find from "ramda/src/find"
import propEq from "ramda/src/propEq"
import { LANGUAGE_OPTIONS } from "../consts"

const ALL_TOPIC_GROUPS_QUERY = gql`
  query ALL_TOPIC_GROUPS_QUERY($language: String!) {
    allTopicGroups(language: $language) {
      title
      topicGroupId
      topics {
        title
        topicId
      }
    }
  }
`

const ALL_GLOBAL_TOPIC_GROUP_QUERY = gql`
  query ALL_GLOBAL_TOPIC_GROUP_QUERY {
    allGlobalTopicGroups {
      title
      topicGroupId
      topics {
        title
        topicId
      }
    }
  }
`

const ALL_TOPICS_QUERY = gql`
  query ALL_TOPICS_QUERY($language: String!) {
    allTopics(language: $language) {
      entities {
        title
        topicId
      }
    }
  }
`
const ALL_GLOBAL_TOPICS_QUERY = gql`
  query ALL_GLOBAL_TOPICS_QUERY {
    allGlobalTopics {
      title
      topicId
    }
  }
`

const TOPICS_BY_CATEGORY_QUERY = gql`
  query TOPICS_BY_CATEGORY_QUERY($groupIds: [Int!]!) {
    topicByGroup(groupIds: $groupIds) {
      topicId
      title
    }
  }
`

const transformCategoryToOption = ({ topicGroupId, title }) => ({
  label: title,
  value: topicGroupId
})

const transformTopicToOption = ({ topicId, title }) => ({
  label: title,
  value: topicId
})

const extractTopicIdsFromCategory = category =>
  category.topics.map(c => c.topicId)
const sortAlphabetically = (a, b) => a.label.localeCompare(b.label)

const useCategoriesAndTopicsSelector = function({ client }) {
  const ALL_OPTION = { label: "All", value: null }
  const [language, setLanguage] = useState(LANGUAGE_OPTIONS[0]) // portuguese
  const [loadingTopics, setLoadingTopics] = useState(false)
  const [loadingCategories, setLoadingCategories] = useState(false)
  const [categories, setCategories] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [topicOptions, setTopicOptions] = useState([])
  const [topics, setTopics] = useState([])
  const [selectedCategory, selectCategory] = useState(ALL_OPTION)
  const [selectedTopic, selectTopic] = useState(ALL_OPTION)
  const [selectedTopicIds, setSelectedTopicIds] = useState([])

  useEffect(() => {
    setLoadingCategories(true)

    async function getCategories() {
      try {
        const data = await client.query({
          query:
            language.value === "global"
              ? ALL_GLOBAL_TOPIC_GROUP_QUERY
              : ALL_TOPIC_GROUPS_QUERY,
          variables: {
            ...(language.value !== "global"
              ? { language: language.value }
              : null)
          }
        })
        const dataTopic =
          language.value === "global"
            ? data.data.allGlobalTopicGroups
            : data.data.allTopicGroups
        setCategories(dataTopic)
        setLoadingCategories(false)
      } catch (e) {
        setLoadingCategories(false)
        console.error("error getting topic categories >>>>>>>>>>>", e)
      }
    }
    getCategories()
  }, [language])

  // get allTopics initially, topic by groups when a group is selected
  useEffect(() => {
    if (language?.value === "none" || !selectedCategory.value) {
      setTopicOptions([ALL_OPTION])
      return
    }

    setLoadingTopics(true)
    async function getTopics() {
      try {
        const isCategoryFiltered = !!selectedCategory.value
        const queryInfo = isCategoryFiltered
          ? {
              query: TOPICS_BY_CATEGORY_QUERY,
              variables: {
                groupIds: selectedCategory.value
              }
            }
          : {
              query:
                language.value === "global"
                  ? ALL_GLOBAL_TOPICS_QUERY
                  : ALL_TOPICS_QUERY,
              variables: {
                ...(language.value !== "global"
                  ? { language: language.value }
                  : null)
              }
            }

        const { data } = await client.query(queryInfo)

        if (!isCategoryFiltered) {
          const dataTopics =
            language.value === "global"
              ? data.allGlobalTopics
              : data.allTopics.entities

          setTopics(dataTopics)
        }

        if (isCategoryFiltered) {
          setTopics(data.topicByGroup)
        }

        setLoadingTopics(false)
      } catch (e) {
        setLoadingTopics(false)
        console.error("error getting topics >>>>>>>>>>>", e)
      }
    }
    getTopics()
  }, [selectedCategory, language])

  useEffect(() => {
    selectTopic(ALL_OPTION)
  }, [selectedCategory])

  useEffect(() => {
    selectTopic(ALL_OPTION)
    selectCategory(ALL_OPTION)
  }, [language])

  useEffect(() => {
    const categoriesToOptions = [
      ALL_OPTION,
      ...categories.map(transformCategoryToOption).sort(sortAlphabetically)
    ]
    setCategoryOptions(categoriesToOptions)
  }, [categories])

  useEffect(() => {
    const topicsToOptions = [
      ALL_OPTION,
      ...topics.map(transformTopicToOption).sort(sortAlphabetically)
    ]
    setTopicOptions(topicsToOptions)
  }, [topics])

  useEffect(() => {
    if (!selectedCategory.value && !selectedTopic.value) {
      setSelectedTopicIds([])
    }
    if (
      (!selectedCategory.value && selectedTopic.value) ||
      (selectedCategory.value && selectedTopic.value)
    ) {
      setSelectedTopicIds([selectedTopic.value])
    }
    if (selectedCategory.value && !selectedTopic.value) {
      const category = find(propEq("topicGroupId", selectedCategory.value))(
        categories
      )
      const selectedCategoryTopicIds = extractTopicIdsFromCategory(category)
      setSelectedTopicIds(selectedCategoryTopicIds)
    }
  }, [language, selectedCategory, selectedTopic])

  return {
    language,
    setLanguage,
    languageOptions: LANGUAGE_OPTIONS,
    loadingTopics,
    loadingCategories,
    topics,
    categories,
    categoryOptions,
    topicOptions,
    selectedCategory,
    selectCategory,
    selectedTopic,
    selectTopic,
    selectedTopicIds
  }
}

export default useCategoriesAndTopicsSelector
