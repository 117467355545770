const getEnvFromLocation = function() {
  const locationUrl = window?.location?.href

  if (locationUrl) {
    if (locationUrl.includes("https://local-tagger.winnin.com:3000/")) {
      return "local"
    }
    if (locationUrl.includes("https://dev.tagger.insights.winnin.com/")) {
      return "development"
    }
    if (locationUrl.includes("https://staging.tagger.insights.winnin.com/")) {
      return "staging"
    }
    if (locationUrl.includes("https://tagger.winnin.com/")) {
      return "production"
    }
    return "test"
  }
}

export default getEnvFromLocation
