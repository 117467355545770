import React, { Fragment } from "react";

export default (
  <>
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 7C15 9.76142 12.7614 12 10 12C7.23858 12 5 9.76143 5 7C5 4.23858 7.23858 2 10 2C12.7614 2 15 4.23858 15 7ZM14 7C14 9.20914 12.2091 11 10 11C7.79086 11 6 9.20914 6 7C6 4.79086 7.79086 3 10 3C12.2091 3 14 4.79086 14 7Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4629 2.25765C16.6951 2.10827 17.0045 2.17545 17.1539 2.4077C18.0058 3.73232 18.5 5.30921 18.5 7C18.5 8.3327 18.193 9.5951 17.6453 10.719C17.5243 10.9673 17.225 11.0704 16.9768 10.9495C16.7285 10.8285 16.6254 10.5292 16.7463 10.281C17.229 9.29051 17.5 8.17762 17.5 7C17.5 5.50672 17.0641 4.11678 16.3128 2.94865C16.1634 2.7164 16.2306 2.40703 16.4629 2.25765Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8177 0.0799378C20.0497 -0.0698394 20.3592 -0.00318906 20.5089 0.228805C21.7688 2.18031 22.5 4.50553 22.5 7C22.5 9.19641 21.9331 11.2619 20.9372 13.0566C20.8032 13.2981 20.4989 13.3852 20.2574 13.2512C20.016 13.1172 19.9288 12.8129 20.0628 12.5714C20.9784 10.9214 21.5 9.02234 21.5 7C21.5 4.70366 20.8275 2.56592 19.6688 0.771196C19.519 0.539202 19.5857 0.229715 19.8177 0.0799378Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4805 19.2586L19.4847 19.2653C20.1244 20.2763 20.1343 21.4394 19.7021 22.3587C19.2725 23.2728 18.3682 24 17.1997 24H2.80026C1.58749 24 0.700802 23.1802 0.292215 22.28C-0.117664 21.3769 -0.149401 20.1879 0.544281 19.2219C2.67357 16.1668 6.01479 14 9.99998 14C13.9621 14 17.3256 15.9693 19.4761 19.252L19.4805 19.2586ZM18.6397 19.8C19.5396 21.2222 18.6397 23 17.1997 23H2.80026C1.36031 23 0.460344 21.0444 1.36031 19.8C3.34023 16.9556 6.40012 15 9.99998 15C13.5998 15 16.6597 16.7778 18.6397 19.8Z"
      />
    </g>
  </>
);
