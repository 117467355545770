import React, { Fragment } from "react";

export default (
  <>
    <path
      d="M17.4444 10H6.55556C5.69645 10 5 10.8954 5 12V19C5 20.1046 5.69645 21 6.55556 21H17.4444C18.3036 21 19 20.1046 19 19V12C19 10.8954 18.3036 10 17.4444 10Z"
      stroke="#A7B0BE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 9V6.57143C8 5.62423 8.42143 4.71582 9.17157 4.04605C9.92172 3.37627 10.9391 3 12 3C13.0609 3 14.0783 3.37627 14.8284 4.04605C15.5786 4.71582 16 5.62423 16 6.57143V9"
      stroke="#A7B0BE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="12" cy="15.5" r="1.5" />
  </>
);
