import gql from "graphql-tag"

export const REJECT_QUERY_MUTATION = gql`
  mutation queryRejectModal_rejectQueryMutation(
    $queryIdStr: String!
    $feedback: String!
  ) {
    updateTopicQueryClassifier(
      queryIdStr: $queryIdStr
      feedback: $feedback
      status: "rejected"
    ) {
      success
      message
    }
  }
`
