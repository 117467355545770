const LocalStorage = {
  setObject: (key, value) => {
    window.localStorage.setItem(key, JSON.stringify(value))
  },

  delete: key => {
    window.localStorage.removeItem(key)
  },

  getObject: key => {
    const value = window.localStorage.getItem(key)
    if (value) {
      return JSON.parse(value)
    }
    return null
  }
}

export default LocalStorage
