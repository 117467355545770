const FACEBOOK = {
  KEY: "fb",
  NAME: "Facebook",
  COLOR: "#3B5999",
  HOVER_COLOR: "#001B54",
  POST_TYPES: {
    IMAGE: 1,
    VIDEO: 2,
    TEXT: 3,
    LINK: 4,
    OFFER: 5,
    OTHER: 6
  }
}

const INSTAGRAM = {
  KEY: "in",
  NAME: "Instagram",
  COLOR: "#FCAF45",
  HOVER_COLOR: "#714200",
  POST_TYPES: {
    IMAGE: 1,
    VIDEO: 2,
    TEXT: 3,
    OTHER: 4
  }
}

const YOUTUBE = {
  KEY: "yt",
  NAME: "Youtube",
  COLOR: "#FF0000",
  HOVER_COLOR: "#560700",
  POST_TYPES: {
    VIDEO: 2,
    OTHER: null
  }
}

const TWITCH = {
  KEY: "tw",
  NAME: "Twitch",
  COLOR: "#874FF6",
  HOVER_COLOR: "#6441A5",
  POST_TYPES: {
    VIDEO: 2,
    OTHER: null
  }
}

const TIKTOK = {
  KEY: "tt",
  NAME: "TikTok",
  COLOR: "#000000",
  HOVER_COLOR: "#777777",
  POST_TYPES: {
    VIDEO: 2,
    OTHER: null
  }
}

const providers = {
  NAMES: [
    {
      KEY: "fb",
      TITLE: "facebook"
    },
    {
      KEY: "in",
      TITLE: "instagram"
    },
    {
      KEY: "yt",
      TITLE: "youtube"
    },
    {
      KEY: "tt",
      TITLE: "tiktok"
    },
    {
      KEY: "tw",
      TITLE: "twitch"
    }
  ],
  yt: YOUTUBE,
  fb: FACEBOOK,
  in: INSTAGRAM,
  tt: TIKTOK,
  tw: TWITCH
}

export default providers
