import React, { Fragment } from "react";

export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.03519 2.46513C9.44895 2.48454 9.76863 2.83569 9.74922 3.24945L9.50897 8.37087L12.2221 9.48612C12.6052 9.6436 12.7881 10.0818 12.6306 10.4649C12.4732 10.8481 12.0349 11.031 11.6518 10.8735L8.44989 9.55731C8.15675 9.43682 7.971 9.14507 7.98585 8.82848L8.25087 3.17916C8.27028 2.7654 8.62143 2.44572 9.03519 2.46513Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
    />
  </>
);
