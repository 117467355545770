import React from "react"
import ReactDOM from "react-dom"
import { Router } from "react-router-dom"
import { createBrowserHistory } from "history"
import { RouterStore, syncHistoryWithStore } from "mobx-react-router"
import { Provider as MobxProvider } from "mobx-react"
import { TopicProvider } from "./context/TopicContext"
import { KeycloakProvider } from "@react-keycloak/web"
import { keycloakProviderProps } from "./utils/auth"
import UserStore from "./stores/UserStore"
import ToastStore from "./stores/ToastStore"
import ApolloProvider from "./ApolloProvider"
import App from "./App"
import "./index.scss"

const browserHistory = createBrowserHistory()
const routingStore = new RouterStore()
const history = syncHistoryWithStore(browserHistory, routingStore)

const stores = {
  UserStore,
  ToastStore
}

ReactDOM.render(
  <KeycloakProvider {...keycloakProviderProps}>
    <Router history={history}>
      <ApolloProvider>
        <MobxProvider {...stores} routingStore={routingStore}>
          <TopicProvider>
            <App />
          </TopicProvider>
        </MobxProvider>
      </ApolloProvider>
    </Router>
  </KeycloakProvider>,
  document.getElementById("root")
)
