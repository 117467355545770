// Rounds a value to it's next "big" number
import dayjs from "dayjs"

const Format = {
  Date: (date, format = "DD/MM/YYYY") => dayjs(date).format(format),
  Number: {
    Duration: durationInSeconds => {
      if (!durationInSeconds) return null
      const date = new Date(null)
      date.setSeconds(durationInSeconds)
      return date.toISOString().substr(11, 8)
    },
    BigRound: v => {
      if (Number.isNaN(v)) return 0
      const n = typeof v === "string" ? parseInt(v, 10) : v
      const s = `${n}`
      const ownBig = parseInt(`${s[0]}`.padEnd(s.length, "0"), 10)
      const nextBig = parseInt(
        `${parseInt(s[0], 10) + 1}`.padEnd(s.length, "0"),
        10
      )
      if (n === ownBig) return n
      if (s[0] === "9") return nextBig * 10
      return nextBig
    },

    Human: (num, digits = 0) => {
      if (!num || Number.isNaN(num)) return "0"
      if (num < 1) return `${num}`.slice(0, 4)
      const si = [
        { value: 1e18, symbol: "E" },
        { value: 1e15, symbol: "P" },
        { value: 1e12, symbol: "T" },
        { value: 1e9, symbol: "B" },
        { value: 1e6, symbol: "M" },
        { value: 1e3, symbol: "k" },
        { value: 1, symbol: "" }
      ]
      const n = parseInt(num, 10)
      const regex = /\.0+$|(\.[0-9]*[1-9])0+$/
      const { symbol, value } = si.filter(s => n >= s.value)[0]
      return (n / value).toFixed(digits).replace(regex, "$1") + symbol
    },

    FormatPercent: (v, digits = 0) => {
      const abs = (Math.abs(v) * 100).toFixed(digits)
      if (abs < 1) return "<1%"
      return `${abs}%`
    }
  }
}

export default Format
