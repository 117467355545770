import React from "react"
import "./index.scss"
import Select from "react-select"
import { Icon } from "../../shared/shared.ui.icon"
import { Tooltip } from "../../shared/shared.ui.tooltip"

const customStyles = {
  option: (provided, { isSelected }) => ({
    ...provided,
    padding: 6,
    fontSize: 13,
    fontWeight: "400",
    whiteSpace: "nowrap",
    color: isSelected ? "#fff" : "#4C5667",
    background: isSelected ? "#6324C6" : "#fff",
    cursor: "pointer",
    "&:hover": {
      background: isSelected ? "#6324C6" : "#9b6ce4",
      color: "#fff"
    }
  }),
  valueContainer: provided => ({
    ...provided,
    display: "inline",
    padding: 0,
    border: "none",
    cursor: "pointer"
  }),
  menu: provided => ({
    ...provided,
    width: "auto",
    margin: 0,
    right: 0
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4
  }),
  control: provided => ({
    ...provided,
    display: "inline",
    padding: 0,
    border: "none",
    fontSize: 14,
    fontWeight: "700",
    color: "#4C5667",
    background: "transparent",
    boxShadow: "none"
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: "none"
  }),
  dropdownIndicator: provided => ({
    ...provided,
    display: "none"
  })
}

const SingleValue = ({ selectProps, data }) => (
  <div className="custom-single-value">
    {selectProps.getOptionLabel(data)}
    <Icon icon="arrowDownNew" fill="#4C5667" />
  </div>
)

const renderTooltip = (tooltipContent, tooltipPlace) => {
  const tooltipId = (Math.random() * 1000).toFixed()

  return (
    <Tooltip
      targetId={`dropdown-tooltip-${tooltipId}`}
      place={tooltipPlace || "top"}
      TooltipContent={tooltipContent}
      containerClassName="dropdown-tooltip-container"
    >
      <Icon
        className="dropdown-tooltip-icon"
        icon="question-mark"
        fill="#6324C6"
        viewBox="2 2 16 16"
        width={18}
        height={18}
      />
    </Tooltip>
  )
}

const Dropdown = ({
  label = null,
  defaultValue = null,
  tooltipContent = null,
  tooltipPlace = null,
  options,
  ...rest
}) => (
  <div className="Dropdown">
    {label && <p className="dropdown-label">{label}</p>}

    <Select
      className="dropdown-select"
      styles={customStyles}
      menuPlacement="bottom"
      isSearchable={false}
      defaultValue={defaultValue || options[0]}
      options={options}
      components={{ SingleValue }}
      {...rest}
    />

    {tooltipContent && renderTooltip(tooltipContent, tooltipPlace)}
  </div>
)

export default Dropdown
