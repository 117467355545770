import React, { Fragment } from "react";

export default (
  <>
    <linearGradient
      id="reaction-angry-gradient"
      x1="516.7903"
      x2="516.7903"
      y1="1062.1221"
      y2="1047.7532"
      gradientTransform="matrix(1.6667 0 0 -1.6667 -849.3345 1770.2019)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset=".098" stopColor="#f05766" />
      <stop offset=".25" stopColor="#f3766a" />
      <stop offset=".826" stopColor="#ffda6b" />
    </linearGradient>
    <circle cx="12" cy="12" r="12" fill="url(#reaction-angry-gradient)" />
    <circle cx="6.7" cy="15.1" r="1.2" />
    <circle cx="17.3" cy="15.1" r="1.2" />
    <path d="M13.9 14.7c-.1 0-.3-.1-.3-.3 0-.1.1-.3.3-.3 4.7 0 6.6-1.4 6.6-1.4.1-.1.3-.1.4.1s.1.3 0 .4c0 0-.5.4-1.6.8-.6.2-1.4.4-2.1.5-1.1.1-2.2.2-3.3.2zm-7-.3c-.8-.1-1.5-.3-2.2-.5-1.1-.4-1.6-.8-1.7-.8 0-.1 0-.3.1-.4.1-.1.3-.1.4-.1 0 0 1.9 1.4 6.6 1.4.1 0 .3.1.3.3 0 .1-.1.3-.3.3-1.2.1-2.2 0-3.2-.2zm8.9 5c0 .7-1.7.7-3.8.7s-3.8 0-3.8-.7c0-.6 1.8-.9 3.9-.9s3.7.3 3.7.9z" />
  </>
);
