import React, { Fragment } from "react";

export default (
  <>
    <path
      opacity="0.4"
      d="M14 9.8c.83 0 1.29-.76 1.29-1.68S14.84 6.48 14 6.48s-1.29.8-1.29 1.68S13.15 9.8 14 9.8zM11.72 13.78c-.06.24-.13.49-.2.73l-.4 1.17h1.22l-.4-1.16c-.1-.27-.16-.52-.22-.74z"
    />
    <path
      opacity="0.4"
      d="M0 0v24h24V0zm14 6.05a1.85 1.85 0 0 1 1.82 2.05 1.9 1.9 0 0 1-1.88 2.13 1.85 1.85 0 0 1-1.82-2.05A1.92 1.92 0 0 1 14 6.05zM4.67 17.36h-.52v-4h.52zm4.42 0L9 15.59c0-.57-.06-1.25-.06-1.75-.13.47-.3 1-.51 1.54l-.71 2h-.4l-.65-1.93c-.19-.57-.35-1.09-.47-1.57 0 .5 0 1.18-.08 1.79L6 17.36h-.5l.28-4h.67l.68 1.95c.17.5.31.95.41 1.37.1-.41.25-.86.43-1.37l.72-1.95h.66l.25 4zm-.21-10.6c0 .51.05 1 .05 1.66v1.73h-.5v-4H9l1.3 2a11.09 11.09 0 0 1 .7 1.33c0-.54-.06-1-.06-1.66v-1.7h.49v4h-.53L9.64 8.11a14.27 14.27 0 0 1-.76-1.35zm4 10.6l-.43-1.27H11l-.42 1.27H10l1.38-4h.63l1.38 4zm4.12-.18a3.67 3.67 0 0 1-1.24.22 2 2 0 0 1-1.51-.53 2.09 2.09 0 0 1-.57-1.51 2 2 0 0 1 2.19-2.08 2.63 2.63 0 0 1 1 .18l-.13.43a2 2 0 0 0-.89-.18 1.52 1.52 0 0 0-1.63 1.63A1.48 1.48 0 0 0 15.8 17a1.72 1.72 0 0 0 .7-.11v-1.2h-.82v-.42H17zm3.07.18h-2.26v-4H20v.44h-1.67V15h1.57v.44h-1.57v1.45h1.75z"
    />
  </>
);
