import React, { Fragment } from "react";

export default (
  <>
    <path
      id="trash-icon"
      d="M11 18H3a2 2 0 0 1-2-2V4h12v12a2 2 0 0 1-2 2zm3-15H0V2a1 1 0 0 1 1-1h2.5l1-1h5l1 1H13a1 1 0 0 1 1 1v1z"
      transform="translate(5 5)"
    />
  </>
);
