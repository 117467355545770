import React, { createContext, useState, useEffect } from "react"
import { client } from "../ApolloProvider"
import localStorageUtils from "../utils/localStorageUtils"
import gql from "graphql-tag"
import { useLazyQuery } from "@apollo/react-hooks"

const GET_TOPIC_QUERY = gql`
  query homeQuery {
    getNextTopicToBeTagged {
      topicId
      title
    }
  }
`

const TAGGER_STATUS_KEY = "winninTaggerStatus"

const defaultState = {
  topic: { topicId: null, title: "", count: 0 },
  loading: false,
  error: false,
  updateTopic: () => {},
  incrementCount: () => {},
  getNewTopic: () => {}
}

export const TopicContext = createContext(defaultState)

export const TopicProvider = ({ children }) => {
  const [topic, setTopic] = useState({ topicId: null, title: "", count: 0 })

  const [getNewTopic, { data, loading, error }] = useLazyQuery(
    GET_TOPIC_QUERY,
    { client }
  )

  function updateTopic({ topicId, count, title }) {
    const newTopic = {
      topicId: topicId || topic.topicId,
      title: title || topic.title,
      count: count
    }

    localStorageUtils.setObject(TAGGER_STATUS_KEY, newTopic)
    setTopic(newTopic)
  }

  function incrementCount() {
    const status = localStorageUtils.getObject(TAGGER_STATUS_KEY)
    const newTopic = {
      ...status,
      count: status.count + 1
    }

    updateTopic(newTopic)
  }

  useEffect(() => {
    if (!loading && !error && data) {
      const { getNextTopicToBeTagged: topic } = data

      updateTopic(topic)
    }
  }, [data])

  return (
    <TopicContext.Provider
      value={{
        topic,
        loading,
        error,
        updateTopic,
        incrementCount,
        getNewTopic
      }}
    >
      {children}
    </TopicContext.Provider>
  )
}
