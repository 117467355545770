import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icon } from "../../shared/shared.ui.icon";
import "./index.scss";

// TODOS:
// Icon on button;

export const Button = ({
  children,
  appearance = "",
  variation = "primary",
  disabled = false,
  onClick,
  icon,
  className,
  ...props
}) => {
  const classes = classNames(
    "Insights-ui__btn",
    appearance,
    variation,
    { "with-icon": icon },
    className
  );
  return (
    <button
      type="button"
      {...props}
      onClick={onClick}
      disabled={disabled}
      className={classes}
    >
      {icon && <Icon icon={icon} />}
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /** Button action */
  onClick: PropTypes.func.isRequired,
  /** Button type */
  appearance: PropTypes.oneOf(["default", "outline", "link"]),
  /** Button variation */
  variation: PropTypes.oneOf(["primary", "highlighted", "faded"]),
  /** Disables the button, ignoring any click event */
  disabled: PropTypes.bool,
  /** Any Insights UI icon */
  icon: PropTypes.string,
  className: PropTypes.string,
};

Button.defaultProps = {
  children: null,
  appearance: "default",
  variation: "primary",
  disabled: false,
  icon: null,
  className: "",
};
