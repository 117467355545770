import gql from "graphql-tag"

export const DELETE_QUERY_MUTATION = gql`
  mutation topicEditPage_deleteQueryMutation(
    $queryIdStr: String!
    $status: String!
  ) {
    updateTopicQueryClassifier(queryIdStr: $queryIdStr, status: $status) {
      success
      message
    }
  }
`
