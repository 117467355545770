import React, { Fragment } from "react";

export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3635 18.6939L16.0554 20.8564C15.1329 22.3812 12.9999 22.3812 12.0775 20.8564L10.7693 18.6939H3.33331C2.67506 18.6939 2.0644 18.4448 1.62609 17.9618C1.19578 17.4875 1 16.8651 1 16.2245V4.46939C1 3.82877 1.19578 3.20633 1.62609 2.7321C2.0644 2.24905 2.67506 2 3.33331 2H20.6663C21.3245 2 21.9352 2.24905 22.3735 2.7321C22.8038 3.20633 22.9996 3.82877 22.9996 4.46939V16.2142C23.0106 16.8212 22.7913 17.408 22.4404 17.8516C22.0892 18.2957 21.5204 18.6939 20.7996 18.6939H17.3635ZM21.9996 16.2245V4.46939C21.9996 3.58776 21.4663 3 20.6663 3H3.33331C2.53332 3 2 3.58776 2 4.46939V16.2245C2 17.1061 2.53332 17.6939 3.33331 17.6939H11.3332L12.9331 20.3388C13.4664 21.2204 14.6664 21.2204 15.1997 20.3388L16.7997 17.6939H20.7996C21.4663 17.6939 22.0168 16.95 21.9996 16.2245Z"
    />
  </>
);
