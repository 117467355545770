import React, { Component } from "react"
import { compose, withApollo } from "react-apollo"
import { Loading } from "../../shared/shared.ui.loading"

import VideoGrid from "../../components/VideoGrid"
import StatusBar from "../../components/StatusBar"
import AppHeader from "../../components/AppHeader"
import { GET_POSTS_TO_VERIFY } from "./query"
import { VERIFY_POST } from "./mutation"
import { TopicContext } from "../../context/TopicContext"
import localStorageUtils from "../../utils/localStorageUtils"
import "./index.scss"

const TAGGER_STATUS_KEY = "winninTaggerStatus"

class TaggingPage extends Component {
  state = {
    videos: [],
    loading: true,
    count: 0,
    negatives: 0,
    positives: 0,
    error: false,
    onErrorAction: () => {},
    onErrorButtonTitle: "Try Again"
  }

  limit = 250

  componentDidMount() {
    this.props.topicId && this.fetchVideos()
    const status = localStorageUtils.getObject(TAGGER_STATUS_KEY)
    if (status) {
      const { count } = status
      this.setState({ count })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { videos } = this.state
    const { topicId } = this.props
    if (prevState.videos.length > 0 && videos.length === 0) {
      this.fetchVideos()
    }
    if (!prevProps.topicId && topicId) {
      this.fetchVideos()
    }
  }

  fetchVideos = () => {
    this.setState({ loading: true, error: false })

    const { client, topicId, getNewTopic } = this.props
    const variables = {
      topicId,
      limit: 10
    }

    client
      .query({
        variables,
        query: GET_POSTS_TO_VERIFY,
        fetchPolicy: "network-only"
      })
      .then(({ data }) => {
        const { getPostsToVerify: videos } = data
        if (!videos.length) {
          this.setState({
            loading: false,
            error: "No more videos to be tagged",
            onErrorAction: getNewTopic,
            onErrorButtonTitle: "Get a new Topic"
          })
          return []
        }
        this.setState({
          videos,
          loading: false
        })
        return videos
      })
      .catch(e => {
        console.error("error on TaggingPage@fetchVideos  >>>>>>>>>>>", { e })
        this.setState({
          error: e.message,
          loading: false,
          onErrorAction: this.fetchVideos
        })
      })
  }

  tagAction = ({ video, index, value }) => {
    const { client, topicId, incrementCount, history } = this.props
    const { videos } = this.state
    let { count, positives, negatives } = this.state
    count++

    value ? positives++ : negatives++

    const updated = videos.filter((_, videoIndex) => videoIndex !== index)

    incrementCount()
    this.setState({ positives, negatives, count, videos: updated })

    if (count >= this.limit) {
      history.push("/well-done")
    }

    const { postId, provider } = video
    const variables = { postId, provider, topicId, approved: value }
    client
      .mutate({
        mutation: VERIFY_POST,
        variables
      })
      .catch(error =>
        console.error("error TaggingPage@tagAction >>>>>>>>>>>", error)
      )
  }

  render() {
    const {
      videos,
      loading,
      count,
      error,
      onErrorAction,
      onErrorButtonTitle,
      positives,
      negatives
    } = this.state
    const { title } = this.props

    return (
      <>
        <AppHeader />
        <StatusBar
          topic={title}
          positives={positives}
          negatives={negatives}
          to={count}
          from={this.limit}
        />
        {error ? (
          <div className="tagging-page-loading">
            <h3>
              {error}
              <span role="img" aria-label="crying-emoji">
                😭
              </span>
            </h3>
            <button onClick={onErrorAction}>{onErrorButtonTitle}</button>
          </div>
        ) : loading ? (
          <div className="tagging-page-loading">
            <Loading medium />
          </div>
        ) : (
          <VideoGrid tagAction={this.tagAction} videos={videos} />
        )}
      </>
    )
  }
}

const TaggingPageWithContext = props => {
  return (
    <TopicContext.Consumer>
      {({ topic, updateTopic, incrementCount, getNewTopic }) => {
        return (
          <TaggingPage
            title={topic.title}
            count={topic.count}
            topicId={topic.topicId}
            updateTopic={updateTopic}
            incrementCount={incrementCount}
            getNewTopic={getNewTopic}
            {...props}
          />
        )
      }}
    </TopicContext.Consumer>
  )
}

export default compose(withApollo)(TaggingPageWithContext)
