import React, { Fragment } from "react";

export default (
  <>
    <ellipse cx="12" cy="12" fill="#5e91ff" rx="12" ry="12" />
    <path
      fill="#fff"
      d="M6.1 10.6h2.6c.2 0 .4.2.4.4v6.1c0 .2-.2.4-.4.4H6.1c-.2 0-.4-.2-.4-.4V11c0-.2.2-.4.4-.4zm4 5.4v-4.3c0-1.1.3-2.1.9-3 .4-.6 1.1-1.4 1.6-1.9.1-.1.4-.4.4-.6V5c0-.4.3-.7.7-.7.7 0 .9.7.9.7s.6 1.2.1 2.7c-.5 1.2-.6 1.3-.6 1.3s-.1.2.3.2h3.1c.2 0 .9.2.9 1.1 0 .6-.4.9-.6.9v.1c.2.1.6.4.6.9 0 .6-.3.8-.7.9v.1c.2.1.5.4.5.9s-.4.7-.6.8v.1c.1.1.3.3.3.7 0 .2-.1.4-.2.5-.1.2-.4.3-.6.3h-6.6c-.1 0-.4 0-.4-.5z"
    />
  </>
);
