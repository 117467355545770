import React from "react"
import TabPage from "../../components/TabPage"
import BlockListPage from "../BlockListPage"
import ClassificationPage from "../ClassificationPage"

const CreatorsPage = ({ match: { url } }) => {
  const tabs = [
    {
      path: "/classification",
      component: ClassificationPage,
      label: "Classification"
    },
    {
      path: "/blocklist",
      component: BlockListPage,
      label: "Blocklist"
    }
  ]

  return <TabPage url={url} tabs={tabs} />
}

export default CreatorsPage
