import React, { Fragment } from "react";

export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7144 21H3.28578C1.9881 21 1.00006 19.8807 1.00006 18.72V5.28C1.00006 4.62938 1.23907 4.03885 1.68719 3.61416C2.12735 3.19703 2.7051 3 3.28578 3H20.7144C22.012 3 23.0001 4.11936 23.0001 5.28V18.56C23.0001 19.8027 22.0845 21 20.7144 21ZM3.28578 20H20.7144C21.4286 20 22.0001 19.36 22.0001 18.56V5.28C22.0001 4.64 21.4286 4 20.7144 4H3.28578C2.57149 4 2.00006 4.48 2.00006 5.28V18.72C2.00006 19.36 2.57149 20 3.28578 20Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00006 7.38197L17.2361 12L8.00006 16.618V7.38197ZM9.00006 15L15.0001 12L9.00006 9V15Z"
    />
  </>
);
