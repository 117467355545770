import gql from "graphql-tag"

export const TOPIC_QUERY = gql`
  query topics($topicIds: [Int!]!) {
    topics(topicIds: $topicIds) {
      category {
        title
        topicGroupId
      }

      language
      title
      topicId

      subtopics {
        title
        subtopicId
        term
      }
    }
  }
`

export const TOPIC_EDIT_MUTATION = gql`
  mutation editTopicTitle($id: Int!, $title: String!) {
    editTopicTitle(id: $id, title: $title) {
      success
      message
    }
  }
`
