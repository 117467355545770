import gql from "graphql-tag"

export const QUERIES_LIST_QUERY = gql`
  query queryCard_queriesListQuery {
    listTopicQueryClassifiers {
      id
      topic {
        title
        topicId
      }
      user {
        name
        id
      }
      query
    }
  }
`
