import React, { Component } from "react"
import { Icon } from "../../shared/shared.ui.icon"
import { Button } from "../../shared/shared.ui.button"

import "./index.scss"

class Toast extends Component {
  didMount = false
  state = {
    isVisible: false,
    panelId: null
  }

  componentDidMount() {
    this.didMount = true
    this.setState({
      isVisible: true
    })

    const timeout = this.props.toast?.timeout || 6000

    if (timeout >= 0) {
      this.closeTimeout = setTimeout(() => {
        this.didMount &&
          this.setState({
            isVisible: false
          })
      }, timeout)
    }
  }

  componentWillUnmount() {
    this.didMount = false
    clearTimeout(this.closeTimeout)
    this.closeToast()
  }

  closeToast = () => this.setState({ isVisible: false })

  render() {
    const { isVisible } = this.state
    const { toast } = this.props

    return (
      isVisible && (
        <section className="container-toast">
          <p className="toast-text">{toast.title}</p>
          {/* {toast.link && (
            <Link className="toast-link toast-actions" to={toast.link}>
              View
            </Link>
          )} */}
          {toast.onUndo && (
            <Button
              onClick={toast.onUndo}
              className="toast-button toast-actions"
            >
              Undo
            </Button>
          )}
          <Icon onClick={this.closeToast} className="toast-icon" icon="close" />
        </section>
      )
    )
  }
}

export default Toast
