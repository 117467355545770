import React, { Fragment } from "react";

export default (
  <>
    <g fill="#E4E8EF" fillRule="nonzero">
      <path d="M13.178 55.541c7.267 0 13.179 5.906 13.179 13.165v24.933c0 7.44 5.457 13.626 12.582 14.782v-89.78C38.939 8.419 47.265.1 57.499.1c10.236 0 18.562 8.318 18.562 18.542V129.93c7.125-1.156 12.582-7.343 12.582-14.782V90.215c0-7.259 5.912-13.164 13.179-13.164 7.266 0 13.178 5.905 13.178 13.164v24.934c0 21.968-17.256 39.985-38.939 41.238v29.894h7.237a2.41 2.41 0 0 1 2.412 2.41 2.41 2.41 0 0 1-2.412 2.409H31.702a2.41 2.41 0 0 1-2.412-2.41 2.41 2.41 0 0 1 2.412-2.41h7.237v-51.403C17.256 133.625 0 115.607 0 93.639V68.706c0-7.26 5.912-13.165 13.178-13.165zM5 93.696c0 20.068 16.33 36.394 36.405 36.394a2.404 2.404 0 0 1 2.404 2.404V186.1H71.19v-32.152a2.404 2.404 0 0 1 2.404-2.403C93.67 151.545 110 135.22 110 115.15V90.28c0-4.59-3.735-8.324-8.326-8.324-4.591 0-8.327 3.735-8.327 8.325v24.87c0 10.074-7.587 18.404-17.348 19.595v4.157a2.404 2.404 0 0 1-4.808 0V18.788c0-7.548-6.141-13.688-13.69-13.688-7.55 0-13.692 6.14-13.692 13.688v98.66a2.404 2.404 0 0 1-4.808 0v-4.158c-9.761-1.19-17.348-9.52-17.348-19.595v-24.87c0-4.59-3.736-8.324-8.327-8.324-4.59 0-8.326 3.735-8.326 8.324v24.87z" />
      <path d="M57.5 168.1c1.38 0 2.5 1.007 2.5 2.25v4.5c0 1.243-1.12 2.25-2.5 2.25s-2.5-1.007-2.5-2.25v-4.5c0-1.243 1.12-2.25 2.5-2.25zM51.5 53.1c1.38 0 2.5 1.007 2.5 2.25v4.5c0 1.243-1.12 2.25-2.5 2.25S49 61.093 49 59.85v-4.5c0-1.243 1.12-2.25 2.5-2.25zM62.5 93.1c1.38 0 2.5 1.007 2.5 2.25v4.5c0 1.243-1.12 2.25-2.5 2.25s-2.5-1.007-2.5-2.25v-4.5c0-1.243 1.12-2.25 2.5-2.25zM64.5 32.1c1.38 0 2.5 1.007 2.5 2.25v4.5c0 1.243-1.12 2.25-2.5 2.25S62 40.093 62 38.85v-4.5c0-1.243 1.12-2.25 2.5-2.25zM13.5 76.1c1.38 0 2.5 1.007 2.5 2.25v4.5c0 1.243-1.12 2.25-2.5 2.25S11 84.093 11 82.85v-4.5c0-1.243 1.12-2.25 2.5-2.25zM102.5 105.1c1.38 0 2.5 1.007 2.5 2.25v4.5c0 1.243-1.12 2.25-2.5 2.25s-2.5-1.007-2.5-2.25v-4.5c0-1.243 1.12-2.25 2.5-2.25z" />
    </g>
  </>
);
