import React from "react"
import "./index.scss"
import PropTypes from "prop-types"
import classNames from "classnames"

const Loading = ({ className, white, topMargin, fullpage, ...props }) => {
  const smallClass = classNames("loading-small", { white, topMargin })

  return (
    <div
      className={`loading-component ${className || ""} ${
        fullpage ? "fullpage" : ""
      }`}
    >
      <div className={smallClass}>
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </div>
    </div>
  )
}

Loading.displayName = "Loading"

Loading.propTypes = {
  white: PropTypes.bool,
  fullpage: PropTypes.bool
}

Loading.defaultProps = {
  fullpage: false
}

export default Loading
