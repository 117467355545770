import React, { Fragment } from "react";

export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6667 23H5.33333C4.68717 23 4.08013 22.765 3.63847 22.2958C3.20196 21.832 3 21.2181 3 20.5833V6.41667C3 5.78195 3.20196 5.16801 3.63847 4.70422C4.08013 4.23495 4.68717 4 5.33333 4H18.6667C19.3128 4 19.9199 4.23495 20.3615 4.70422C20.798 5.16801 21 5.78195 21 6.41667V20.5833C21 21.2419 20.6988 21.8312 20.3115 22.2427C19.9279 22.6502 19.3473 23 18.6667 23ZM5.33333 22H18.6667C19.3333 22 20 21.2917 20 20.5833V6.41667C20 5.56667 19.4667 5 18.6667 5H5.33333C4.53333 5 4 5.56667 4 6.41667V20.5833C4 21.4333 4.53333 22 5.33333 22Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 11.5C7 11.2239 7.22386 11 7.5 11H16.5C16.7761 11 17 11.2239 17 11.5C17 11.7761 16.7761 12 16.5 12H7.5C7.22386 12 7 11.7761 7 11.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 17.5C7 17.2239 7.22386 17 7.5 17H16.5C16.7761 17 17 17.2239 17 17.5C17 17.7761 16.7761 18 16.5 18H7.5C7.22386 18 7 17.7761 7 17.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 1C8.77614 1 9 1.22386 9 1.5L9 6.5C9 6.77614 8.77614 7 8.5 7C8.22386 7 8 6.77614 8 6.5L8 1.5C8 1.22386 8.22386 1 8.5 1Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 1C15.7761 1 16 1.22386 16 1.5L16 6.5C16 6.77614 15.7761 7 15.5 7C15.2239 7 15 6.77614 15 6.5L15 1.5C15 1.22386 15.2239 1 15.5 1Z"
    />
  </>
);
