import React, { Fragment } from "react";

export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 5C14 7.76142 11.7614 10 9 10C6.23858 10 4 7.76142 4 5C4 2.23858 6.23858 0 9 0C11.7614 0 14 2.23858 14 5ZM12 5C12 6.65686 10.6569 8 9 8C7.34315 8 6 6.65686 6 5C6 3.34315 7.34315 2 9 2C10.6569 2 12 3.34315 12 5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.383 15.156L17.3918 15.1694C18.8355 17.3694 17.5405 20.6667 14.5997 20.6667H3.40013C1.89522 20.6667 0.826234 19.6842 0.348097 18.6684C-0.130729 17.6511 -0.194134 16.2469 0.667529 15.0836C2.52004 12.5245 5.4614 10.6667 8.99992 10.6667C12.4915 10.6667 15.4785 12.3527 17.374 15.1427L17.383 15.156ZM15.7197 16.2667C16.4196 17.3333 15.7197 18.6667 14.5997 18.6667H3.40013C2.28017 18.6667 1.5802 17.2 2.28017 16.2667C3.82011 14.1333 6.20002 12.6667 8.99992 12.6667C11.7998 12.6667 14.1797 14 15.7197 16.2667Z"
    />
  </>
);
