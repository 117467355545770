import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { compose, withApollo } from "react-apollo"
import InfiniteScroll from "react-infinite-scroller"
import { Link } from "react-router-dom"
import { Input } from "../../shared/shared.ui.input"
import { Loading } from "../../shared/shared.ui.loading"
import { Button } from "../../shared/shared.ui.button"

import AppHeader from "../../components/AppHeader"
import Select from "../../components/Select"
import ListHeader from "../../components/ListHeader"
import ListItem from "../../components/ListItem"
import { useQuery, useLazyQuery } from "@apollo/react-hooks"
import { TOPICS_QUERY, CATEGORIES_QUERY } from "./query.js"
import { LANGUAGE_OPTIONS, PAGINATION } from "../../consts"
import ToastStore from "../../stores/ToastStore"
import { humanizeLargeNumber } from "../../utils"
import "./index.scss"

const defaultLanguage = LANGUAGE_OPTIONS[0]
const defaultCategory = { label: "All categories", value: "" }

const TopicListPage = ({ client, history, ...props }) => {
  // local state
  const [hasMore, setHasMore] = useState(true)
  const [searchTerm, setSearchTerm] = useState("")
  const [language, setLanguage] = useState(defaultLanguage)
  const [categoryOptions, setCategoryOptions] = useState([defaultCategory])
  const [selectedCategory, setSelectedCategory] = useState(defaultCategory)

  // queries and mutations
  const {
    data: topicsData,
    loading: topicsLoading,
    error: topicsError,
    networkStatus: topicsStatus,
    fetchMore: topicsFetchMore,
    refetch: topicsRefetch
  } = useQuery(TOPICS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    client,
    variables: {
      offset: 0,
      limit: PAGINATION.PAGE_SIZE,
      sort: { field: "title", order: "asc" }
    },
    onCompleted: ({ allTopics }) => {
      const topicsCount = allTopics?.entities?.length
      const rows = allTopics?.rows
      const limit = PAGINATION.PAGE_SIZE
      const hasMore = topicsCount >= limit && topicsCount < rows

      setHasMore(hasMore)
    }
  })
  const [loadCategories, { loading: categoriesLoading }] = useLazyQuery(
    CATEGORIES_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      client,
      onCompleted: ({ allTopicGroups }) => {
        const categoryOptions = allTopicGroups.map(
          ({ title, topicGroupId }) => ({
            label: title,
            value: topicGroupId
          })
        )

        setCategoryOptions([defaultCategory, ...categoryOptions])
      },
      onError(e) {
        console.error("TopicListPage @ loadCategories >>>>>", e)

        ToastStore.clearToasts()
        ToastStore.addToast({
          title: "Oops! Error loading categories. Please try again.",
          id: "categories-error"
        })
      }
    }
  )

  // effects
  useEffect(() => {
    // on language filter change, reset other filters
    setCategoryOptions(defaultCategory)

    const newLanguageValue =
      language.value === "global" ? "all" : language.value
    const queryLanguage = newLanguageValue === "none" ? "" : newLanguageValue

    loadCategories({
      variables: {
        language: queryLanguage
      }
    })

    topicsRefetch({
      language: queryLanguage,
      categoryId: null
    })

    setSelectedCategory(defaultCategory)
  }, [language])

  useEffect(() => {
    // on category filter change, fetch topics by category
    if (language.value !== "none") {
      const queryCategory =
        selectedCategory.value === "" ? null : selectedCategory.value
      const queryLanguage = language.value === "global" ? "all" : language.value

      topicsRefetch({
        language: queryLanguage,
        categoryId: queryCategory
      })
    }
  }, [selectedCategory])

  const handleInputChange = e => {
    setSearchTerm(e.target.value)
  }

  const handleSearch = e => {
    e?.preventDefault() && e.preventDefault()

    topicsRefetch({
      title: searchTerm
    })
  }

  const loadMore = async () => {
    if (topicsLoading || topicsError || !hasMore) return

    const variables = {
      offset: topicsData?.allTopics?.entities?.length
    }

    try {
      await topicsFetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.allTopics?.entities?.length) {
            return prev
          }

          if (
            fetchMoreResult.allTopics.entities.length < PAGINATION.PAGE_SIZE
          ) {
            setHasMore(false)
          }

          return Object.assign({}, prev, {
            allTopics: {
              ...prev.allTopics,
              entities: [
                ...prev.allTopics.entities,
                ...fetchMoreResult.allTopics.entities
              ]
            }
          })
        }
      })
    } catch (e) {
      console.error("TopicListPage @ loadMore >>>>>", e)
    }
  }

  // consts and functions used in render
  const isFilterDisabled = language.value === "none"
  const topics = topicsData?.allTopics?.entities ?? []
  const topicsCount = topicsData?.allTopics?.rows ?? "..."
  const topicsListHeaders = [
    { label: `Topic (${topicsCount})` },
    { label: "Category" },
    { label: "Creators" },
    { label: "Upload videos" },
    {
      label: "Upload date",
      tooltipContent: (
        <>
          This metric is relative at
          <br />
          30 days, return to zero in
          <br />
          the end of each month
        </>
      )
    },
    { label: "Total videos" }
  ]

  const renderTopicList = () => {
    if (topicsError) return <p>Error loading topics :(</p>
    if (topicsLoading && topicsStatus !== 3) return <Loading />
    if (!topics?.length) return <p>No topics found.</p>

    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMore}
        loader={
          <div key={Date.now()}>
            <Loading />
          </div>
        }
        hasMore={hasMore}
        initialLoad={false}
        className="topics-list"
      >
        {topics?.map(
          (
            {
              topicId,
              title,
              category: { title: categoryTitle, topicGroupId: categoryId },
              analytics
            },
            index
          ) => {
            const creators =
              analytics?.creators ?? humanizeLargeNumber(analytics?.creators)
            const totalVideos =
              analytics?.posts ?? humanizeLargeNumber(analytics?.posts)
            const columns = [
              {
                label: title,
                onClick: () => history.push(`/topics/edit/${topicId}`)
              },
              {
                label: categoryTitle,
                onClick:
                  language.value !== "none"
                    ? () =>
                        setSelectedCategory({
                          label: categoryTitle,
                          value: categoryId
                        })
                    : null
              },
              {
                label: creators ?? "---"
              },
              { label: "---" },
              { label: "---" },
              {
                label: totalVideos ?? "---"
              }
            ]

            return (
              <ListItem
                key={`${title}-${index}`}
                columns={columns}
                className="topics-item"
              />
            )
          }
        )}
      </InfiniteScroll>
    )
  }

  return (
    <>
      <AppHeader />
      <div className="TopicListPage">
        <div className="page-header">
          <div className="topic-filters">
            <Link to="/topics/new" className="create-new-button">
              <Button icon="add">Create new</Button>
            </Link>
            <form onSubmit={handleSearch} className="topic-search-form">
              <Input
                onChange={handleInputChange}
                value={searchTerm}
                placeholder="Search for a Topic"
                className="topic-search-input"
              />
            </form>
            <Select
              label="Language"
              options={LANGUAGE_OPTIONS}
              value={language}
              onChange={setLanguage}
            />
            <Select
              label="Categories"
              value={selectedCategory}
              options={categoryOptions}
              isLoading={categoriesLoading}
              isDisabled={isFilterDisabled}
              onChange={setSelectedCategory}
            />
          </div>
        </div>

        <div className="page-content">
          <ListHeader
            headers={topicsListHeaders}
            className="topics-list-header"
          />
          {renderTopicList()}
        </div>
      </div>
    </>
  )
}

TopicListPage.propTypes = {
  client: PropTypes.object,
  history: PropTypes.object
}

export default compose(withApollo)(TopicListPage)
