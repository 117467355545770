import React, { Fragment } from "react";

export default (
  <>
    <ellipse cx="12" cy="12" fill="#ed5168" rx="12" ry="12" />
    <path
      fill="#fff"
      d="M12.1 8.7c-.5-3.3-6.9-3.5-6.9 1.4 0 4.3 6.9 8.3 6.9 8.3s6.9-4 6.9-8.3c0-4.9-6.4-4.7-6.9-1.4z"
    />
  </>
);
