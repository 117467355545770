import React, { Fragment } from "react";

export default (
  <>
    <path
      fillRule="evenodd"
      d="M17.07 7.58L8.4 11.42 7.6 9.6l8.66-3.84zm-8.62 4.17l9.33 4.69-.9 1.79-9.33-4.7z"
    />
    <path
      fillRule="evenodd"
      d="M19.33 7.33a2.67 2.67 0 100-5.33 2.67 2.67 0 000 5.33zm0 2a4.67 4.67 0 100-9.33 4.67 4.67 0 000 9.33zM19.33 22a2.67 2.67 0 100-5.33 2.67 2.67 0 000 5.33zm0 2a4.67 4.67 0 100-9.33 4.67 4.67 0 000 9.33zM4.67 14a2.67 2.67 0 100-5.33 2.67 2.67 0 000 5.33zm0 2a4.67 4.67 0 100-9.33 4.67 4.67 0 000 9.33z"
    />
  </>
);
