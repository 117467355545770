import React from "react"
import { Observer } from "mobx-react"
import { useKeycloak } from "@react-keycloak/web"
import ToastStore from "./stores/ToastStore"
import Toast from "./components/Toast"
import Loading from "./components/Loading"
import Router from "./Router"

const App = () => {
  const { initialized } = useKeycloak()

  if (!initialized) return <Loading fullpage />

  return (
    <Observer>
      {() => (
        <>
          <Router />

          {ToastStore.toasts.map((toast, index) => (
            <Toast key={`${index}-${toast.id}`} toast={toast} />
          ))}
        </>
      )}
    </Observer>
  )
}

export default App
