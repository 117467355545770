import React from "react";

export default (
  <>
    <line
      x1="125.695"
      y1="82.2382"
      x2="177.336"
      y2="38.1087"
      stroke="#E5E5E5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeDasharray="3 9"
    />
    <line
      x1="65.5434"
      y1="151.847"
      x2="115.573"
      y2="92.3784"
      stroke="#E5E5E5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeDasharray="3 9"
    />
    <path
      d="M156.412 181.412L122.588 92.6861"
      stroke="#E5E5E5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeDasharray="3 9"
    />
    <line
      x1="24.1362"
      y1="82.9027"
      x2="112.313"
      y2="85.778"
      stroke="#E5E5E5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeDasharray="3 9"
    />
    <line
      x1="89.2884"
      y1="21.3506"
      x2="116.449"
      y2="79.4175"
      stroke="#E5E5E5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeDasharray="3 9"
    />
    <line
      x1="187.117"
      y1="123.571"
      x2="162.787"
      y2="181.589"
      stroke="#E5E5E5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeDasharray="3 9"
    />
    <line
      x1="20.7202"
      y1="87.8263"
      x2="58.4148"
      y2="151.907"
      stroke="#E5E5E5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeDasharray="3 9"
    />
    <line
      x1="151.128"
      y1="187.346"
      x2="67.7389"
      y2="163.656"
      stroke="#E5E5E5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeDasharray="3 9"
    />
    <circle
      cx="15.2353"
      cy="80.9213"
      r="13.2353"
      fill="white"
      stroke="#E5E5E5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeDasharray="3 9"
    />
    <circle
      cx="60.3334"
      cy="159.353"
      r="13.2353"
      fill="white"
      stroke="#E5E5E5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeDasharray="3 9"
    />
    <circle
      cx="182.882"
      cy="30.9213"
      r="13.2353"
      fill="white"
      stroke="#E5E5E5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeDasharray="3 9"
    />
    <circle
      cx="188.765"
      cy="117.196"
      r="13.2353"
      fill="white"
      stroke="#E5E5E5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeDasharray="3 9"
    />
    <circle
      cx="83.8627"
      cy="15.2353"
      r="13.2353"
      fill="white"
      stroke="#E5E5E5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeDasharray="3 9"
    />
    <circle
      cx="159.353"
      cy="188.765"
      r="13.2353"
      fill="white"
      stroke="#E5E5E5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeDasharray="3 9"
    />
    <circle cx="120.137" cy="86.8041" r="13.2353" fill="#B9DDDB" />
  </>
);
