import React, { Fragment } from "react";

export default (
  <>
    <circle cx="12" cy="12" r="12" fill="#ffda6b" />
    <ellipse cx="12" cy="16.8" rx="3.5" ry="4.8" />
    <ellipse
      cx="6.4"
      cy="9.1"
      rx="2.5"
      ry="1.7"
      transform="rotate(-81.396 6.4453563 9.0509093)"
    />
    <ellipse
      cx="17.5"
      cy="9"
      rx="1.7"
      ry="2.5"
      transform="rotate(-8.604 17.5017931 9.04855514)"
    />
    <path d="M5.1 3.8h-.2c-.1-.1-.1-.3 0-.4 0 0 .3-.5.9-.7.5-.3 1.3-.5 2.3.2.1.1.2.3.1.4-.1.1-.4.1-.5 0-.6-.4-1.1-.4-1.7-.1-.4.2-.7.5-.7.5 0 .1-.1.1-.2.1zm13.8 0h.2c.1-.1.1-.3.1-.4 0 0-.3-.5-.9-.7-.5-.3-1.3-.5-2.3.2-.1.1-.2.3-.1.4.1.1.3.2.4.1.6-.4 1.2-.5 1.7-.2.4.2.6.5.7.5 0 .1.1.1.2.1z" />
  </>
);
