import React from "react"
import { Header } from "../../shared/shared.ui.header"

import Dropdown from "../../components/Dropdown"

const PostListHeader = ({
  postsCount,
  dateRange,
  dateRangeOptions,
  handleDateRangeChange,
  sortBy,
  sortByOptions,
  handleSortByChange,
  isSelectAllDisabled,
  isSelectAllActive,
  handleSelectAll
}) => {
  return (
    <div className="PostListHeader">
      <div className="posts-count">
        <Header size="md">
          {postsCount?.toLocaleString()} video
          {postsCount !== 1 && "s"}
        </Header>
      </div>

      <div className="actions">
        <Dropdown
          label="Sort by"
          onChange={handleSortByChange}
          value={sortBy}
          options={sortByOptions}
        />

        <Dropdown
          label="Date Range"
          onChange={handleDateRangeChange}
          value={dateRange}
          options={dateRangeOptions}
        />

        <div
          className={`radio-input${isSelectAllDisabled ? " disabled" : ""}${
            isSelectAllActive ? " active" : ""
          }`}
          onClick={handleSelectAll}
        >
          <div className="radio-button" />
          <Header size="xxs" weight="light" className="radio-label">
            Select all
          </Header>
        </div>
      </div>
    </div>
  )
}

export default PostListHeader
