import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useQuery } from "@apollo/react-hooks"
import { withApollo, compose } from "react-apollo"
import { withRouter } from "react-router-dom"
import dayjs from "dayjs"

import Loading from "../../../../components/Loading"
import ListHeader from "../../../../components/ListHeader"
import ListItem from "../../../../components/ListItem"
import HighlightedQuery from "../../../../components/HighlightedQuery"
import { ReactComponent as CheckMark } from "../../../../assets/img/check.svg"
import { ReactComponent as XMark } from "../../../../assets/img/x.svg"
import { queryStatus } from "../../../../consts/queryStatus"
import { QUERIES_LIST_QUERY } from "./query"
import "./index.scss"

const QueryStatus = ({ status }) => {
  return (
    <div className={`QueryStatus ${status}`}>
      {status === queryStatus.rejected ? <XMark /> : <CheckMark />}
    </div>
  )
}

const Status = ({ client, history, location }) => {
  // queries and mutations
  const { data, loading, error, refetch } = useQuery(QUERIES_LIST_QUERY, {
    client,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      status: [queryStatus.approved, queryStatus.rejected]
    }
  })

  // effects
  useEffect(() => {
    if (location?.state?.refetch) {
      refetch()
    }
  }, [location, refetch])

  // functions
  const handleModeration = queryObj => {
    const rootUrl = "/queries/status"

    history.push({
      pathname: `${rootUrl}/moderate`,
      state: {
        backgroundPage: {
          pathname: rootUrl
        },
        queryObj
      }
    })
  }

  // rendering
  if (loading) return <Loading />
  if (error)
    return (
      <p>
        Oops! Erro loading terms and queries. Please, refresh the page or try
        again later.
      </p>
    )

  const reversedList = [...data?.listTopicQueryClassifiers]?.reverse() || []
  const queriesListHeaders = [
    { label: "Approval owner" },
    { label: "Reason of reject" },
    { label: "Queries" },
    { label: "Upload date" },
    { label: "Status" }
  ]

  return (
    <div className="Status">
      <ListHeader headers={queriesListHeaders} />

      {reversedList?.map(queryObj => {
        const username = queryObj?.user?.name
        const topicTitle = queryObj?.topic?.title
        const date = queryObj.updatedAt || queryObj.createdAt

        const columns = [
          {
            label: (
              <>
                <p className="status-username">{username}</p>
                <p className="status-topic">{topicTitle}</p>
              </>
            )
          },
          {
            label:
              queryObj.status === queryStatus.rejected
                ? queryObj.feedback || "No feedback provided."
                : "-"
          },
          { label: <HighlightedQuery query={queryObj.query} /> },
          { label: dayjs(date).format("DD/MM/YYYY") },
          { label: <QueryStatus status={queryObj.status} /> }
        ]

        return (
          <ListItem
            key={queryObj.id}
            onClick={() => handleModeration(queryObj)}
            columns={columns}
          />
        )
      })}
    </div>
  )
}

QueryStatus.propTypes = {
  status: PropTypes.string.isRequired
}

Status.propTypes = {
  client: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default compose(withApollo)(withRouter(Status))
