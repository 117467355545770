import React from "react"
import { useKeycloak } from "@react-keycloak/web"
import { Redirect } from "react-router-dom"
import Loading from "../../components/Loading"

const LoginPage = ({ location }) => {
  const { keycloak } = useKeycloak()
  const from = location?.state?.from || { pathname: "/" }

  if (!keycloak.authenticated) {
    keycloak.login({
      redirectUri: `${window?.location?.origin}${from?.pathname}`
    })
    return <Loading fullpage />
  }

  return <Redirect to={from} />
}

export default LoginPage
