import React from "react"
import { PROVIDERS } from "../../consts"
import { countries } from "../../utils"
import { Icon } from "../../shared/shared.ui.icon"
import { Header } from "../../shared/shared.ui.header"
import "./index.scss"

const CardCreator = ({ infos, className }) => {
  const providerName = PROVIDERS?.[infos.provider]?.NAME.toLowerCase()
  return (
    <section className={`container-card-creator ${className}`}>
      <div className="creator-thumbnail-container">
        <div className="thumbnail-creator">
          <img src={`${infos.thumbnail}`} alt={`${infos.title}`} />
        </div>
        <div className={`provider-badge-component ${providerName}`}>
          <Icon icon={providerName} />
        </div>
      </div>
      <div className="creator-text-container">
        <Header weight="regular" size="sm">
          {infos.title}
        </Header>
        <Header weight="light" size="xxs">
          {countries.byValue(infos.country).NAME}
        </Header>
      </div>
    </section>
  )
}

export default CardCreator
