import React, { Fragment } from "react";

export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5084 0L25.3499 6.47738L37.2262 4.43059L31.9924 28L18.506 25.9732L18.4768 25.9779L5.91946 27.9847L0.0737288 4.63327L0 4.33748L11.7009 6.38903L18.5084 0ZM10.7905 20.5493L18.4756 19.3214L27.0698 20.6129L28.8015 12.553L23.2472 13.511L18.499 9.01558L18.4884 9.00616L13.7777 13.4262L8.78845 12.5518L10.7905 20.5493Z"
    />
  </>
);
