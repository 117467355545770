import React from "react"
import VideoCard from "../VideoCard"
import "./index.scss"

const VideoGrid = ({ videos = [], tagAction }) => {
  const items =
    videos.length > 0 &&
    videos.map((video, index) => (
      <VideoCard
        tagAction={tagAction}
        index={index}
        key={video.postId}
        video={video}
      />
    ))
  return (
    <div className="video-grid-container">
      <div className="video-grid-wrapper">{items}</div>
    </div>
  )
}

export default VideoGrid
