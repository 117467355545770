import React, { Fragment } from "react";

export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.62466 6.21905C6.05592 6.56406 6.12584 7.19335 5.78083 7.62461L2.28059 11.9999L5.78083 16.3752C6.12584 16.8065 6.05592 17.4358 5.62466 17.7808C5.1934 18.1258 4.5641 18.0559 4.21909 17.6246L0.219094 12.6246C-0.0730808 12.2594 -0.0730808 11.7404 0.219094 11.3752L4.21909 6.37522C4.5641 5.94396 5.1934 5.87404 5.62466 6.21905Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 12C1 11.4477 1.44772 11 2 11L12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13L2 13C1.44772 13 1 12.5523 1 12Z"
    />
  </>
);
