import React, { Fragment } from "react";

export default (
  <>
    <rect x="4" y="17" width="17" height="2" rx="1" />
    <rect x="4" y="11" width="17" height="2" rx="1" />
    <rect x="4" y="5" width="17" height="2" rx="1" />
    <rect x="7" y="9" width="6" height="2" rx="1" transform="rotate(-90 7 9)" />
    <rect
      x="17"
      y="21"
      width="6"
      height="2"
      rx="1"
      transform="rotate(-90 17 21)"
    />
    <rect
      x="11"
      y="15"
      width="6"
      height="2"
      rx="1"
      transform="rotate(-90 11 15)"
    />
  </>
);
