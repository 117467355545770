import React from "react"
import PropTypes from "prop-types"
import { NavLink, Route, Switch, Redirect } from "react-router-dom"
import { Loading } from "../../shared/shared.ui.loading"

import "./index.scss"
import AppHeader from "../../components/AppHeader"

const TabPage = ({
  url,
  tabs,
  className = "",
  renderHeader = null,
  loading = false,
  error = false
}) => {
  const renderPage = () => {
    if (loading) return <Loading />
    if (error) return <p>Error :(</p>

    return (
      <div className={`TabPage ${className}`}>
        {renderHeader && <div className="tab-header">{renderHeader()}</div>}

        <div className="tabs-container">
          {tabs.map(({ path, label }) => (
            <NavLink
              key={label}
              activeClassName="active"
              className="tab-link"
              to={`${url}${path}`}
            >
              {label}
            </NavLink>
          ))}
        </div>

        <div className="tab-content">
          <Switch>
            {/* if no tab is provided, go to first tab */}
            <Route exact path={`${url}`}>
              <Redirect to={`${url}${tabs[0].path}`} />
            </Route>

            {tabs.map(({ path, component, label }) => (
              <Route key={label} path={`${url}${path}`} component={component} />
            ))}
          </Switch>
        </div>
      </div>
    )
  }

  return (
    <>
      <AppHeader />

      {renderPage()}
    </>
  )
}

export default TabPage

const tabsShape = {
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired
}

TabPage.propTypes = {
  url: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape(tabsShape)).isRequired,
  className: PropTypes.string,
  renderHeader: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.bool
}
