import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { compose, withApollo } from "react-apollo"
import { useQuery, useMutation } from "@apollo/react-hooks"

import { TOPIC_QUERY, TOPIC_EDIT_MUTATION } from "./query.js"
import { LANGUAGE_OPTIONS } from "../../consts"
import { getRole } from "../../utils"
import TabPage from "../../components/TabPage"
import TopicEditHeader from "../../components/TopicEditHeader"
import ToastStore from "../../stores/ToastStore"
import TermsAndQueries from "./tabs/TermsAndQueries"
import Videos from "./tabs/Videos"
import Subtopics from "./tabs/Subtopics"
import "./index.scss"

const TopicEditPage = ({
  client,
  history,
  location,
  match: { params, url }
}) => {
  const topicId = Number(params.topicId)

  // local state
  const [inputState, setInputState] = useState({ name: "" })
  const [initialName, setInitialName] = useState("")

  // queries and mutations
  const {
    data: topicData,
    loading: topicLoading,
    error: topicError,
    refetch: topicRefetch
  } = useQuery(TOPIC_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    client,
    variables: {
      topicIds: [topicId]
    },
    onCompleted: ({ topics }) => {
      const name = topics[0].title

      setInitialName(name)
      setInputState({
        name
      })
    }
  })
  const [topicEdit] = useMutation(TOPIC_EDIT_MUTATION, {
    client,
    onError(e) {
      console.error("TopicEditPage @ topicEdit @ onError >>>>>", e)
      ToastStore.clearToasts()
      ToastStore.addToast({
        title: "Oops! An error ocurred. Please try again.",
        id: "error"
      })
    },
    onCompleted({ editTopicTitle: { success, message } }) {
      if (success) {
        history.push({
          pathname: "/topic/success",
          state: { backgroundPage: location }
        })
      } else {
        console.error("TopicEditPage @ topicEdit @ onCompleted >>>>>", message)
        ToastStore.clearToasts()
        ToastStore.addToast({
          title: `Oops! Name was not updated. Please try again.`,
          id: "edit-error"
        })
      }
    }
  })

  // effects
  useEffect(() => {
    // on "successful change modal" closing, refetch topic
    if (location?.state?.refetch) {
      topicRefetch()
    }
  }, [location])

  // functions
  const getTopicLanguage = languageInitials => {
    if (!languageInitials) return "Undefined"

    const languageOption = LANGUAGE_OPTIONS.find(
      languageOption => languageOption.value === languageInitials
    )

    return languageOption?.label || "Global"
  }
  const handleInputChange = (value, name) => {
    setInputState(inputState => ({
      ...inputState,
      [name]: value
    }))
  }
  const handleSave = () => {
    const variables = {
      id: topicId,
      title: inputState.name
    }

    topicEdit({
      variables
    })
  }
  const onReset = () => {
    setInputState({
      name: initialName
    })
  }

  // rendering
  const renderHeader = () => {
    const role = getRole()
    const topic = topicData.topics[0]
    const {
      language,
      category: { title: categoryTitle }
    } = topic
    const topicLanguage = getTopicLanguage(language)
    const isResetDisabled = inputState.name === initialName
    const isSaveDisabled = isResetDisabled || !inputState.name.length

    return (
      <TopicEditHeader
        {...{
          handleInputChange,
          handleSave,
          onReset,
          initialName,
          isResetDisabled,
          isSaveDisabled,
          categoryTitle,
          topicLanguage,
          inputState,
          role
        }}
      />
    )
  }

  const tabs = useMemo(() => {
    const topic = topicData?.topics?.[0] || {}
    const subtopics = topic?.subtopics || []

    return [
      {
        path: "/queries",
        component: () => <TermsAndQueries topic={topic} />,
        label: "Terms and queries"
      },
      {
        path: "/videos",
        component: () => <Videos topicId={topicId} />,
        label: "Videos"
      },
      {
        path: "/subtopics",
        component: () => <Subtopics subtopics={subtopics} />,
        label: "Subtopics"
      }
    ]
  }, [topicId, topicData])

  return (
    <TabPage
      className="TopicEditPage"
      renderHeader={renderHeader}
      loading={topicLoading}
      error={topicError}
      url={url}
      tabs={tabs}
    />
  )
}

TopicEditPage.propTypes = {
  client: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default compose(withApollo)(TopicEditPage)
