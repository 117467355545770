import React, { Component } from "react"
import { Button } from "../../shared/shared.ui.button"
import { Loading } from "../../shared/shared.ui.loading"
import { GroupMark } from "../../shared/shared.ui.group-mark"
import { CollapsedMultiMark } from "../../shared/shared.ui.collapsed-multi-mark"
import { Input } from "../../shared/shared.ui.input"
import { Icon } from "../../shared/shared.ui.icon"

import AppHeader from "../../components/AppHeader"
import Insights from "../../frontend_commons"
import "./index.scss"

const { Constants } = Insights
const { Actions } = Insights.Utils
const {
  PlayerCard,
  Wizard,
  StepCard,
  ReactSelect,
  withFilters
} = Insights.Components

class WizardPage extends Component {
  inputProject = React.createRef()
  inputDetail = React.createRef()
  inputKeyword = React.createRef()

  state = {
    loading: true,
    loadingNewProject: false,
    loadingWizard: false,
    post: null,
    currentStep: 1,
    filterCategories: "",
    mainTopicList: [],
    mainTopic: null,
    keywords: [],
    details: "",
    newProject: ""
  }

  otherTopics = {}

  componentDidMount() {
    this.loadPost()
    //espera os filtros carregarem
    this.props.onFiltersLoad(() => {
      this.setFiltersListener()
      this.setOthersOptsOnCategories()
    })
  }

  setFiltersListener = () => {
    const { filters } = this.props
    filters.topicGroup.addListener(this.setOthersOptsOnCategories)
  }

  //adiciona outros nas options
  setOthersOptsOnCategories = () => {
    const { filters } = this.props

    filters.topicGroup.transformOptions(options => {
      return options.reduce((acc, _opts) => {
        _opts.options.push({
          label: "Others",
          value: `${_opts.value} - ${_opts.label}`,
          onInputChange: (cat, value) => {
            this.otherTopics[_opts.value + "_" + cat] = value
          }
        })

        acc.push(_opts)

        return acc
      }, [])
    })
  }

  changeStep = currentStep => {
    if (currentStep === 5) {
      const mainTopicList = this.generateMainTopicList()

      this.setState({
        currentStep,
        mainTopicList,
        mainTopic: mainTopicList.length === 1 ? mainTopicList[0] : null
      })
    } else {
      this.setState({ currentStep })
    }
  }

  generateMainTopicList = () => {
    const { filters } = this.props

    const mainTopicList = filters.topicGroup.selecteds.reduce(
      (acc, topicGroup) => {
        if (topicGroup.selecteds) {
          const withOutOthers = topicGroup.selecteds.filter(
            t => t.label !== "Others"
          )
          acc = acc.concat(withOutOthers)
        }
        return acc
      },
      []
    )

    const othersToOptions = Object.keys(this.otherTopics).map(k => ({
      label: `${k.split("_")[1]} - ${this.otherTopics[k]}`,
      value: `${k} - ${this.otherTopics[k]}`
    }))
    const allTopicsList = mainTopicList.concat(othersToOptions)

    return allTopicsList
  }

  changeMainTopic = value => {
    this.setState({
      mainTopic: value
    })
  }

  addKeyword = (k, input) => {
    if (k) {
      //add if not in array
      const keywords = [...this.state.keywords.filter(kw => kw !== k), k]
      this.setState({ keywords })
    }

    input.value = ""
  }

  removeKeyword = k => {
    const keywords = this.state.keywords.filter(kw => kw !== k)
    this.setState({ keywords })
  }

  changeFilterCategories = e => {
    const filterCategories = e.target.value
    this.setState({ filterCategories })
  }

  loadPost = () => {
    this.setState(
      {
        loading: true
      },
      this.loadAction
    )
  }

  saveNewProject = () => {
    const title = this.inputProject.value

    if (title) {
      this.setState(
        {
          loadingNewProject: true
        },
        () => {
          this.projectCall(title)
        }
      )
    }
  }

  projectCall = title => {
    const { filters, user } = this.props
    const createdBy = user.id

    Actions.createProject({
      variables: {
        title,
        createdBy
      },
      fields: `
                ... on TaggerProject {
                    projectId
                    title
                }
            `
    })
      .then(response => {
        if (response && response.projectId) {
          setTimeout(() => {
            filters.taggerProjects.reaload().then(() => {
              this.setState({
                loadingNewProject: false
              })
            })
          }, 2000)
        }
      })
      .catch(err => {
        console.error(err)
        alert("Something goes wrong!")
        this.setState({
          loading: false
        })
      })
  }

  loadAction = () => {
    const { postId, provider } = this.props.match.params

    if (postId && provider) {
      Actions.posts({
        variables: {
          postsInfo: [{ id: postId, provider }]
        },
        fields: Constants.Fields.postCard
      })
        .then(posts => {
          this.setState({
            loading: false,
            post: posts[0]
          })
        })
        .catch(err => {
          console.error(err)
          this.setState({
            loading: false
          })
        })
    }
  }

  save = () => {
    const { variables, match } = this.props
    const { postId, provider } = match.params
    const { keywords } = this.state

    const detailing = this.inputDetail.value
    const detectedLanguage = variables.language
    const projectId = variables.taggerProjects
    const topicIds = variables.topicGroup
      .flat()
      .filter(t => typeof t === "number")
    //se o valor for um ID então eu coloco, se for uma string é pq é um other
    const mainTopic =
      typeof this.state.mainTopic.value === "number"
        ? this.state.mainTopic.value
        : parseInt(this.state.mainTopic.value.split("_"))
    const formatIds = variables.formatGroups.flat()

    //mandrake 🤘
    const otherTopics = Object.keys(this.otherTopics).map(
      k => `${k.split("_")[1]} - ${this.otherTopics[k]}`
    )

    const vars = {
      postId,
      provider,
      topicIds,
      projectId,
      mainTopic,
      keywords,
      detectedLanguage,
      formatIds,
      detailing,
      otherTopics
    }

    this.setState(
      {
        loadingWizard: true
      },
      () => {
        this.tagAction(vars)
      }
    )
  }

  tagAction = variables => {
    Actions.tagPost({
      variables
    })
      .then(tagPost => {
        this.setState({
          loadingWizard: false
        })

        if (tagPost) {
          window.close()
        } else {
          alert("something goes wrong, try again or call the devs 🐊")
        }
      })
      .catch(err => {
        console.error(err)
        this.setState({
          loadingWizard: false
        })
      })
  }

  renderKeywords() {
    const { keywords } = this.state
    if (keywords.length) {
      return (
        <>
          <p>
            <strong>Keyword list:</strong>
          </p>
          <div className="keyword-list">
            {keywords.map(k => (
              <div
                className="keyword"
                key={k}
                onClick={() => {
                  this.removeKeyword(k)
                }}
                title="click me to delete"
              >
                <span>{k}</span>
                <Icon icon="close" />
              </div>
            ))}
          </div>
        </>
      )
    }
    return null
  }

  renderContent() {
    const { filters } = this.props
    const {
      post,
      currentStep,
      filterCategories,
      mainTopicList,
      mainTopic,
      keywords,
      loadingNewProject,
      loadingWizard
    } = this.state

    const canNextProject = !!filters.taggerProjects.selected
    const canNextLang = !!filters.language.selected
    const canNextTopics = !!filters.topicGroup.selecteds.length
    const canNextMain = !!mainTopic
    const canNextKeywords = !!keywords.length
    const canSave = true

    return (
      <>
        {post && <PlayerCard post={post} />}
        <Wizard
          loading={loadingWizard}
          currentStep={currentStep}
          onPrev={this.changeStep}
          onNext={this.changeStep}
        >
          <StepCard title="Whats a project name?" canNext={canNextProject}>
            {loadingNewProject ? (
              <Loading />
            ) : (
              <>
                <ReactSelect
                  placeholder="Select a Project"
                  onChange={filters.taggerProjects.change}
                  value={filters.taggerProjects.selected}
                  options={filters.taggerProjects.options}
                />
                <strong className="split">OR</strong>
                <Input
                  placeholder="Create a new project"
                  inputRef={i => {
                    this.inputProject = i
                  }}
                />
                <Button onClick={this.saveNewProject} className="fluid">
                  Create
                </Button>
              </>
            )}
          </StepCard>
          <StepCard
            title="What is the language of this video?"
            canNext={canNextLang}
          >
            <GroupMark
              options={filters.language.options}
              onChange={filters.language.change}
              type="radio"
              selecteds={filters.language.selected}
            />
          </StepCard>
          <StepCard
            title="What is the format of this video?"
            subtitle="You can choose more than one format."
            canNext={filters.formatGroups.selecteds.length > 0}
          >
            <CollapsedMultiMark
              options={filters.formatGroups.options}
              selecteds={filters.formatGroups.selecteds}
              typeMark="checkbox"
              onChange={filters.formatGroups.change}
            />
          </StepCard>
          <StepCard
            title="Which topics does this video belongs to?"
            canNext={canNextTopics}
            validationWarning="Select at least one topic"
          >
            <Input
              placeholder="Search for a topic"
              onChange={this.changeFilterCategories}
            />
            <CollapsedMultiMark
              filter={filterCategories}
              options={filters.topicGroup.options}
              selecteds={filters.topicGroup.selecteds}
              typeMark="checkbox"
              onChange={filters.topicGroup.change}
            />
          </StepCard>
          <StepCard title="What is the main topic?" canNext={canNextMain}>
            <GroupMark
              options={mainTopicList}
              onChange={this.changeMainTopic}
              type="radio"
              selecteds={mainTopic}
            />
          </StepCard>
          <StepCard title="What is the keyword?" canNext={canNextKeywords}>
            <div className="input-keyword-container">
              <Input
                placeholder="Type a 'keyword' and press enter"
                inputRef={i => {
                  this.inputKeyword = i
                }}
                onEnter={this.addKeyword}
              />
              <Button
                onClick={() => {
                  this.addKeyword(this.inputKeyword.value, this.inputKeyword)
                }}
                className="fluid"
              >
                Add keyword
              </Button>
            </div>
            {this.renderKeywords()}
          </StepCard>
          <StepCard
            title="What is the detailing?"
            canNext={canSave}
            saveAction={this.save}
          >
            <Input
              placeholder="Tell me about the subject of the video."
              inputRef={i => {
                this.inputDetail = i
              }}
              type="textarea"
            />
          </StepCard>
        </Wizard>
      </>
    )
  }

  render() {
    const { loading } = this.state

    return (
      <>
        <AppHeader fixed />
        <div className="wizard-page-new-container">
          {loading ? <Loading /> : this.renderContent()}
        </div>
      </>
    )
  }
}

export default withFilters(WizardPage, "taggingPost")
