const escapedCharacters = new RegExp(/\r?\n|\r|[;,]/g)
export default function csvExport(data, { extraInfoLine } = {}) {
  const columnTitles = `${Object.keys(data[0]).join(",")}\r\n`
  const rowData = data
    .map(d =>
      Object.keys(d)
        .map(k => {
          if (typeof d[k] === "string") {
            const addAtStart = d[k].charAt(0) !== '"'
            const addAtEnd = d[k].charAt(d[k].length) !== '"'
            const s = `${addAtStart ? '"' : ""}${d[k]}${addAtEnd ? '"' : ""}`
            return s.replace(escapedCharacters, "")
          }
          if (Array.isArray(d[k]))
            return `"${d[k].join(", ")}"`.replace(escapedCharacters, "")
          return d[k]
        })
        .join(",")
    )
    .join("\r\n")

  const encoder = new TextEncoder("windows-1252")
  const csv = encoder.encode([
    (extraInfoLine ? `${extraInfoLine}\r\n` : "") + columnTitles + rowData
  ])

  return new Blob([csv], { type: "text/csv;charset=windows-1252;" })
}
