import { client as Client } from "../../ApolloProvider"
import operations from "./operations"

const operationConfigs = {
  query: {
    method: "query",
    queryName: "query"
  },
  mutation: {
    method: "mutate",
    queryName: "mutation"
  }
}

class Actions {
  constructor() {
    this.Client = Client
    this.buildOperations()
  }

  makeIndividualOp(operationName) {
    this[operationName] = ({
      variables = null,
      fields,
      customVariables,
      ...rest
    }) =>
      new Promise((resolve, reject) => {
        const operationType = operations[operationName].type

        const query = operations[operationName].fields(fields, customVariables)
        const { method, queryName } = operationConfigs[operationType]

        Client[method]({
          [queryName]: query,
          variables,
          ...rest
        })
          .then(({ data }) => {
            if (data && data[operationName]) {
              resolve(data[operationName])
            } else {
              resolve(data)
            }
          })
          .catch(res => {
            console.warn(res)
            reject(res)
          })
      })
  }

  buildOperations() {
    const oprs = Object.keys(operations)
    oprs.forEach(opName => this.makeIndividualOp(opName))
  }
}

export default new Actions()
