import React from "react"
import PropTypes from "prop-types"
import { NavLink } from "react-router-dom"
import classNames from "classnames"
import { useKeycloak } from "@react-keycloak/web"

import logo from "../../assets/logo.svg"
import { getRole } from "../../utils/auth"
import "./index.scss"

const AppHeader = ({ fixed }) => {
  const { keycloak } = useKeycloak()
  const role = getRole()

  const logout = () => {
    keycloak.logout()
  }

  const containerClass = classNames("header", { fixed })

  return (
    <div className={containerClass}>
      <div className="logo">
        <NavLink to={`/`}>
          <img src={logo} alt="Winnin" />
          <span className="product-name">tagger</span>
        </NavLink>
      </div>

      <div className="nav">
        <NavLink activeClassName="active" to={`/home`}>
          Home
        </NavLink>
        <NavLink activeClassName="active" to={`/search`}>
          Search Topics
        </NavLink>
        <NavLink activeClassName="active" to={`/topics`}>
          Topics
        </NavLink>
        <NavLink activeClassName="active" to={`/subtopics`}>
          Subtopics
        </NavLink>
        <NavLink activeClassName="active" to={`/creators`}>
          Creators
        </NavLink>

        {role.value !== "admin" && (
          <NavLink activeClassName="active" to={`/queries`}>
            Queries
          </NavLink>
        )}

        <button className="btn" onClick={logout}>
          Logout
        </button>
      </div>
    </div>
  )
}

AppHeader.propTypes = {
  fixed: PropTypes.bool
}

export default AppHeader
