import React, { Fragment } from "react";

export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1526 6.95156C12.4802 6.96692 12.7333 7.24492 12.7179 7.57248L12.5277 11.6269L14.6756 12.5098C14.9789 12.6345 15.1237 12.9815 14.999 13.2847C14.8744 13.588 14.5274 13.7328 14.2241 13.6082L11.6893 12.5662C11.4572 12.4708 11.3101 12.2398 11.3219 11.9892L11.5317 7.51683C11.5471 7.18927 11.8251 6.93619 12.1526 6.95156Z"
      fill="#868A90"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.125 18.0625C15.4042 18.0625 18.0625 15.4042 18.0625 12.125C18.0625 8.84581 15.4042 6.1875 12.125 6.1875C8.84581 6.1875 6.1875 8.84581 6.1875 12.125C6.1875 15.4042 8.84581 18.0625 12.125 18.0625ZM12.125 19.25C16.06 19.25 19.25 16.06 19.25 12.125C19.25 8.18997 16.06 5 12.125 5C8.18997 5 5 8.18997 5 12.125C5 16.06 8.18997 19.25 12.125 19.25Z"
      fill="#868A90"
    />
  </>
);
