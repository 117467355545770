import Constants from "../constants"

import FilterHandler from "./filter-handle"
import Actions from "./actions"
import splitProviderFromId from "./splitProviderFromId"

const { Countries } = Constants

const defaultsValues = {
  topicCategories: {
    label: "All Categories",
    value: "---"
  },
  topic: {
    label: "All Topics",
    value: "---"
  }
}

const agesOptions = [
  {
    key: "all",
    value: "---",
    label: "All Ages",
    filterName: "Ages",
    buttonLabel: "All"
  },
  {
    key: "a13_17",
    value: "a13_17",
    label: "13 - 17",
    filterName: "Ages",
    buttonLabel: "Teens"
  },
  {
    key: "a18_24",
    value: "a18_24",
    label: "18 - 24",
    filterName: "Ages",
    buttonLabel: "Young Adults"
  },
  {
    key: "a25_34",
    value: "a25_34",
    label: "25 - 34",
    filterName: "Ages",
    buttonLabel: "Adults"
  },
  {
    key: "a35_44",
    value: "a35_44",
    label: "35 - 44",
    filterName: "Ages",
    buttonLabel: "Mature Adults"
  },
  {
    key: "a45_54",
    value: "a45_54",
    label: "45 - 54",
    filterName: "Ages",
    buttonLabel: "Midlife Adults"
  },
  {
    key: "a55_64",
    value: "a55_64",
    label: "55 - 64",
    filterName: "Ages",
    buttonLabel: "Seniors"
  },
  {
    key: "a65_99",
    value: "a65_99",
    label: "65 +",
    filterName: "Ages",
    buttonLabel: "Senior Senior"
  }
]

const countries = [
  {
    key: "all",
    value: "---",
    label: "All Countries",
    filterName: "Countries"
  }
].concat(
  ...Countries.map(v => ({
    key: v.ALPHA_2,
    value: v.ALPHA_2,
    label: v.NAME,
    filterName: "Countries"
  }))
)

const gendersOptions = [
  {
    key: "f",
    value: "f",
    label: "Female",
    icon: "female",
    filterName: "Genders"
  },
  {
    key: "m",
    value: "m",
    label: "Male",
    icon: "male",
    filterName: "Genders"
  }
]

const searchSortByOptions = [
  {
    key: "bestMatch",
    value: "---",
    label: "Best Match"
  },
  {
    key: "engagement",
    value: '{ "field": "engagement", "order": "desc" }',
    label: "Most Engaged"
  },
  {
    key: "views",
    value: '{ "field": "views", "order": "desc" }',
    label: "Most Viewed"
  },
  {
    key: "published_at",
    value: '{ "field": "published_at", "order": "desc" }',
    label: "Most Recent"
  }
]

const providersOptions = [
  {
    label: "Facebook",
    value: "fb",
    filterName: "providers"
  },
  {
    label: "Youtube",
    value: "yt",
    filterName: "providers"
  },
  {
    label: "Instagram",
    value: "in",
    filterName: "providers"
  }
]

const languageOptions = [
  {
    label: "Portuguese",
    value: "por",
    filterName: "language"
  },
  {
    label: "English",
    value: "eng",
    filterName: "language"
  }
]

const allLanguageOptions = [
  {
    label: "All",
    value: "---",
    filterName: "language"
  },
  ...languageOptions
]

const dateRangeOptions = [
  {
    label: "Last 6 months",
    value: 6,
    filterName: "dateRange"
  },
  {
    label: "Last 1 Year",
    value: 12,
    filterName: "dateRange"
  }
]

const dateRangeAllOptions = [
  {
    label: "All time",
    value: "---",
    filterName: "dateRange"
  },
  ...dateRangeOptions
]

const publishedAtOptions = [
  {
    label: "All time",
    value: null,
    filterName: "publishedAt"
  },
  {
    label: "Last 1 Year",
    value: Date.now() + -365 * 24 * 3600 * 1000,
    filterName: "publishedAt"
  },
  {
    label: "Last 6 months",
    value: Date.now() + -180 * 24 * 3600 * 1000,
    filterName: "publishedAt"
  }
]

const detectedLanguageOptions = [
  {
    label: "Portuguese",
    value: "por",
    filterName: "detectedLanguage"
  },
  {
    label: "English",
    value: "eng",
    filterName: "detectedLanguage"
  },
  {
    label: "Spanish",
    value: "spa",
    filterName: "detectedLanguage"
  },
  {
    label: "French",
    value: "fra",
    filterName: "detectedLanguage"
  },
  {
    label: "Hindi",
    value: "hin",
    filterName: "detectedLanguage"
  },
  {
    label: "Other",
    value: null,
    filterName: "detectedLanguage"
  }
]

const comparing = [
  {
    label: "Topics",
    value: "topics",
    filterName: "comparing"
  },
  {
    label: "Creators",
    value: "creators",
    filterName: "comparing"
  },
  {
    label: "Brands",
    value: "brands",
    filterName: "comparing"
  }
]

const Filters = {
  comparing: {
    name: "comparing",
    label: "Comparing",
    type: "option",
    default: comparing[0],
    options: comparing,
    selected: comparing[0]
  },
  comparingTopics: {
    name: "comparingTopics",
    label: "",
    type: "dynamic/multiples",
    alias: "topics",
    default: [],
    options: [],
    selecteds: [],
    filterDependency: "language",
    loading: true,
    load: dependency => {
      const { value } = dependency

      if (value) {
        return Actions.allTopics({
          variables: { language: value },
          fields: `
          title
          topicId
                    `
        })
      }

      return Promise.resolve()
    },
    buildOptions: allTopics => {
      if (allTopics?.entities?.length) {
        const options = allTopics.entities.map(topicGroup => {
          const { title, topicId } = topicGroup
          return {
            label: title,
            value: topicId,
            filterName: "topics"
          }
        })
        return [defaultsValues.topic, ...options]
      }

      return [
        {
          label: "Select a language please",
          value: "---"
        }
      ]
    }
  },
  comparingCreators: {
    name: "comparingCreators",
    label: "",
    type: "dynamic/multiples",
    default: [],
    options: [],
    selecteds: [],
    loading: true,
    loadOptionsFromQueryString: async (filterKey, value) => {
      const filterValues = Array.isArray(value) ? value : [value]
      const creatorsInfo = filterValues.map(splitProviderFromId)
      const creator = await Actions.creators({
        variables: { creatorsInfo },
        fields: `
          title
          creatorId
          provider
        `
      })
      return creator
    },
    buildOptions: creators => {
      if (creators && creators.length) {
        return creators.map(creator => {
          const { title, name, provider, creatorId } = creator
          const label = title || name
          const value = `${provider}:${creatorId}`
          return {
            provider,
            label,
            value
          }
        })
      }
    }
  },
  comparingBrands: {
    name: "comparingBrands",
    label: "",
    type: "dynamic/multiples",
    default: [],
    options: [],
    selecteds: [],
    loading: true,
    loadOptionsFromQueryString: async (filterKey, value) => {
      const filterValues = Array.isArray(value) ? value : [value]
      const creatorsInfo = filterValues.map(splitProviderFromId)
      const creator = await Actions.creators({
        variables: { creatorsInfo },
        fields: `
          title
          creatorId
          provider
        `
      })
      return creator
    },
    buildOptions: creators => {
      if (creators && creators.length) {
        return creators.map(creator => {
          const { title, name, provider, creatorId } = creator
          const label = title || name
          const value = `${provider}:${creatorId}`
          return {
            provider,
            label,
            value
          }
        })
      }
    }
  },
  ages: {
    name: "ages",
    label: "Ages",
    type: "multiples",
    default: [agesOptions[0]],
    options: agesOptions,
    selecteds: [agesOptions[0]]
  },
  countries: {
    name: "countries",
    label: "Countries",
    type: "multiples",
    default: [countries[0]],
    options: countries,
    selecteds: [countries[0]]
  },
  genders: {
    name: "genders",
    label: "Gender",
    type: "multiples",
    default: gendersOptions,
    options: gendersOptions,
    selecteds: gendersOptions
  },
  title: {
    name: "title",
    label: "Title",
    type: "text",
    default: "",
    value: ""
  },
  sortBy: {
    name: "sortBy",
    label: "Sort by",
    type: "option",
    default: searchSortByOptions[0],
    options: searchSortByOptions,
    selected: searchSortByOptions[0]
  },
  allLanguage: {
    name: "language",
    label: "Language",
    type: "option",
    default: allLanguageOptions[0],
    options: allLanguageOptions,
    selected: allLanguageOptions[0],
    filterDependent: ["topicCategories", "topicGroup"]
  },
  language: {
    name: "language",
    label: "Language",
    type: "option",
    default: languageOptions[0],
    options: languageOptions,
    selected: languageOptions[0],
    filterDependent: ["topicCategories", "topicGroup"]
  },
  providers: {
    name: "providers",
    label: "Providers",
    type: "multiples",
    default: providersOptions,
    options: providersOptions,
    selecteds: providersOptions
  },
  dateRange: {
    name: "dateRange",
    label: "Date Range",
    type: "option",
    default: dateRangeAllOptions[0],
    options: dateRangeAllOptions,
    selected: dateRangeAllOptions[0]
  },
  dateRangeSimple: {
    name: "dateRange",
    label: "Date Range",
    type: "option",
    default: dateRangeOptions[1],
    options: dateRangeOptions,
    selected: dateRangeOptions[1]
  },
  publishedAt: {
    name: "publishedAt",
    label: "Date Range",
    type: "option",
    default: publishedAtOptions[0],
    options: publishedAtOptions,
    selected: publishedAtOptions[0]
  },
  detectedLanguage: {
    name: "detectedLanguage",
    label: "Choose a language",
    type: "option",
    default: null,
    selected: [],
    options: detectedLanguageOptions
  },
  topicCategories: {
    name: "topicCategories",
    label: "Topic Category",
    type: "dynamic/option",
    alias: "category",
    default: defaultsValues.topicCategories,
    selected: defaultsValues.topicCategories,
    options: [defaultsValues.topicCategories],
    filterDependency: "language",
    filterDependent: ["topic"],
    loading: true,
    load: dependency => {
      const { value } = dependency

      if (value) {
        return Actions.allTopicGroups({
          variables: { language: value },
          fields: `
                      title
                      topicGroupId
                    `
        })
      }

      return Promise.resolve()
    },
    buildOptions: allTopicGroups => {
      if (allTopicGroups && allTopicGroups.length) {
        const options = allTopicGroups.map(topicGroup => {
          const { title, topicGroupId } = topicGroup
          return {
            label: title,
            value: topicGroupId,
            filterName: "topicCategories"
          }
        })
        return [defaultsValues.topicCategories, ...options]
      }

      return [
        {
          label: "Select a language please",
          value: "---"
        }
      ]
    }
  },
  topic: {
    name: "topic",
    label: "Topic",
    type: "dynamic/option",
    default: defaultsValues.topic,
    selected: defaultsValues.topic,
    options: [defaultsValues.topic],
    filterDependency: "topicCategories",
    loading: true,
    load: (dependency, resetCache) => {
      const { value } = dependency

      if (value) {
        return Actions.topicByGroup({
          variables: { groupIds: [value] },
          fields: `
                      topicId
                      title
                    `,
          ...(resetCache && { fetchPolicy: "no-cache" })
        })
      }

      return Promise.resolve()
    },
    buildOptions: topicByGroup => {
      if (topicByGroup && topicByGroup.length) {
        return topicByGroup.map(topicGroup => {
          const { title, topicId } = topicGroup
          return {
            label: title,
            value: topicId,
            filterName: "topic"
          }
        })
      }

      return [
        {
          label: "Topic Category",
          value: "--"
        }
      ]
    }
  },
  topicGroup: {
    name: "topicGroup",
    label: "Topic Group",
    type: "dynamic/multiples",
    default: null,
    selecteds: [],
    options: [],
    filterDependency: "language",
    loading: true,
    load: (dependency, resetCache) => {
      const { value } = dependency

      if (value) {
        return Actions.allTopicGroups({
          variables: { language: value },
          fields: `
                      title
                      topicGroupId
                      topics{
                        topicId
                        title
                      }
                    `,
          ...(resetCache && { fetchPolicy: "no-cache" })
        })
      }

      return Promise.resolve()
    },
    buildOptions: allTopicGroups => {
      if (allTopicGroups && allTopicGroups.length) {
        return allTopicGroups.map(topicGroup => {
          const { title, topicGroupId, topics } = topicGroup
          return {
            label: title,
            value: topicGroupId,
            filterName: "topicGroup",
            options: topics.map(t => ({ label: t.title, value: t.topicId }))
          }
        })
      }

      return [
        {
          label: "Select a language first",
          value: "---"
        }
      ]
    }
  },
  formatGroups: {
    name: "formatGroups",
    label: "Format Group",
    type: "dynamic/multiples",
    default: null,
    selecteds: [],
    options: [],
    loading: true,
    load: (_, resetCache) =>
      Actions.formatGroups({
        fields: `
            groupId
            name
            formats{
              formatId
              name
            }
                  `,
        ...(resetCache && { fetchPolicy: "no-cache" })
      }),
    buildOptions: formatGroups => {
      if (formatGroups && formatGroups.length) {
        return formatGroups.map(group => {
          const { groupId, name, formats } = group
          return {
            label: name,
            value: groupId,
            filterName: "formatGroups",
            options: formats.map(t => ({ label: t.name, value: t.formatId }))
          }
        })
      }

      return [
        {
          label: "Something goes wrong",
          value: "---"
        }
      ]
    }
  }
}
const FilterGroups = {
  searchPostTagger: new FilterHandler({
    sortBy: Filters.sortBy,
    title: Filters.title,
    language: Filters.language,
    providers: Filters.providers,
    topicCategories: Filters.topicCategories,
    topic: Filters.topic
  }),
  searchPost: new FilterHandler({
    sortBy: Filters.sortBy,
    title: Filters.title,
    language: Filters.allLanguage,
    providers: Filters.providers,
    topicCategories: Filters.topicCategories,
    topic: Filters.topic,
    publishedAt: Filters.publishedAt
  }),
  taggingPost: new FilterHandler({
    language: Filters.language,
    topicGroup: Filters.topicGroup,
    taggerProjects: Filters.taggerProjects,
    formatGroups: Filters.formatGroups
  }),
  audience: new FilterHandler({
    language: Filters.language,
    genders: Filters.genders,
    countries: Filters.countries,
    topicCategories: Filters.topicCategories,
    dateRange: Filters.dateRangeSimple,
    ages: Filters.ages
  }),
  comparisonPage: new FilterHandler({
    language: Filters.language,
    genders: Filters.genders,
    countries: Filters.countries,
    dateRange: Filters.dateRangeSimple,
    ages: Filters.ages,
    comparing: Filters.comparing,
    comparingTopics: Filters.comparingTopics,
    comparingBrands: Filters.comparingBrands,
    comparingCreators: Filters.comparingCreators
  })
}

export default {
  FilterGroups,
  Filters
}
