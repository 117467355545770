import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icon } from "../../shared/shared.ui.icon";
import { Tooltip } from "../../shared/shared.ui.tooltip";
import "./index.scss";

const sizes = {
  xlg: "h1",
  lg: "h2",
  md: "h3",
  sm: "h4",
  xs: "h5",
  xxs: "h6",
};

export const Header = ({
  variation,
  as,
  size,
  children,
  className,
  weight,
  icon,
  iconWidth,
  iconHeight,
  iconFill,
  tooltipContent,
  tooltipTargetId,
  ...props
}) => {
  const classes = classNames(
    "Insights-ui__header",
    as ? sizes[size] : "",
    variation !== "regular" ? `variation-${variation}` : "",
    weight,
    icon ? "with-icon" : "",
    className
  );
  const Component = as || sizes[size] || "h1";
  return (
    <Component {...props} className={classes}>
      {children}
      {icon && (
        <Tooltip
          place="right"
          TooltipContent={tooltipContent}
          targetId={tooltipTargetId}
          containerClassName="tooltip-inline"
        >
          <Icon
            icon={icon}
            width={iconWidth}
            height={iconHeight}
            fill={iconFill}
          />
        </Tooltip>
      )}
    </Component>
  );
};

Header.propTypes = {
  /** Header size */
  size: PropTypes.oneOf(Object.keys(sizes)),
  /** Allows header component to be rendered as another DOM element */
  as: PropTypes.elementType,
  /** A icon to be displayed at the right of header text */
  icon: PropTypes.string,
  /** Icon width */
  iconWidth: PropTypes.number,
  /** Icon height */
  iconHeight: PropTypes.number,
  /** Icon fill */
  iconFill: PropTypes.string,
  /** A text who is displayed as tooltip when hover the icon, requeired if icon is used */
  tooltipContent: PropTypes.string,
  /** Used for target tooltip, requeired if icon is used */
  tooltipTargetId: PropTypes.string,
  /** Header font weight  */
  weight: PropTypes.oneOf(["regular", "bold", "light", "semi"]),
  /** Header variation */
  variation: PropTypes.oneOf(["regular", "primary"]),
  /** Children  */
  children: PropTypes.node,
  /** Class name */
  className: PropTypes.string,
};

Header.defaultProps = {
  size: "xlg",
  as: "",
  icon: "",
  iconWidth: null,
  iconHeight: null,
  iconFill: "",
  tooltipContent: "",
  tooltipTargetId: "",
  children: null,
  className: "",
  variation: "regular",
  weight: "bold",
};
