import React, { Fragment } from "react";

export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.22747C6.60278 2.22747 2.22747 6.60278 2.22747 12C2.22747 17.3972 6.60278 21.7725 12 21.7725C17.3972 21.7725 21.7725 17.3972 21.7725 12C21.7725 6.60278 17.3972 2.22747 12 2.22747ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.899902 12C0.899902 5.86964 5.86954 0.900002 11.9999 0.900002C18.1303 0.900002 23.0999 5.86964 23.0999 12C23.0999 18.1304 18.1303 23.1 11.9999 23.1C5.86954 23.1 0.899902 18.1304 0.899902 12ZM11.9999 1.1C5.98 1.1 1.0999 5.9801 1.0999 12C1.0999 18.0199 5.98 22.9 11.9999 22.9C18.0198 22.9 22.8999 18.0199 22.8999 12C22.8999 5.9801 18.0198 1.1 11.9999 1.1ZM11.9999 2.32747C6.65791 2.32747 2.32737 6.65801 2.32737 12C2.32737 17.342 6.65791 21.6725 11.9999 21.6725C17.3419 21.6725 21.6724 17.342 21.6724 12C21.6724 6.65801 17.3419 2.32747 11.9999 2.32747ZM2.12737 12C2.12737 6.54755 6.54745 2.12747 11.9999 2.12747C17.4524 2.12747 21.8724 6.54755 21.8724 12C21.8724 17.4525 17.4524 21.8725 11.9999 21.8725C6.54745 21.8725 2.12737 17.4525 2.12737 12Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9992 6.12232C10.5488 6.12232 9.37298 7.29811 9.37298 8.74852C9.37298 10.1989 10.5488 11.3747 11.9992 11.3747C13.4496 11.3747 14.6254 10.1989 14.6254 8.74852C14.6254 7.29811 13.4496 6.12232 11.9992 6.12232ZM8.14551 8.74852C8.14551 6.6202 9.87085 4.89485 11.9992 4.89485C14.1275 4.89485 15.8528 6.6202 15.8528 8.74852C15.8528 10.8768 14.1275 12.6022 11.9992 12.6022C9.87085 12.6022 8.14551 10.8768 8.14551 8.74852Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.04541 8.74852C8.04541 6.56497 9.81553 4.79485 11.9991 4.79485C14.1826 4.79485 15.9527 6.56497 15.9527 8.74852C15.9527 10.9321 14.1826 12.7022 11.9991 12.7022C9.81553 12.7022 8.04541 10.9321 8.04541 8.74852ZM11.9991 4.99485C9.92598 4.99485 8.24541 6.67543 8.24541 8.74852C8.24541 10.8216 9.92598 12.5022 11.9991 12.5022C14.0722 12.5022 15.7527 10.8216 15.7527 8.74852C15.7527 6.67543 14.0722 4.99485 11.9991 4.99485ZM11.9991 6.22232C10.6039 6.22232 9.47288 7.35334 9.47288 8.74852C9.47288 10.1437 10.6039 11.2747 11.9991 11.2747C13.3943 11.2747 14.5253 10.1437 14.5253 8.74852C14.5253 7.35334 13.3943 6.22232 11.9991 6.22232ZM9.27288 8.74852C9.27288 7.24288 10.4934 6.02232 11.9991 6.02232C13.5047 6.02232 14.7253 7.24288 14.7253 8.74852C14.7253 10.2542 13.5047 11.4747 11.9991 11.4747C10.4934 11.4747 9.27288 10.2542 9.27288 8.74852Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9998 15.0537C8.89026 15.0537 6.21625 16.6592 4.71745 19.1215L3.66895 18.4833C5.38461 15.6647 8.45071 13.8262 11.9998 13.8262C15.5515 13.8262 18.7399 15.7832 20.3406 18.6153L19.272 19.2192C17.8879 16.7704 15.1066 15.0537 11.9998 15.0537Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.58373 18.4313C5.31706 15.5837 8.4151 13.7262 12 13.7262C15.5879 13.7262 18.8095 15.7028 20.4279 18.566L20.4771 18.6531L19.2344 19.3555L19.1852 19.2684C17.8187 16.8508 15.0706 15.1537 12 15.1537C8.92627 15.1537 6.2842 16.7403 4.80307 19.1735L4.75108 19.259L3.53174 18.5168L3.58373 18.4313ZM3.80701 18.4502L4.68472 18.9844C6.21065 16.5413 8.89063 14.9537 12 14.9537C15.1063 14.9537 17.8916 16.6496 19.3095 19.0833L20.204 18.5777C18.6052 15.8237 15.479 13.9262 12 13.9262C8.52261 13.9262 5.51459 15.7088 3.80701 18.4502Z"
    />
  </>
);
