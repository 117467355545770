import React, { Fragment } from "react";

export default (
  <>
    <path
      id="pencil-icon"
      d="M3.75 18H0v-3.75L11.06 3.192l3.75 3.75zM15.88 5.872l-3.75-3.75 1.83-1.83a1 1 0 0 1 1.41 0l2.34 2.34a1 1 0 0 1 0 1.41l-1.83 1.83z"
      transform="translate(4 3.997)"
    />
  </>
);
