import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Clamp from "react-dotdotdot"
import { Card } from "../../../shared/shared.ui.card"
import { Tooltip } from "../../../shared/shared.ui.tooltip"
import { Icon } from "../../../shared/shared.ui.icon"
import { Link } from "../../../shared/shared.ui.link"

import Utils from "../../utils"
import Constants from "../../constants"
import "./index.scss"

const { LinkTo } = Utils
const { Number } = Utils.Format

const PostCard = props => {
  const { className, post, onClickRemoveVideo, onClick } = props

  const {
    postId,
    creatorId,
    creatorTitle,
    provider,
    thumbnail,
    title,
    views,
    engagement
  } = post

  const providerName = Constants.Providers[provider].NAME
  const classes = classNames("Insights-ui__postcard", className)

  return (
    <Card className={classes} onClick={onClick}>
      <div className="image" style={{ backgroundImage: `url(${thumbnail})` }}>
        {onClickRemoveVideo && (
          <Tooltip
            onClick={null}
            targetId={`remove-${postId}`}
            place="left"
            TooltipContent={<span>Remove from topic</span>}
            containerClassName="remove-video"
          >
            <Icon icon="close" />
          </Tooltip>
        )}
      </div>
      <div className="card-body">
        <div className="title">
          <Tooltip
            delayShow={500}
            targetId={`title-${postId}`}
            TooltipContent={title}
            title={title}
            className="title-tooltip"
          >
            <Clamp clamp={3}>{title}</Clamp>
          </Tooltip>
        </div>
        {post.creator && post.creator.groupId && creatorId && (
          <div className="video-card-creator">
            <span>by </span>
            <Link
              href={LinkTo.Creator({
                groupId: post.creator.groupId,
                creatorId,
                provider
              })}
            >
              {creatorTitle}
            </Link>
          </div>
        )}
      </div>
      <div className="card-footer">
        <div className={`provider ${providerName.toLowerCase()}`}>
          <Icon icon={providerName.toLowerCase()} className="icon" />
        </div>
        <div className="video-statistics">
          <Tooltip
            className="tooltip"
            targetId={`engagement-tooltip-${postId}`}
            TooltipContent="Total Engagement"
            place="top"
            type="dark"
            effect="solid"
          >
            <div className="video-card-detail">
              <Icon fill="#868A90" icon="engagementNew" />
              <span>{Number.Format(engagement, 1)}</span>
            </div>
          </Tooltip>
          <Tooltip
            className="tooltip"
            targetId={`views-tooltip-${postId}`}
            place="top"
            TooltipContent="Total Views"
            type="dark"
            effect="solid"
          >
            <div className="video-card-detail">
              <Icon fill="#868A90" icon="viewNew" />
              <span>{Number.Format(views, 1)}</span>
            </div>
          </Tooltip>
        </div>
      </div>
    </Card>
  )
}

PostCard.propTypes = {
  className: PropTypes.string,
  post: PropTypes.object,
  onClickRemoveVideo: PropTypes.func
}

PostCard.defaultProps = {
  onClickRemoveVideo: null,
  post: {
    postId: "default",
    thumbnail: "https://via.placeholder.com/311/174",
    title: "Post default title default",
    views: 123000,
    engagement: 199000
  },
  className: ""
}

export default PostCard
