import { useState, useEffect } from "react"

const usePagination = function({ limit }) {
  const [offset, setOffset] = useState(0)
  const [page, setPage] = useState(0)

  useEffect(() => {
    const currentOffset = page * limit
    setOffset(currentOffset)
  }, [page])

  return {
    offset,
    page,
    setPage
  }
}

export default usePagination
