export default [
  {
    label: "None",
    value: "none"
  },
  {
    label: "Portuguese",
    value: "por"
  },
  {
    label: "English",
    value: "eng"
  },
  {
    label: "Spanish",
    value: "spa"
  },
  {
    label: "Global",
    value: "all"
  }
]
