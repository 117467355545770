import gql from "graphql-tag"

export const VERIFY_POST = gql`
  mutation(
    $postId: String!
    $provider: String!
    $topicId: Int!
    $approved: Boolean!
  ) {
    verifyPost(
      postId: $postId
      provider: $provider
      topicId: $topicId
      approved: $approved
    )
  }
`
