import React, { Fragment } from "react";

export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 1H16C14.8954 1 14 1.89543 14 3V8C14 9.10457 14.8954 10 16 10H21C22.1046 10 23 9.10457 23 8V3C23 1.89543 22.1046 1 21 1ZM16 0C14.3431 0 13 1.34315 13 3V8C13 9.65685 14.3431 11 16 11H21C22.6569 11 24 9.65685 24 8V3C24 1.34315 22.6569 0 21 0H16Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 13.9998H16C14.8954 13.9998 14 14.8952 14 15.9998V20.9998C14 22.1043 14.8954 22.9998 16 22.9998H21C22.1046 22.9998 23 22.1043 23 20.9998V15.9998C23 14.8952 22.1046 13.9998 21 13.9998ZM16 12.9998C14.3431 12.9998 13 14.3429 13 15.9998V20.9998C13 22.6566 14.3431 23.9998 16 23.9998H21C22.6569 23.9998 24 22.6566 24 20.9998V15.9998C24 14.3429 22.6569 12.9998 21 12.9998H16Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1H3C1.89543 1 1 1.89543 1 3V8C1 9.10457 1.89543 10 3 10H8C9.10457 10 10 9.10457 10 8V3C10 1.89543 9.10457 1 8 1ZM3 0C1.34315 0 0 1.34315 0 3V8C0 9.65685 1.34315 11 3 11H8C9.65685 11 11 9.65685 11 8V3C11 1.34315 9.65685 0 8 0H3Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 13.9998H3C1.89543 13.9998 1 14.8952 1 15.9998V20.9998C1 22.1043 1.89543 22.9998 3 22.9998H8C9.10457 22.9998 10 22.1043 10 20.9998V15.9998C10 14.8952 9.10457 13.9998 8 13.9998ZM3 12.9998C1.34315 12.9998 0 14.3429 0 15.9998V20.9998C0 22.6566 1.34315 23.9998 3 23.9998H8C9.65685 23.9998 11 22.6566 11 20.9998V15.9998C11 14.3429 9.65685 12.9998 8 12.9998H3Z"
    />
  </>
);
