import gql from "graphql-tag"

export const SUBTOPICS_QUERY = gql`
  query listSubtopics(
    $query: SearchSubtopicsInput
    $topicIds: [Int]
    $language: String
    $timestamp: Int
    $offset: Int
    $limit: Int
    $sort: SearchSort
  ) {
    listSubtopics(
      query: $query
      topicIds: $topicIds
      language: $language
      timestamp: $timestamp
      offset: $offset
      limit: $limit
      sort: $sort
    ) {
      entities {
        title
        subtopicId
        term

        topic {
          title
          topicId

          category {
            title
            topicGroupId
          }
        }
      }

      rows
    }
  }
`

export const CATEGORIES_QUERY = gql`
  query allTopicGroups($language: String!) {
    allTopicGroups(language: $language) {
      topicGroupId
      title
      topics {
        topicId
      }
    }
  }
`

export const TOPICS_QUERY = gql`
  query allTopics($language: String!) {
    allTopics(language: $language) {
      entities {
        title
        topicId
      }
    }
  }
`

export const TOPICS_BY_CATEGORY_QUERY = gql`
  query topicByGroup($groupIds: [Int!]!) {
    topicByGroup(groupIds: $groupIds) {
      topicId
      title
    }
  }
`
