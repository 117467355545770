const typesConfigs = {
  text: "value",
  option: "selected",
  multiples: "selecteds",
  "dynamic/option": "selected",
  "dynamic/multiples": "selecteds"
}

class FilterHanddler {
  constructor(filters) {
    this.filters = filters
    this.promisesToWait = []

    this.onChange = null
    this.storedState = null
  }

  setOnload(callback) {
    this.onLoad = callback
    this.loadDynamicsFilters()
  }

  subscribe(listener) {
    this.onChange = listener
  }

  clearAll() {
    this.forEach(filter => {
      filter.change(filter.default, false)
    })

    this.updateCompoent()
  }

  saveState() {
    this.storedState = this.toVariables(false, "value", false)
  }

  backTolastStoredState() {
    if (this.storedState) {
      const filtersKeys = Object.keys(this.storedState)

      this.forEach(filter => {
        // se não existir na lista então retorna o valor inicial
        if (filtersKeys.indexOf(filter.name) === -1) {
          this.initializeValue(undefined, filter.name)
        }
      })

      filtersKeys.forEach(filterKey => {
        const storedValue = this.storedState[filterKey]

        if (
          typeof storedValue === "string" ||
          typeof storedValue === "number"
        ) {
          this.initializeValue(storedValue, filterKey)
        }

        if (Array.isArray(storedValue)) {
          if (storedValue.length) {
            this.initializeValue(storedValue, filterKey)
          } else {
            this.initializeValue(undefined, filterKey)
          }
        }
      })
      this.updateCompoent()
    }
  }

  setComponent(comp, changeFunc) {
    this.updateCompoent = comp[changeFunc]

    const keys = Object.keys(this.filters)

    keys.forEach(filterKey => {
      const filter = this.filters[filterKey]

      filter.listeners = []

      filter.change = (newValue, update = true, callback) => {
        this.setValue(filterKey, newValue)
        if (update) {
          comp[changeFunc](() => {
            filter.listeners.forEach(l => l())
            if (callback) callback()
          })
        }
      }

      filter.transformOptions = transform => {
        if (transform) {
          filter.options = transform(filter.options)
          comp[changeFunc]()
        }
      }

      filter.addListener = fn => {
        if (fn) {
          filter.listeners.push(fn)
        }
        comp[changeFunc]()
      }

      filter.onLoad = () => {
        filter.listeners.forEach(l => l())
      }
    })
  }

  loadDynamicsFilters() {
    this.forEach(this.lookingForDynamicFilters)

    if (this.promisesToWait.length) {
      Promise.all(this.promisesToWait).then(() => {
        if (this.onLoad) {
          this.onLoad()
        }
      })
    } else {
      this.onLoad()
    }
  }

  checkIfIsDinamic(filter) {
    const type = filter.type.split("/")[0]
    if (type && type === "dynamic") {
      return true
    }
    return false
  }

  lookingForDynamicFilters = filter => {
    if (this.checkIfIsDinamic(filter)) {
      // reload
      if (filter.reaload)
        filter.reaload = () => this.loadIndividualFilter(filter, null, true)

      if (filter.filterDependency) {
        const dependency = this.getFilterByKey(filter.filterDependency)

        if (this.checkIfIsDinamic(dependency)) {
          if (!dependency.loading) {
            this.loadDependency(filter, dependency.name)
          }
        } else {
          this.loadDependency(filter, dependency.name)
        }
      } else {
        this.promisesToWait.push(this.loadIndividualFilter(filter))
      }
    }
  }

  loadDependency(filter, dependencyName) {
    const dependencyOption = this.getValue(dependencyName)

    this.promisesToWait.push(
      this.loadIndividualFilter(filter, dependencyOption)
    )
  }

  loadIndividualFilter(filter, dependencyOption = null, reload = false) {
    filter.loading = true
    return new Promise((resolve, reject) => {
      if (filter.load) {
        filter
          .load(dependencyOption, reload)
          .then(res => {
            filter.options = filter.buildOptions(res)
            filter.loading = false
            filter.onLoad()

            if (reload) {
              this.updateCompoent()
            }

            resolve()
          })
          .catch(err => {
            console.warn(err)
            filter.loading = false
            reject()
          })
      } else {
        filter.loading = false
        resolve()
      }
    })
  }

  toState() {
    return this.filters
  }

  getFilterByKey(filterKey) {
    if (this.filters[filterKey]) {
      return this.filters[filterKey]
    }
    return null
  }

  forEach(interator) {
    const keys = Object.keys(this.filters)
    keys.forEach(filterKey => {
      interator(this.filters[filterKey], filterKey)
    })
  }

  findOptionByValue(filterKey, value) {
    if (!value) {
      return this.getFilterByKey(filterKey).default
    }

    const option = this.getFilterByKey(filterKey).options.filter(
      f => f.value.toString() === value.toString()
    )[0]
    if (option) {
      return option
    }
    console.error(
      `No option to ${filterKey} => ${value}, to prevent error we will use defalt value `
    )
    return this.getFilterByKey(filterKey).default
  }

  async findMultipleOptions(filterKey, value) {
    const filter = this.getFilterByKey(filterKey)

    if (filter.loadOptionsFromQueryString) {
      const _promise = filter.loadOptionsFromQueryString(filterKey, value)
      this.promisesToWait.push(_promise)
      const result = await _promise
      const values = result || []
      const opts = filter.buildOptions(values)
      filter.options = opts
      return opts
    }

    if (value && value.length && Array.isArray(value)) {
      return value.reduce((acc, queryStringValue) => {
        const result = filter.options.filter(
          opts => opts.value.toString() === queryStringValue.toString()
        )[0]
        if (result) {
          acc.push(result)
        }
        return acc
      }, [])
    }

    // se não for um array
    if (value) {
      return [this.findOptionByValue(filterKey, value)]
    }

    return filter.default
  }

  getValue(filterKey) {
    const filter = this.getFilterByKey(filterKey)
    const valueKey = typesConfigs[filter.type]
    return filter[valueKey]
  }

  selectedsToVariables(currentFilter, filterAttr) {
    return currentFilter.selecteds.reduce((_acc, selected) => {
      if (selected.value) {
        if (filterAttr === "value") {
          // value default always is '---' we dont need that on gql
          if (selected.value !== "---") {
            _acc.push(selected[filterAttr])
          }
        } else {
          _acc.push(selected[filterAttr])
        }
      }

      if (selected.selecteds) {
        _acc.push(selected.selecteds.map(s => s[filterAttr]))
      }

      return _acc
    }, [])
  }

  alias(acc, filter, value, filterKey, withAlias) {
    if (withAlias) {
      if (filter.alias) {
        acc[filter.alias] = value
      } else {
        acc[filterKey] = value
      }
    } else {
      acc[filterKey] = value
    }
  }

  // antes de mecher nisso, tenha muito medo 👻☠️, se tiver dúvida chame o king que ele explica 😛
  toVariables(
    excludeDefaults = false,
    filterAttr = "value",
    withAlias = false
  ) {
    const keys = Object.keys(this.filters)

    return keys.reduce((acc, filterKey) => {
      const currentFilter = this.filters[filterKey]

      if (currentFilter.value) {
        if (!excludeDefaults) {
          // ver mais para frente como resolver essa questão de código repetido
          // acc[filterKey] = currentFilter.value
          this.alias(
            acc,
            currentFilter,
            currentFilter.value,
            filterKey,
            withAlias
          )
        } else if (currentFilter.value !== currentFilter.default) {
          // acc[filterKey] = currentFilter[filterAttr]
          this.alias(
            acc,
            currentFilter,
            currentFilter[filterAttr],
            filterKey,
            withAlias
          )
        }
      }

      if (
        (currentFilter.selected && currentFilter.selected.value === "---") ||
        (currentFilter.selecteds && currentFilter.selecteds.value === "---")
      ) {
        return acc
      }

      if (currentFilter.selected && currentFilter.selected.value) {
        if (!excludeDefaults) {
          // ver mais para frente como resolver essa questão de código repetido
          // acc[filterKey] = currentFilter.selected[filterAttr]
          this.alias(
            acc,
            currentFilter,
            currentFilter.selected[filterAttr],
            filterKey,
            withAlias
          )
        } else if (
          currentFilter.selected.value &&
          currentFilter.selected.value !== currentFilter.default.value
        ) {
          // acc[filterKey] = currentFilter.selected[filterAttr]
          this.alias(
            acc,
            currentFilter,
            currentFilter.selected[filterAttr],
            filterKey,
            withAlias
          )
        }
      }

      if (currentFilter.selecteds) {
        if (!excludeDefaults) {
          // acc[filterKey] = this.selectedsToVariables(currentFilter, filterAttr)
          this.alias(
            acc,
            currentFilter,
            this.selectedsToVariables(currentFilter, filterAttr),
            filterKey,
            withAlias
          )
        } else {
          const selecteds = JSON.stringify(currentFilter.selecteds)
          const defaults = JSON.stringify(currentFilter.default)

          if (selecteds !== defaults) {
            // ver mais para frente como resolver essa questão de código repetido
            /* acc[filterKey] = this.selectedsToVariables(
              currentFilter,
              filterAttr,
            ) */

            this.alias(
              acc,
              currentFilter,
              this.selectedsToVariables(currentFilter, filterAttr),
              filterKey,
              withAlias
            )
          } else {
            // acc[filterKey] = []
            this.alias(acc, currentFilter, [], filterKey, withAlias)
          }
        }
      }

      return acc
    }, {})
  }

  setValue(filterKey, value) {
    const filter = this.getFilterByKey(filterKey)
    const valueKey = typesConfigs[filter.type]

    filter[valueKey] = value

    if (filter.filterDependent) {
      filter.filterDependent.forEach(filterDepName => {
        const filterDependent = this.getFilterByKey(filterDepName)

        // dependent filter not in group
        if (!filterDependent) {
          return
        }

        // if value is default wont load dependent filter
        // just switch that value to default or not set
        if ((value && value.value && value.value === "---") || !value) {
          filterDependent.change(filterDependent.default)
          if (filterDependent.options) {
            filterDependent.options = []
          }
        } else {
          this.loadIndividualFilter(filterDependent, value, () => {
            filterDependent.change(filterDependent.default)
          })
        }
      })
    }

    if (this.onChange) this.onChange(filter)

    return null
  }

  // simplificar depois
  async initializeValue(valueFromQueryString, filterKey) {
    const filter = this.getFilterByKey(filterKey)

    switch (filter.type) {
      case "text":
        filter.value =
          valueFromQueryString !== filter.default
            ? filter.change(valueFromQueryString, false)
            : filter.change(filter.default, false)
        break

      case "option":
        filter.change(
          this.findOptionByValue(filterKey, valueFromQueryString),
          false
        )
        break

      case "dynamic/option":
        filter.change(
          this.findOptionByValue(filterKey, valueFromQueryString),
          false
        )
        break

      case "multiples":
        const option = await this.findMultipleOptions(
          filterKey,
          valueFromQueryString
        )
        filter.change(option, false)
        break

      case "dynamic/multiples":
        const _option = await this.findMultipleOptions(
          filterKey,
          valueFromQueryString
        )
        filter.change(_option, false)
        break

      default:
        break
    }
  }

  valueOf(filter) {
    switch (filter.type) {
      case "text":
        return filter.value

      case "option":
        return filter.selected

      case "dynamic/option":
        return filter.selected

      case "multiples":
        return filter.selecteds

      case "dynamic/multiples":
        return filter.selecteds

      default:
        return filter.selected
    }
  }
}

export default FilterHanddler
