export default {
  creatorCard: `
    `,
  postCard: `
        postId
        title
        provider
        description
        creatorId
        creatorTitle
        views
        likes
        thumbnail
        publishedAt
        postType
        postUrl
        categoryId
        comments
        durationInSeconds
        videoUrl
        engagement
        creator {
          ... on Creator {
            groupId
          }
        }
    `,
  login: `
        __typename
        ... on LoginApprovalException {
        msg
        }

        ... on LoginApproval {
        token
        user {
            id
            name
            thumbnail
            role
            email
            onboarding
            company {
              contractBegin
              contractEnd
            }
        }
        expire
        }

        ... on LoginFull {
        users {
            name
            email
        }
        }
    `
}
