import gql from "graphql-tag"

export const POSTS_QUERY = gql`
  query searchPosts(
    $query: SearchPostsInput!
    $lastValue: String
    $lastPostId: String
  ) {
    searchPosts(
      query: $query
      lastValue: $lastValue
      lastPostId: $lastPostId
      sort: { field: "views", order: "desc" }
      offset: 0
      limit: 36
    ) {
      entities {
        ... on Post {
          postUrl
          postId
          thumbnail
          title
          creatorTitle
          topics {
            title
          }
          publishedAt
          provider
          engagement
          views
          likes
        }
      }

      rows
    }
  }
`
