import React, { Fragment } from "react";

export default (
  <>
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#029C96"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7203 7.30666C20.1031 7.70471 20.0908 8.33776 19.6927 8.7206L11.1188 16.967C10.7303 17.3406 10.1156 17.3392 9.72891 16.9637L4.84192 12.218C4.44571 11.8333 4.43642 11.2002 4.82117 10.804C5.20592 10.4078 5.83902 10.3985 6.23523 10.7832L10.4289 14.8556L18.3063 7.27912C18.7044 6.89628 19.3374 6.9086 19.7203 7.30666Z"
      fill="white"
    />
  </>
);
