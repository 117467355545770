import gql from "graphql-tag"

export const SUBTOPIC_DELETE_MUTATION = gql`
  mutation deleteSubtopic($id: Int!) {
    deleteSubtopic(id: $id) {
      success
      message
    }
  }
`
