import React from "react"
import { Link } from "react-router-dom"
import CenteredRows from "../../components/CenteredRows"
import checkedCircle from "../../assets/checked-circle.svg"
import "./index.scss"
import { TopicContext } from "../../context/TopicContext"
import AppHeader from "../../components/AppHeader"

const Top = () => {
  return (
    <div className="group top">
      <h1 className="heading color green">Well Done!</h1>
      <span className="sub-heading color dark-purple">
        Topic tagging complete!
      </span>
    </div>
  )
}

const Middle = () => {
  return (
    <div className="group middle">
      <img src={checkedCircle} className="check" alt="check marker" />
    </div>
  )
}

const Bottom = () => {
  return (
    <TopicContext.Consumer>
      {({ topic, getNewTopic }) => (
        <div className="group bottom">
          <h3 className="heading color green light">{topic.title}</h3>
          <Link to={`/`} onClick={getNewTopic} className="btn green block">
            Next
          </Link>
        </div>
      )}
    </TopicContext.Consumer>
  )
}

const WellDonePage = () => {
  return (
    <>
      <AppHeader fixed />
      <CenteredRows
        className="well-done-page"
        top={Top}
        middle={Middle}
        bottom={Bottom}
      />
    </>
  )
}

export default WellDonePage
