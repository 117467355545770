import React, { useMemo, useEffect } from "react"
import PropTypes from "prop-types"

import TabPage from "../../components/TabPage"
import Approval from "./tabs/Approval"
import Status from "./tabs/Status"
import { getRole } from "../../utils/auth"
import "./index.scss"

const QueriesPage = ({ history, match: { url } }) => {
  // effects
  useEffect(() => {
    const role = getRole()

    if (role.value === "admin") {
      history.push("/")
    }
  }, [history])

  // rendering
  const tabs = useMemo(() => {
    return [
      {
        path: "/approval",
        component: () => <Approval />,
        label: "For approval"
      },
      {
        path: "/status",
        component: () => <Status />,
        label: "Queries status"
      }
    ]
  }, [])

  return <TabPage className="TopicEditPage" url={url} tabs={tabs} />
}

QueriesPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default QueriesPage
