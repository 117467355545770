import React, { Fragment } from "react";

export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4285 8.21425C12.4285 10.5417 10.5417 12.4285 8.21425 12.4285C5.88678 12.4285 4 10.5417 4 8.21425C4 5.88678 5.88678 4 8.21425 4C10.5417 4 12.4285 5.88678 12.4285 8.21425ZM11.4285 8.21425C11.4285 9.98943 9.98943 11.4285 8.21425 11.4285C6.43907 11.4285 5 9.98943 5 8.21425C5 6.43907 6.43907 5 8.21425 5C9.98943 5 11.4285 6.43907 11.4285 8.21425Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5451 19.6654L16.5562 19.6838C17.1076 20.5988 17.1153 21.6453 16.7437 22.4753C16.3752 23.2985 15.5757 24 14.4998 24H2.50024C1.37978 24 0.598529 23.2088 0.251125 22.4051C-0.0997077 21.5935 -0.130971 20.5218 0.471247 19.6408C2.26081 16.9451 5.09237 15 8.50001 15C11.9105 15 14.7439 16.9483 16.5332 19.6475L16.5451 19.6654ZM15.6997 20.2C16.4497 21.4444 15.6997 23 14.4998 23H2.50024C1.30029 23 0.550317 21.2889 1.30029 20.2C2.95023 17.7111 5.50013 16 8.50001 16C11.4999 16 14.0498 17.7111 15.6997 20.2Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5781 12.6116C13.7291 10.8023 15.5483 9.5 17.7372 9.5C19.9261 9.5 21.7454 10.8023 22.8963 12.6116L22.8969 12.6126C23.1953 13.0839 23.3194 13.5696 23.1282 14.0141C22.9479 14.4334 22.5535 14.6504 22.2295 14.7676C21.5866 15.0004 20.7669 15.0001 20.5139 15C20.5091 15 20.5044 15 20.5 15C20.2239 15 20 14.7761 20 14.5C20 14.2239 20.2239 14 20.5 14C20.7646 14 21.4257 13.9951 21.8891 13.8274C22.1245 13.7421 22.194 13.6554 22.2096 13.619C22.2144 13.6079 22.2664 13.4862 22.0522 13.1477C21.0319 11.5441 19.4958 10.5 17.7372 10.5C15.9784 10.5 14.4422 11.5444 13.4219 13.1484C13.2737 13.3814 12.9646 13.4501 12.7316 13.3019C12.4986 13.1537 12.4299 12.8446 12.5781 12.6116Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 4C21 5.65685 19.6569 7 18 7C16.3431 7 15 5.65685 15 4C15 2.34315 16.3431 1 18 1C19.6569 1 21 2.34315 21 4ZM20 4C20 5.10457 19.1046 6 18 6C16.8954 6 16 5.10457 16 4C16 2.89543 16.8954 2 18 2C19.1046 2 20 2.89543 20 4Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7768 13.9519L20.8046 14.0139C20.9946 14.4367 21.0707 14.9319 20.921 15.4381C20.7768 15.926 20.4559 16.3124 20.058 16.6033L18 18.2942L15.9027 16.5711L15.8879 16.5579C15.5183 16.229 15.2171 15.831 15.079 15.3637C14.9336 14.872 14.9908 14.3699 15.2356 13.934L15.247 13.9139L15.2592 13.8943C15.5575 13.4163 16.0002 13.1537 16.4701 13.0737C16.8958 13.0012 17.2904 13.0855 17.5702 13.176C17.7274 13.2268 17.8728 13.2877 18 13.3483C18.1272 13.2877 18.2726 13.2268 18.4297 13.176C18.7095 13.0855 19.1042 13.0012 19.5298 13.0737C19.9998 13.1537 20.4425 13.4163 20.7408 13.8943L20.7768 13.9519ZM19.4472 15.811C20.0038 15.4146 20.1151 14.9192 19.8925 14.4238C19.3359 13.532 18 14.5 18 14.5C18 14.5 16.6641 13.532 16.1075 14.4238C15.8848 14.8201 15.9962 15.3155 16.5528 15.811L18 17L19.4472 15.811Z"
    />
  </>
);
