import { useEffect, useState } from "react"
import gql from "graphql-tag"

const CREATOR_QUERY = gql`
  query CREATOR_QUERY($creatorsInfo: [ExternalEntityInput!]!) {
    creators(creatorsInfo: $creatorsInfo) {
      country
      title
      thumbnail
      provider
      topics {
        language
        title
        topicId
      }
      unconfirmedTopics {
        language
        title
        topicId
      }
    }
  }
`

const useCreatorQuery = function({ client, searchTerm, blockCreators }) {
  const [infosCreator, setInfosCreator] = useState(null)
  const [loadingCreator, setLoadingCreator] = useState(false)
  const [noResults, setNoResults] = useState(false)
  const [creatorDontHaveTopic, setCreatorDontHaveTopic] = useState(false)
  const [allTopics, setAllTopics] = useState([])
  const [topics, setTopics] = useState(null)
  const [creatorIdProvider, setCreatorIdProvide] = useState(null)
  useEffect(() => {
    async function getCreatorQuery() {
      try {
        if (!searchTerm) return
        if (loadingCreator) return

        setNoResults(false)
        setInfosCreator(null)

        const query = CREATOR_QUERY

        const regexSearchTerm = /\S[^/]+\/\w.\/$/
        const parseSearchTerm = regexSearchTerm.exec(searchTerm)
        const regexParamsSearchTerm = /([^/]+\S)\/(\w\w)/
        const paramsSearchTerm = regexParamsSearchTerm.exec(parseSearchTerm)
        const searchTermParamCreator = paramsSearchTerm[1]
        const searchTermParamProvider = paramsSearchTerm[2]

        const variables = {
          creatorsInfo: [
            { id: searchTermParamCreator, provider: searchTermParamProvider }
          ]
        }

        setCreatorIdProvide({
          creatorId: searchTermParamCreator,
          provider: searchTermParamProvider
        })
        setLoadingCreator(true)

        const {
          data: { creators }
        } = await client.query({
          query,
          variables
        })

        setLoadingCreator(false)

        if (!creators.length) setNoResults(true)

        setInfosCreator(creators[0] || null)

        if (
          creators.length &&
          (creators?.[0].topics.length ||
            creators?.[0].unconfirmedTopics.length)
        ) {
          setCreatorDontHaveTopic(false)

          const allTopics = creators?.[0].topics.concat(
            creators?.[0].unconfirmedTopics
          )

          const topicsDeduplicated = allTopics.filter(
            (topic, index, self) =>
              index === self.findIndex(t => t.title === topic.title)
          )

          const isCreatorOnBlockList = blockCreators.filter(
            creator => creator.creator.title === creators[0].title
          )

          if (isCreatorOnBlockList.length) {
            const topicsCreatorBlockList = isCreatorOnBlockList[0].topics.map(
              creatorTopic => creatorTopic.title
            )

            const topicsWithoutTopicsInBlocklist = topicsDeduplicated.filter(
              topic => !topicsCreatorBlockList.includes(topic.title)
            )

            const allTopicsFormatedBlockList = topicsWithoutTopicsInBlocklist.map(
              topic => ({
                label: topic.title,
                value: topic.title,
                id: topic.topicId
              })
            )

            setAllTopics(allTopicsFormatedBlockList)
            setTopics(allTopicsFormatedBlockList[0])

            return
          }

          const allTopicsFormated = topicsDeduplicated.map(topic => ({
            label: topic.title,
            value: topic.title,
            id: topic.topicId
          }))

          setAllTopics(allTopicsFormated)
          setTopics(allTopicsFormated[0])
        } else {
          setCreatorDontHaveTopic(true)
          setAllTopics([])
          setTopics(null)
        }
      } catch (e) {
        console.error("error getting Creators >>>>>>>>>>>", e)
        setLoadingCreator(false)
        setNoResults(true)
        setCreatorIdProvide(null)
      }
    }
    getCreatorQuery()
  }, [searchTerm])

  return {
    infosCreator,
    loadingCreator,
    noResults,
    creatorDontHaveTopic,
    allTopics,
    setTopics,
    topics,
    creatorIdProvider
  }
}

export default useCreatorQuery
