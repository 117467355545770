import React, { useState } from "react"
import PropTypes from "prop-types"
import { useMutation, useQuery } from "@apollo/react-hooks"
import { withApollo, compose } from "react-apollo"
import { withRouter } from "react-router-dom"
import { Loading } from "../../../../shared/shared.ui.loading"

import Input from "../../../../components/Input"
import CardWithOptions from "../../../../components/CardWithOptions"
import HighlightedQuery from "../../../../components/HighlightedQuery"
import ToastStore from "../../../../stores/ToastStore"
import { ReactComponent as TrashIcon } from "../../../../assets/icon/trash.svg"
import { ReactComponent as EditIcon } from "../../../../assets/icon/edit.svg"
import { getRole } from "../../../../utils"
import { queryStatus } from "../../../../consts/queryStatus"
import { CREATE_QUERY_MUTATION, LIST_QUERIES_QUERY } from "./query"
import "./index.scss"

const TermsAndQueries = ({ topic, client, history }) => {
  // consts
  const role = getRole()

  // state
  const [queryInputRef, setQueryInputRef] = useState()

  // queries and mutations
  const {
    data: queriesData,
    loading: queriesLoading,
    error: queriesError,
    refetch: queriesRefetch
  } = useQuery(LIST_QUERIES_QUERY, {
    client,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      topicId: topic.topicId,
      status: [queryStatus.active, queryStatus.processing],
      language: topic.language
    },
    onError: error => {
      console.error("TermsAndQueries @ useQuery >>>>>", error)
    }
  })
  const [createQuery] = useMutation(CREATE_QUERY_MUTATION, {
    client,
    onCompleted: ({ createTopicQueryClassifier: { success, message } }) => {
      if (success) {
        queryInputRef.current.value = ""
        queriesRefetch()

        ToastStore.clearToasts()
        ToastStore.addToast({
          title: "Term/Query added successfully.",
          id: "create-query-success"
        })
      } else {
        console.error("TermsAndQueries @ createQuery >>>>> ", message)

        ToastStore.clearToasts()
        ToastStore.addToast({
          title: "Oops! Error adding term/query.",
          id: "create-query-error"
        })
      }
    },
    onError: error => {
      console.error("TermsAndQueries @ createQuery >>>>>", error)

      ToastStore.clearToasts()
      ToastStore.addToast({
        title: "Oops! Error adding term/query.",
        id: "create-query-error"
      })
    }
  })

  // functions
  const handleQueryCreate = value => {
    const { topicId, language } = topic
    const variables = {
      topicId,
      language,
      query: value
    }

    createQuery({ variables })
  }

  const handleAction = ({ action, queryObj }) => {
    const rootUrl = `/topics/edit/${topic.topicId}/queries`

    history.push({
      pathname: `${rootUrl}/${action}`,
      state: {
        backgroundPage: {
          pathname: rootUrl
        },
        queryObj
      }
    })
  }

  // rendering
  const renderQueriesList = () => {
    if (queriesLoading) {
      return <Loading />
    }

    if (queriesError) {
      return (
        <p className="error-message">
          Oops! Error loading terms and queries. Please, refresh the page or try
          again later.
        </p>
      )
    }

    const queries = queriesData?.listTopicQueryClassifiers ?? []

    if (!queries?.length) {
      return (
        <p className="empty-message">This topic has no active terms/queries.</p>
      )
    }

    return queries.map(queryObj => {
      const { id, query, status } = queryObj
      const isOptionsDisabled =
        role.value === "admin" || status === queryStatus.processing
      const options = [
        {
          icon: EditIcon,
          label: "Edit",
          onClick: () => handleAction({ action: "edit", queryObj })
        },
        {
          icon: TrashIcon,
          label: "Delete",
          onClick: () => handleAction({ action: "delete", queryObj })
        }
      ]

      return (
        <CardWithOptions
          key={id}
          options={options}
          isDisabled={isOptionsDisabled}
        >
          <HighlightedQuery query={query} />
        </CardWithOptions>
      )
    })
  }

  return (
    <div className="TermsAndQueries">
      <Input
        className="query-input"
        name="query"
        placeholder="Add a term or query inside the topic"
        onSubmit={handleQueryCreate}
        disabled={role.value === "admin"}
        getRef={ref => setQueryInputRef(ref)}
      />

      <div className="queries-list">{renderQueriesList()}</div>
    </div>
  )
}

TermsAndQueries.propTypes = {
  topic: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default compose(withApollo)(withRouter(TermsAndQueries))
