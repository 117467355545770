import React, { Fragment } from "react";

export default (
  <>
    <path d="M12.48 18.24a.96.96 0 11-1.92 0 .96.96 0 011.92 0z" />
    <path d="M11.52 16.83c-.77 0-1.41.64-1.41 1.41 0 .77.64 1.41 1.4 1.41.78 0 1.42-.64 1.42-1.41 0-.77-.64-1.41-1.41-1.41z" />
    <path d="M12 0C5.38 0 0 5.38 0 12s5.38 12 12 12 12-5.38 12-12S18.62 0 12 0zm0 1.95c5.56 0 10.05 4.49 10.05 10.05 0 5.56-4.49 10.05-10.05 10.05A10.03 10.03 0 011.95 12C1.95 6.44 6.44 1.95 12 1.95z" />
    <path d="M11.06 14.18c0 .15.07.22.23.22h.82c.15 0 .23-.07.23-.22v-.88c0-.32.1-.6.3-.82.2-.22.5-.48.9-.78.4-.3.71-.55.95-.78.23-.23.44-.52.6-.88.18-.36.27-.8.27-1.3 0-.9-.3-1.62-.9-2.17-.61-.54-1.41-.81-2.4-.81a3.6 3.6 0 00-2.48.83 2.76 2.76 0 00-.94 2.17v.43c0 .15.08.22.23.22l.8.03c.15 0 .23-.06.23-.2v-.48c0-.56.2-1.02.57-1.37.4-.35.9-.53 1.55-.53.62 0 1.13.18 1.5.53.39.34.58.8.58 1.36 0 .35-.07.65-.22.91-.13.26-.3.5-.5.68-.2.18-.47.4-.81.67-.51.38-.9.73-1.14 1.03-.25.3-.37.7-.37 1.19z" />
    <path d="M12.05 5.3c-1.1 0-2.05.32-2.77.95a3.26 3.26 0 00-1.1 2.51v.43c0 .15.06.35.2.49a.7.7 0 00.49.18l.79.03h.01a.7.7 0 00.47-.16c.15-.13.21-.34.21-.49v-.48c0-.46.14-.77.42-1.04.3-.26.69-.4 1.25-.4.54 0 .91.13 1.2.4.3.26.43.56.43 1.03 0 .29-.06.51-.16.7l-.01.01c-.1.22-.24.4-.4.55-.19.17-.45.38-.78.64-.53.4-.93.76-1.22 1.1-.32.4-.47.92-.47 1.48v.95c0 .15.05.35.2.49a.7.7 0 00.48.18h.82a.7.7 0 00.48-.18c.15-.14.2-.34.2-.49v-.88a.7.7 0 01.19-.52c.16-.19.44-.43.83-.72.4-.3.73-.57.99-.81.28-.27.51-.62.7-1.02.2-.43.31-.93.31-1.48 0-1-.36-1.88-1.06-2.51a3.93 3.93 0 00-2.7-.93z" />
  </>
);
