import React, { Fragment } from "react";

export default (
  <>
    <path d="M18.2537 5.79999H15.9179C15.5346 5.79999 15.2911 6.14834 15.2578 6.46146L13.8029 16.6461L11.9013 8.60478C11.6222 7.47042 11.188 7.32726 10.3309 7.32726H8.64529C7.78832 7.32726 7.35399 7.47198 7.07442 8.60839L5.17343 16.6382L3.71951 6.46518C3.6852 6.1432 3.44186 5.79999 3.05847 5.79999H0.722532C0.330696 5.79999 0 6.14471 0 6.553V6.65171L2.20179 18.2096C2.50086 19.7678 2.93745 20.5 4.57554 20.5H5.22571C6.58817 20.5 7.38575 19.83 7.73518 18.373L9.48809 11.302L11.2405 18.3689C11.5907 19.8276 12.3882 20.5 13.7505 20.5H14.4007C16.0388 20.5 16.4693 19.7678 16.7686 18.2091L18.9599 6.66992L18.9643 6.54439C18.9643 6.13609 18.6455 5.79999 18.2537 5.79999Z" />
    <path d="M21.9167 4.52755C22.5077 4.52755 23.0272 4.33452 23.4608 3.95385C23.9009 3.56748 24.1241 3.04341 24.1241 2.39627C24.1241 1.73244 23.901 1.19977 23.4607 0.812914C23.0267 0.432287 22.5072 0.239258 21.9167 0.239258C21.3082 0.239258 20.7797 0.432287 20.346 0.813003C19.9059 1.19942 19.6828 1.73208 19.6828 2.39627C19.6828 3.04376 19.906 3.56779 20.3459 3.95376C20.7792 4.33452 21.3077 4.52755 21.9167 4.52755Z" />
    <path d="M23.1842 6.12482H20.8486C20.4713 6.12482 20.207 6.44491 20.1697 6.76866L20.1682 6.77387L20.169 19.316L20.1664 19.3333L20.1697 19.3603C20.2074 19.677 20.4715 20.0041 20.8484 20.0041L23.1832 19.9916C23.5609 19.9847 23.8191 19.6666 23.8562 19.3533L23.8604 19.3478L23.8629 6.79245L23.8654 6.77984L23.8627 6.76829C23.8253 6.44454 23.5616 6.12482 23.1842 6.12482Z" />
    <path d="M39.3305 12.4033C39.3305 11.452 39.2357 10.5634 39.0487 9.76218C38.8576 8.9439 38.5243 8.23389 38.0581 7.65161C37.5892 7.0662 36.9529 6.60532 36.1667 6.28172C35.3891 5.96206 34.4146 5.79999 33.2702 5.79999C32.0734 5.79999 30.9657 5.88135 29.9782 6.04177C29.24 6.1617 28.5953 6.28879 28.0615 6.41964L28.0457 6.41909L28.003 6.43479C27.6145 6.57823 27.4002 6.8662 27.3958 7.24848L27.3937 7.25528L27.3929 19.7185L27.3944 19.7453C27.4314 20.072 27.6917 20.41 28.0632 20.41L28.1009 20.4086L30.3644 20.41C30.7358 20.41 30.9962 20.0721 31.0333 19.7453L31.0349 19.732L31.0379 9.56163C31.0338 9.41489 31.1201 9.19808 31.2899 9.17329C31.2901 9.17329 31.6134 9.1305 31.8017 9.10772C32.227 9.05602 32.6416 9.02976 33.034 9.02976C33.5439 9.02976 33.9788 9.1001 34.3266 9.23876C34.6612 9.37265 34.9182 9.57862 35.1119 9.86815C35.3116 10.1671 35.4585 10.5659 35.5486 11.0532C35.6418 11.5603 35.689 12.1841 35.689 12.9075L35.6884 19.7832L35.6899 19.8227C35.727 20.1494 35.9873 20.5 36.3587 20.5H38.6724C39.0515 20.5 39.2952 20.1432 39.3318 19.8195L39.3334 19.7999L39.3305 12.4033Z" />
    <path d="M53.3782 12.4033C53.3782 11.452 53.2833 10.5634 53.0963 9.76218C52.9052 8.9439 52.5719 8.23389 52.1057 7.65161C51.6368 7.0662 51.0005 6.60532 50.2143 6.28172C49.4367 5.96206 48.4622 5.79999 47.3178 5.79999C46.121 5.79999 45.0133 5.88135 44.0258 6.04177C43.2876 6.1617 42.643 6.28879 42.1091 6.41964L42.0933 6.41909L42.0506 6.43479C41.6621 6.57823 41.4478 6.8662 41.4434 7.24848L41.4413 7.25528L41.4405 19.7185L41.442 19.7453C41.479 20.072 41.7393 20.41 42.1109 20.41L42.1485 20.4086L44.412 20.41C44.7834 20.41 45.0438 20.0721 45.0809 19.7453L45.0825 19.732L45.0855 9.56163C45.0814 9.41489 45.1677 9.19808 45.3375 9.17329C45.3377 9.17329 45.661 9.1305 45.8493 9.10772C46.2746 9.05602 46.6892 9.02976 47.0816 9.02976C47.5915 9.02976 48.0264 9.1001 48.3742 9.23876C48.7088 9.37265 48.9658 9.57862 49.1595 9.86815C49.3592 10.1671 49.5061 10.5659 49.5962 11.0532C49.6894 11.5603 49.7366 12.1841 49.7366 12.9075L49.736 19.7832L49.7375 19.8227C49.7746 20.1494 50.035 20.5 50.4063 20.5H52.72C53.0992 20.5 53.3428 20.1432 53.3794 19.8195L53.381 19.7999L53.3782 12.4033Z" />
    <path d="M57.7382 4.52755C58.3292 4.52755 58.8487 4.33452 59.2823 3.95385C59.7224 3.56748 59.9456 3.04341 59.9456 2.39627C59.9456 1.73244 59.7225 1.19977 59.2822 0.812914C58.8482 0.432287 58.3287 0.239258 57.7382 0.239258C57.1296 0.239258 56.6012 0.432287 56.1675 0.813003C55.7274 1.19942 55.5043 1.73208 55.5043 2.39627C55.5043 3.04376 55.7275 3.56779 56.1674 3.95376C56.6006 4.33452 57.1292 4.52755 57.7382 4.52755Z" />
    <path d="M59.0057 6.12482H56.6701C56.2927 6.12482 56.0285 6.44491 55.9912 6.76866L55.9897 6.77387L55.9904 19.316L55.9879 19.3333L55.9912 19.3603C56.0289 19.677 56.293 20.0041 56.6699 20.0041L59.0047 19.9916C59.3823 19.9847 59.6406 19.6666 59.6777 19.3533L59.6819 19.3478L59.6844 6.79245L59.6869 6.77984L59.6841 6.76829C59.6468 6.44454 59.3831 6.12482 59.0057 6.12482Z" />
    <path d="M74.4496 12.4033C74.4496 11.452 74.3547 10.5634 74.1678 9.76218C73.9766 8.9439 73.6433 8.23389 73.1771 7.65161C72.7082 7.0662 72.0719 6.60532 71.2857 6.28172C70.5081 5.96206 69.5336 5.79999 68.3892 5.79999C67.1924 5.79999 66.0848 5.88135 65.0972 6.04177C64.359 6.1617 63.7144 6.28879 63.1805 6.41964L63.1647 6.41909L63.122 6.43479C62.7335 6.57823 62.5192 6.8662 62.5148 7.24848L62.5127 7.25528L62.5119 19.7185L62.5134 19.7453C62.5504 20.072 62.8107 20.41 63.1823 20.41L63.2199 20.4086L65.4834 20.41C65.8548 20.41 66.1152 20.0721 66.1524 19.7453L66.1539 19.732L66.1569 9.56163C66.1528 9.41489 66.2391 9.19808 66.4089 9.17329C66.4091 9.17329 66.7324 9.1305 66.9207 9.10772C67.346 9.05602 67.7606 9.02976 68.153 9.02976C68.6629 9.02976 69.0978 9.1001 69.4456 9.23876C69.7802 9.37265 70.0372 9.57862 70.231 9.86815C70.4306 10.1671 70.5775 10.5659 70.6676 11.0532C70.7608 11.5603 70.808 12.1841 70.808 12.9075L70.8074 19.7832L70.8089 19.8227C70.846 20.1494 71.1064 20.5 71.4778 20.5H73.7914C74.1706 20.5 74.4142 20.1432 74.4508 19.8195L74.4524 19.7999L74.4496 12.4033Z" />
  </>
);
