import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./index.scss";

export const Link = ({
  className,
  RenderComponent,
  href,
  children,
  ...passProps
}) => {
  const classes = classNames("Insights-ui__link", className);

  return (
    <RenderComponent className={classes} {...passProps}>
      {children}
    </RenderComponent>
  );
};

Link.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.node,
  RenderComponent: PropTypes.elementType,
};

Link.defaultProps = {
  children: null,
  className: "",
  href: "",
  RenderComponent: "a",
};
