export const REALMS = {
  local: "tagger_dev",
  test: "tagger_dev",
  development: "tagger_dev",
  staging: "tagger_prod",
  production: "tagger_prod"
}

export const CLIENT_IDS = {
  local: "tagger-test-env",
  test: "tagger-test-env",
  development: "tagger-dev",
  staging: "tagger-staging",
  production: "tagger-prod"
}

export const ENDPOINT = {
  local: 'https://login.dev.insights.winnin.com/auth/',
  test: 'https://login.dev.insights.winnin.com/auth/',
  development: 'https://login.dev.insights.winnin.com/auth/',
  staging: 'https://login-insights.winnin.com/auth/',
  production: 'https://login-insights.winnin.com/auth/',
}
