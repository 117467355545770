import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./index.scss";
import ReactModal from "react-modal";

const defaultStyle = {
  content: {
    top: "25%",
    left: "25%",
    right: "25%",
    bottom: "auto",
    position: "fixed",
  },
  overlay: {
    backgroundColor: "transparent",
  },
};

export const Modal = ({
  isOpen,
  onRequestClose,
  customStyles,
  children,
  className,
  ...props
}) => {
  const classes = classNames("Insights-ui__Modal", className);
  return (
    <ReactModal
      appElement={document.body}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={classes}
      style={{ ...defaultStyle, ...customStyles }}
      closeTimeoutMS={200}
      {...props}
    >
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  customStyles: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
};

Modal.defaultProps = {
  customStyles: {},
  children: null,
  className: "",
};
