import React from "react"
import "./index.scss"

import search from "../../assets/icon/search.svg"
import noIcon from "../../assets/icon/no-icon.svg"
import arrowDown from "../../assets/icon/arrow-down.svg"

const Icons = {
  search,
  noIcon,
  arrowDown
}

const Icon = ({ icon, alt, title }) => {
  return (
    <div className="icon-container">
      <img src={Icons[icon]} alt={alt} title={title} />
    </div>
  )
}

Icon.defaultProps = {
  icon: "noIcon"
}

export default Icon
