import React, { Fragment } from "react";

export default (
  <>
    <rect x="13" width="11" height="11" rx="3" />
    <rect x="13" y="12.9998" width="11" height="11" rx="3" />
    <rect width="11" height="11" rx="3" />
    <rect y="12.9998" width="11" height="11" rx="3" />
  </>
);
