import React, { Fragment } from "react";

export default (
  <>
    <circle cx="12" cy="12" r="12" fill="#FBAE42" />
    <path
      d="M11.5 15L11 6H13L12.4806 15H11.5ZM11 16H13V18H11V16Z"
      fill="white"
    />
  </>
);
