import React from "react"
import { Icon } from "../../shared/shared.ui.icon"

import checkMark from "../../assets/img/check.svg"
import xMark from "../../assets/img/x.svg"
import Clamp from "react-dotdotdot"
import { secondsToTimeString } from "../../utils/time"
import Tooltip from "react-tooltip"
import { PROVIDERS } from "../../consts"
import Insights from "../../frontend_commons"
import "./index.scss"

const { Format } = Insights.Utils

const buildHandleEnterKeyPress = onClick => ({ key }) => {
  if (key === "Enter") {
    onClick()
  }
}

const VideoCard = ({ video, index, tagAction }) => {
  const {
    thumbnail,
    title,
    tags,
    durationInSeconds: length,
    views,
    engagement
  } = video
  const confirm = () => tagAction({ video, index, value: true })
  const cancel = () => tagAction({ video, index, value: false })

  const providerName = (PROVIDERS[video.provider] || {}).NAME || "Facebook"

  return (
    <div className="video-card-container">
      <div className="video-card-thumb">
        <div className="img" style={{ backgroundImage: `url(${thumbnail})` }} />
        <span>{secondsToTimeString(length)}</span>
        <Icon
          icon={providerName.toLowerCase()}
          className={`icon ${video.provider}`}
        />
      </div>
      <div className="video-card-body">
        <a href={video.postUrl} target="_blank" rel="noopener noreferrer">
          <Clamp clamp={2}>{title}</Clamp>
        </a>

        <div className="video-card-tags">
          {Array.isArray(tags) && tags.length > 0 && (
            <div
              data-tip
              data-for={`tags-${index}`}
              className="statistics"
              title="tags"
            >
              <Tooltip
                className="tooltip"
                id={`tags-${index}`}
                place="top"
                type="dark"
                effect="solid"
              >
                {tags.map((tag, index) => (
                  <span key={`${tag}-${index}`} className="tooltip-tag">
                    {tag}
                  </span>
                ))}
              </Tooltip>
              <span className="tags-title">Tags: </span>
              {tags.map((tag, index) => (
                <span key={`${tag}-${index}`} className="tag">
                  {tag}
                  {index === tags.length - 1 ? "" : ", "}
                </span>
              ))}
            </div>
          )}
        </div>
        {!tagAction && (
          <div className="video-card-metrics">
            <div className="metric">
              <Icon fill="#a0a0a0" icon="view" />
              {Format.Number.Human(views)}
            </div>
            <div className="metric">
              <Icon fill="#a0a0a0" icon="engagement" />
              {Format.Number.Human(engagement)}
            </div>
          </div>
        )}
      </div>
      {tagAction && (
        <div className="video-card-buttons">
          <div
            onClick={cancel}
            onKeyPress={buildHandleEnterKeyPress(cancel)}
            tabIndex={index + 1}
            className="button cancel"
          >
            <img src={xMark} alt="" />
          </div>
          <div
            onKeyPress={buildHandleEnterKeyPress(confirm)}
            onClick={confirm}
            tabIndex={index + 1}
            className="button confirm"
          >
            <img src={checkMark} alt="" />
          </div>
        </div>
      )}
    </div>
  )
}

export default VideoCard
