import React from "react"
import "./index.scss"

import PropTypes from "prop-types"
import classNames from "classnames"

const YoutubePlayer = ({ url }) => {
  const src = `https://www.youtube.com/embed/${url.replace(
    "https://www.youtube.com/watch?v=",
    ""
  )}`
  return (
    <iframe
      className="provider-player"
      height="315"
      src={src}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="youtube-player"
    />
  )
}

const InstagramPlayer = ({ url }) => (
  <video className="provider-player" controls autoPlay name>
    <source src={url} type="video/mp4" />
  </video>
)

const FacebookPlayer = ({ url }) => (
  <iframe
    className="provider-player"
    src={`https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(
      url
    )}show_text=0&width=476`}
    scrolling="no"
    frameBorder="0"
    allowFullScreen
    title="facebook-player"
  />
)

const players = {
  yt: YoutubePlayer,
  in: InstagramPlayer,
  fb: FacebookPlayer
}

const Player = ({ url, provider, className }) => {
  const wrapperClass = classNames("player-wrapper")
  const containerClass = classNames("player-component", className)
  const PlayerComp = players[provider]

  return (
    <div className={containerClass}>
      <div className={wrapperClass}>
        <PlayerComp url={url} />
      </div>
    </div>
  )
}

Player.propTypes = {
  url: PropTypes.string,
  provider: PropTypes.string
}
Player.defaultProps = {
  url: "",
  provider: "yt"
}
Player.displayName = "Player"

export default Player
