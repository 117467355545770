import React, { useState } from "react"
import { useQuery } from "@apollo/react-hooks"
import { withApollo } from "react-apollo"
import { POSTS_QUERY } from "./query"
import { PAGINATION } from "../../../../consts"
import SimplePostGrid from "../../../../components/SimplePostGrid"
import PropTypes from "prop-types"

const Videos = ({ topicId, client }) => {
  const [hasMore, setHasMore] = useState(true)

  const { data, loading, error, networkStatus, fetchMore } = useQuery(
    POSTS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      client,
      variables: {
        query: {
          topicIds: [topicId]
        }
      },
      onCompleted: ({ searchPosts: { entities, rows } }) => {
        const postsCount = entities.length
        const limit = PAGINATION.PAGE_SIZE
        const hasMore = postsCount >= limit && postsCount < rows

        setHasMore(hasMore)
      }
    }
  )

  const loadMore = async () => {
    if (loading || error || !hasMore) return

    const posts = data?.searchPosts?.entities
    const lastPostIndex = posts.length - 1
    const lastPost = posts[lastPostIndex]

    const lastPostId = lastPost.postId
    const lastValue = lastPost.views.toString()

    const variables = {
      lastPostId,
      lastValue
    }

    try {
      await fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.searchPosts?.entities?.length) {
            return prev
          }

          if (
            fetchMoreResult.searchPosts.entities.length < PAGINATION.PAGE_SIZE
          ) {
            setHasMore(false)
          }

          return Object.assign({}, prev, {
            searchPosts: {
              ...prev.searchPosts,
              entities: [
                ...prev.searchPosts.entities,
                ...fetchMoreResult.searchPosts.entities
              ]
            }
          })
        }
      })
    } catch (e) {
      console.error("TopicEditPage @ loadMore >>>>>", e)
    }
  }

  const posts = data?.searchPosts?.entities
  const postsTotal = data?.searchPosts?.rows

  return (
    <div className="Videos">
      <SimplePostGrid
        posts={posts}
        postsTotal={postsTotal}
        loadMore={loadMore}
        hasMore={hasMore}
        loading={loading}
        networkStatus={networkStatus}
        error={error}
      />
    </div>
  )
}

Videos.propTypes = {
  topicId: PropTypes.number.isRequired,
  client: PropTypes.object.isRequired
}

export default withApollo(Videos)
