import React, { Component } from "react"
import queryString from "query-string"
import Utils from "../../utils"

// import './index.scss'
// import classNames from 'classnames'

function withFilters(WrapedComponent, Group) {
  const { FilterGroups } = Utils.FiltersUtils

  const currentGroup = FilterGroups[Group]

  class WithFilters extends Component {
    state = {
      filters: currentGroup.toState()
    }

    onFiltersLoadFlag

    lastFiltersState = null

    componentDidMount() {
      currentGroup.setComponent(this, "change")
      if (!this.onFiltersLoadFlag) {
        this.onFiltersLoad()
      }
    }

    onFiltersLoad = func => {
      this.onFiltersLoadFlag = true

      currentGroup.setOnload(() => {
        this.mapFiltersToState()
        if (func) func()
      })
    }

    change = (callback = null) => {
      this.setState(
        {
          filters: currentGroup.toState(),
          variables: currentGroup.toVariables(false, "value", true),
          selectedLabels: currentGroup.toVariables(false, "label")
        },
        callback
      )
    }

    getQueryString() {
      const variablesNotDefault = this.lastFiltersState
      const query = queryString.stringify(variablesNotDefault)
      const qs = query ? `?${query}` : ""

      return qs
    }

    mapFiltersToState = () => {
      const parseFilters = this.parsedFilters()
      if (currentGroup) {
        currentGroup.forEach((groupFilter, filterKey) => {
          if (parseFilters[filterKey]) {
            currentGroup.initializeValue(parseFilters[filterKey], filterKey)
          }
        })

        if (currentGroup.promisesToWait.length) {
          Promise.all(currentGroup.promisesToWait).then(() => {
            this.change()
          })
        } else {
          this.change()
        }
      } else {
        throw new Error(`Filter group ${Group} not found`)
      }
    }

    parsedFilters = () => queryString.parse(window.location.search)

    // ver mais para frente se é a melhor maneira de lidar com a querystring
    updateUrl = () => {
      this.lastFiltersState = currentGroup.toVariables(true)

      const url = `${window.location.protocol}//${window.location.host}${
        window.location.pathname
      }${this.getQueryString()}`
      window.history.pushState({}, "", url)

      this.change()
    }

    generateUrl = path => path + this.getQueryString()

    onFilterChange = callback => {
      currentGroup.subscribe(callback)
    }

    clearAllFilters = () => {
      currentGroup.clearAll()
    }

    saveFiltersState = () => {
      currentGroup.saveState()
    }

    backTolastStoredState = () => {
      currentGroup.backTolastStoredState()
    }

    render() {
      const { filters, variables, selectedLabels } = this.state
      const { ...passProps } = this.props

      if (!variables) {
        return null
      }

      return (
        <WrapedComponent
          filters={filters}
          variables={variables}
          selectedLabels={selectedLabels}
          onFiltersLoad={this.onFiltersLoad}
          onFilterChange={this.onFilterChange}
          generateUrl={this.generateUrl}
          saveFiltersState={this.saveFiltersState}
          backTolastStoredState={this.backTolastStoredState}
          updateUrl={this.updateUrl}
          clearAllFilters={this.clearAllFilters}
          {...passProps}
        />
      )
    }
  }

  return WithFilters
}

export default withFilters
