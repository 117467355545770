import React, { Fragment } from "react";

export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 16C11.7599 16 11.5396 15.9154 11.3672 15.7743L6.37534 11.7809C5.94408 11.4359 5.87416 10.8066 6.21917 10.3753C6.56418 9.94404 7.19347 9.87412 7.62473 10.2191L11 12.9193L11 6C11 5.44771 11.4477 5 12 5C12.5523 5 13 5.44771 13 6L13 12.9194L16.3753 10.2191C16.8066 9.87412 17.4359 9.94404 17.7809 10.3753C18.1259 10.8066 18.056 11.4359 17.6247 11.7809L12.6284 15.7779C12.4567 15.9168 12.2381 16 12 16ZM6 17C5.44772 17 5 17.4477 5 18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18C19 17.4477 18.5523 17 18 17H6Z"
    />
  </>
);
