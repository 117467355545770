import React from "react";

export default (
  <>
    <mask id="path-1-inside-1" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18ZM12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18ZM12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z"
      fill="#868A90"
    />
    <path
      d="M17 12C17 14.7614 14.7614 17 12 17V19C15.866 19 19 15.866 19 12H17ZM12 7C14.7614 7 17 9.23858 17 12H19C19 8.13401 15.866 5 12 5V7ZM7 12C7 9.23858 9.23858 7 12 7V5C8.13401 5 5 8.13401 5 12H7ZM12 17C9.23858 17 7 14.7614 7 12H5C5 15.866 8.13401 19 12 19V17ZM18 12C18 15.3137 15.3137 18 12 18V20C16.4183 20 20 16.4183 20 12H18ZM12 6C15.3137 6 18 8.68629 18 12H20C20 7.58172 16.4183 4 12 4V6ZM6 12C6 8.68629 8.68629 6 12 6V4C7.58172 4 4 7.58172 4 12H6ZM12 18C8.68629 18 6 15.3137 6 12H4C4 16.4183 7.58172 20 12 20V18Z"
      fill="#868A90"
      mask="url(#path-1-inside-1)"
    />
    <mask id="path-3-inside-2" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2238 5.94714C11.0933 7.01327 9.5 9.45249 9.5 12C9.5 14.5475 11.0933 16.9867 13.2238 18.0529L12.7762 18.9471C10.3157 17.7158 8.5 14.9356 8.5 12C8.5 9.06439 10.3157 6.28418 12.7762 5.05286L13.2238 5.94714Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2238 5.94714C11.0933 7.01327 9.5 9.45249 9.5 12C9.5 14.5475 11.0933 16.9867 13.2238 18.0529L12.7762 18.9471C10.3157 17.7158 8.5 14.9356 8.5 12C8.5 9.06439 10.3157 6.28418 12.7762 5.05286L13.2238 5.94714Z"
      fill="#868A90"
    />
    <path
      d="M13.2238 5.94714L13.6713 6.84142L14.5655 6.3939L14.118 5.49962L13.2238 5.94714ZM13.2238 18.0529L14.118 18.5004L14.5655 17.6061L13.6713 17.1586L13.2238 18.0529ZM12.7762 18.9471L12.3287 19.8414L13.223 20.2889L13.6705 19.3947L12.7762 18.9471ZM12.7762 5.05286L13.6705 4.60535L13.223 3.71107L12.3287 4.15859L12.7762 5.05286ZM10.5 12C10.5 9.84049 11.871 7.74232 13.6713 6.84142L12.7762 5.05286C10.3156 6.28422 8.5 9.06448 8.5 12H10.5ZM13.6713 17.1586C11.871 16.2577 10.5 14.1595 10.5 12H8.5C8.5 14.9355 10.3156 17.7158 12.7762 18.9471L13.6713 17.1586ZM13.6705 19.3947L14.118 18.5004L12.3295 17.6053L11.882 18.4996L13.6705 19.3947ZM7.5 12C7.5 15.3238 9.53815 18.445 12.3287 19.8414L13.2238 18.0529C11.0932 16.9867 9.5 14.5474 9.5 12H7.5ZM12.3287 4.15859C9.53815 5.55505 7.5 8.67622 7.5 12H9.5C9.5 9.45256 11.0932 7.01331 13.2238 5.94714L12.3287 4.15859ZM14.118 5.49962L13.6705 4.60535L11.882 5.50038L12.3295 6.39466L14.118 5.49962Z"
      fill="#868A90"
      mask="url(#path-3-inside-2)"
    />
    <mask id="path-5-inside-3" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7762 18.0529C12.9067 16.9867 14.5 14.5475 14.5 12C14.5 9.45248 12.9067 7.01327 10.7762 5.94714L11.2238 5.05286C13.6843 6.28418 15.5 9.06439 15.5 12C15.5 14.9356 13.6843 17.7158 11.2238 18.9471L10.7762 18.0529Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7762 18.0529C12.9067 16.9867 14.5 14.5475 14.5 12C14.5 9.45248 12.9067 7.01327 10.7762 5.94714L11.2238 5.05286C13.6843 6.28418 15.5 9.06439 15.5 12C15.5 14.9356 13.6843 17.7158 11.2238 18.9471L10.7762 18.0529Z"
      fill="#868A90"
    />
    <path
      d="M10.7762 18.0529L10.3287 17.1586L9.43445 17.6061L9.88197 18.5004L10.7762 18.0529ZM10.7762 5.94714L9.88197 5.49962L9.43445 6.3939L10.3287 6.84141L10.7762 5.94714ZM11.2238 5.05286L11.6713 4.15858L10.777 3.71107L10.3295 4.60534L11.2238 5.05286ZM11.2238 18.9471L10.3295 19.3947L10.777 20.2889L11.6713 19.8414L11.2238 18.9471ZM13.5 12C13.5 14.1595 12.129 16.2577 10.3287 17.1586L11.2238 18.9471C13.6844 17.7158 15.5 14.9355 15.5 12L13.5 12ZM10.3287 6.84141C12.129 7.74232 13.5 9.84049 13.5 12L15.5 12C15.5 9.06448 13.6844 6.28422 11.2238 5.05286L10.3287 6.84141ZM10.3295 4.60534L9.88197 5.49962L11.6705 6.39465L12.118 5.50038L10.3295 4.60534ZM16.5 12C16.5 8.67622 14.4618 5.55505 11.6713 4.15858L10.7762 5.94714C12.9068 7.0133 14.5 9.45256 14.5 12L16.5 12ZM11.6713 19.8414C14.4618 18.4449 16.5 15.3238 16.5 12L14.5 12C14.5 14.5474 12.9068 16.9867 10.7762 18.0529L11.6713 19.8414ZM9.88197 18.5004L10.3295 19.3947L12.118 18.4996L11.6705 17.6053L9.88197 18.5004Z"
      fill="#868A90"
      mask="url(#path-5-inside-3)"
    />
    <mask id="path-7-inside-4" fill="white">
      <path fillRule="evenodd" clipRule="evenodd" d="M18 10H6V9H18V10Z" />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10H6V9H18V10Z"
      fill="#868A90"
    />
    <path
      d="M6 10H5V11H6V10ZM18 10V11H19V10H18ZM6 9V8H5V9H6ZM18 9H19V8H18V9ZM6 11H18V9H6V11ZM5 9V10H7V9H5ZM18 8H6V10H18V8ZM19 10V9H17V10H19Z"
      fill="#868A90"
      mask="url(#path-7-inside-4)"
    />
    <mask id="path-9-inside-5" fill="white">
      <path fillRule="evenodd" clipRule="evenodd" d="M18 10V9H6V10H18Z" />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10V9H6V10H18Z"
      fill="#868A90"
    />
    <path
      d="M18 10V11H19V10H18ZM18 9H19V8H18V9ZM6 9V8H5V9H6ZM6 10H5V11H6V10ZM17 9V10H19V9H17ZM7 10V9H5V10H7ZM18 9H6V11H18V9ZM6 10H18V8H6V10Z"
      fill="#868A90"
      mask="url(#path-9-inside-5)"
    />
    <mask id="path-11-inside-6" fill="white">
      <path fillRule="evenodd" clipRule="evenodd" d="M18 15H6V14H18V15Z" />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 15H6V14H18V15Z"
      fill="#868A90"
    />
    <path
      d="M6 15H5V16H6V15ZM18 15V16H19V15H18ZM6 14V13H5V14H6ZM18 14H19V13H18V14ZM6 16H18V14H6V16ZM5 14V15H7V14H5ZM18 13H6V15H18V13ZM19 15V14H17V15H19Z"
      fill="#868A90"
      mask="url(#path-11-inside-6)"
    />
  </>
);
