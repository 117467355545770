import React from "react"
import "./index.scss"

const CenteredRows = ({
  className = "default",
  top = null,
  middle = null,
  bottom = null
}) => {
  return (
    <div className={`centered-main-container ${className}`}>
      <div className="centered-columns">
        {top && top()}
        {middle && middle()}
        {bottom && bottom()}
      </div>
    </div>
  )
}

export default CenteredRows
