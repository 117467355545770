import React from "react"
import PropTypes from "prop-types"
import { Icon } from "../../shared/shared.ui.icon"
import { Tooltip } from "../../shared/shared.ui.tooltip"
import "./index.scss"
import classNames from "classnames"

const ListHeader = ({ headers, tooltipContent, className, ...props }) => {
  if (!headers?.length) return null

  const renderTooltip = tooltipContent => {
    const tooltipId = (Math.random() * 1000).toFixed()

    return (
      <Tooltip
        targetId={`header-tooltip-${tooltipId}`}
        TooltipContent={tooltipContent}
        containerClassName="header-tooltip-container"
      >
        <Icon
          className="header-tooltip-icon"
          icon="question-mark"
          fill="#6324C6"
          viewBox="2 2 16 16"
          width={18}
          height={18}
        />
      </Tooltip>
    )
  }

  return (
    <div className={classNames("ListHeader", className)} {...props}>
      {headers.map(({ label, onClick, tooltipContent }, index) => {
        return (
          <div
            key={index}
            className={classNames("header-container", { clickable: !!onClick })}
            onClick={onClick ? onClick : null}
          >
            <p className="label">{label}</p>
            {tooltipContent && renderTooltip(tooltipContent)}
          </div>
        )
      })}
    </div>
  )
}

const headerShape = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  tooltipContent: PropTypes.node
}

ListHeader.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape(headerShape)).isRequired,
  className: PropTypes.string
}

export default ListHeader
