import { useEffect, useState } from "react"
import gql from "graphql-tag"

const GET_SAMPLE_QUALITY = gql`
  query getSampleQuality($topicId: Int!, $term: String!) {
    getSampleQuality(term: $term, topicId: $topicId) {
      label
      proportionalError
      engagement
    }
  }
`

const useSampleQuality = function({ client, term, topicId }) {
  const [sampleQuality, setSampleQuality] = useState(0)
  const [sampleQualityLabel, setSampleQualityLabel] = useState("")
  const [loading, setLoading] = useState(false)
  const [engagement, setEngagement] = useState(null)

  useEffect(() => {
    if (!topicId.length || !term || topicId.length > 1) return
    if (loading) return
    async function getSampleQuality() {
      setSampleQuality(0)
      setSampleQualityLabel("")
      setLoading(true)
      const query = GET_SAMPLE_QUALITY
      const variables = {
        term,
        topicId: topicId[0]
      }
      try {
        const {
          data: { getSampleQuality }
        } = await client.query({
          query,
          variables
        })
        setLoading(false)
        if (getSampleQuality) {
          setSampleQualityLabel(getSampleQuality.label)
          setSampleQuality(getSampleQuality.proportionalError)
          setEngagement(getSampleQuality.engagement)
        }
      } catch (e) {
        setLoading(false)
        console.error("error getting sample quality >>>>>>>>>>>", e)
      }
    }
    getSampleQuality()
  }, [term, topicId])

  return {
    loadingSampleQuality: loading,
    sampleQuality,
    sampleQualityLabel,
    engagement
  }
}

export default useSampleQuality
