import React, { Fragment } from "react";

export default (
  <>
    <path
      d="M22.6047 1.1383C22.3356 0.988844 22.011 0.958943 21.7173 1.0563L17.0862 2.58987C16.5303 2.77395 16.2365 3.35205 16.4299 3.88123C16.6233 4.41041 17.2303 4.68825 17.7865 4.50595L19.7108 3.86869L15.6963 14.0145L7.00977 11.8552C6.50828 11.6299 5.90925 11.8083 5.63205 12.2653L1.14294 22.4782C0.848669 22.9634 1.02333 23.5838 1.53303 23.8639C1.78791 24.0039 2.07827 24.0325 2.34167 23.9653C2.60508 23.8981 2.84154 23.7352 2.98874 23.4927L6.98732 14.0885L15.6743 16.2481C16.176 16.4736 16.7753 16.2947 17.0523 15.8374L21.5365 4.91612L21.8855 6.75019C21.9903 7.30144 22.5449 7.6673 23.1241 7.56751C23.1537 7.56246 23.1826 7.55632 23.2111 7.54907C23.7411 7.41378 24.0823 6.91177 23.9828 6.38868L23.116 1.83363C23.0606 1.54181 22.8738 1.28776 22.6047 1.1383Z"
      fill="white"
      stroke="white"
      strokeWidth="0.2"
    />
  </>
);
