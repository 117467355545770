import React from "react"
import InfiniteScroll from "react-infinite-scroller"
import { Loading } from "../../shared/shared.ui.loading"

import PostListItem from "./PostListItem"

const PostList = ({
  posts,
  loading,
  networkStatus,
  error,
  loadMore,
  hasMore,
  handlePostSelection,
  isPostSelected
}) => {
  if (error)
    return (
      <div className="PostList">
        <p>Error loading posts :(</p>)
      </div>
    )

  if (loading && networkStatus !== 3)
    return (
      <div className="PostList">
        <Loading />
      </div>
    )

  if (posts?.length)
    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMore}
        loader={
          <div key={Date.now()}>
            <Loading />
          </div>
        }
        hasMore={hasMore}
        initialLoad={false}
        className="PostList"
      >
        {posts?.map(p => (
          <PostListItem
            key={p.postId}
            post={p}
            isSelected={isPostSelected(p)}
            handlePostSelection={() => handlePostSelection(p)}
          />
        ))}
      </InfiniteScroll>
    )

  return (
    <div className="PostList">
      <p>No posts found.</p>
    </div>
  )
}

export default PostList
