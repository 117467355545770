import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import ListHeader from "../../../../components/ListHeader"
import ListItem from "../../../../components/ListItem"

const Subtopics = ({ subtopics, history }) => {
  if (!subtopics) return null
  if (!subtopics?.length) return <p>This topic has no subtopics</p>

  const subtopicsCount = subtopics?.length
  const headers = [
    { label: `Subtopic (${subtopicsCount})` },
    { label: "Upload videos" },
    { label: "Upload date" },
    { label: "Total videos" }
  ]

  return (
    <div className="Subtopics">
      <ListHeader headers={headers} className="subtopics-tab-list-header" />

      {subtopics.map(({ title, subtopicId, term }) => {
        const columns = [
          {
            label: title,
            tooltipContent: term,
            onClick: () => history.push(`/subtopics/edit/${subtopicId}`)
          },
          { label: "---" },
          { label: "---" },
          { label: "---" }
        ]

        return (
          <ListItem
            key={`${title}-${subtopicId}`}
            columns={columns}
            className="subtopics-tab-item"
          />
        )
      })}
    </div>
  )
}

const subtopicShape = {
  subtopicId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired
}

Subtopics.propTypes = {
  subtopics: PropTypes.arrayOf(PropTypes.shape(subtopicShape)).isRequired,
  history: PropTypes.object.isRequired
}

export default withRouter(Subtopics)
