import { useEffect, useState } from "react"
import gql from "graphql-tag"

const SUBTOPICS_QUERY = gql`
  query getSubtopics($topicIds: [Int!]!, $ts: Int) {
    subtopics(topicIds: $topicIds, ts: $ts) {
      name
      sampleQuality {
        label
        proportionalError
      }
      term
      subtopicId
      topic {
        title
        topicId
        category {
          title
          topicGroupId
        }
      }
    }
  }
`

const reduceTsNumber = function(ts) {
  return parseInt(`${ts}`.slice(-5))
}

const useSubtopicList = function({ client, selectedTopicIds, language }) {
  const [subtopics, setSubtopics] = useState([])
  const [loadingSubtopics, setLoadingSubtopics] = useState(false)
  useEffect(() => {
    const query = SUBTOPICS_QUERY
    const variables = {
      topicIds: selectedTopicIds,
      ts: reduceTsNumber(Date.now())
    }
    setLoadingSubtopics(true)
    async function getSubtopics() {
      try {
        const {
          data: { subtopics }
        } = await client.query({
          fetchPolicy: "network-only",
          query,
          variables
        })
        setLoadingSubtopics(false)
        if (subtopics) setSubtopics(subtopics)
      } catch (e) {
        console.error("error getting subtopics >>>>>>>>>>>", e)
        setLoadingSubtopics(false)
      }
    }

    getSubtopics()
  }, [selectedTopicIds, language])

  return {
    subtopics,
    loadingSubtopics
  }
}

export default useSubtopicList
