import { useEffect, useState } from "react"
import { useThrottle } from "react-use"

const normalizeStringAccentuation = str =>
  typeof str === "string"
    ? str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    : ""

function useTextSearch({ items, term, accessorFn, throttle = 500 }) {
  const [filteredItems, setFilteredItems] = useState(items)
  const throttledSearchTerm = useThrottle(term, throttle)

  useEffect(() => {
    const normalizedTerm = normalizeStringAccentuation(throttledSearchTerm)
    const regex = new RegExp(normalizedTerm, "gi")

    const results = items.filter(item => {
      const title = accessorFn(item)

      const normalizedTitle = normalizeStringAccentuation(title)
      return normalizedTitle.match(regex)
    })
    setFilteredItems(results)
  }, [throttledSearchTerm])

  useEffect(() => {
    setFilteredItems(items)
  }, [items])

  return {
    filteredItems
  }
}

export default useTextSearch
