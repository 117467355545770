import { useState, useEffect } from "react"
import gql from "graphql-tag"

const VERIFY_POST = gql`
  mutation(
    $postId: String!
    $provider: String!
    $topicId: Int!
    $approved: Boolean!
  ) {
    verifyPost(
      postId: $postId
      provider: $provider
      topicId: $topicId
      approved: $approved
    )
  }
`

const REMOVE_FROM_TOPIC = gql`
  mutation($postId: String!, $provider: String!, $topicId: Int!) {
    removeFromTopic(postId: $postId, provider: $provider, topicId: $topicId) {
      msg
      status
    }
  }
`

const useTagger = function({ client, topicId }) {
  const [addToTopic, setAddToTopic] = useState()
  const [removeFromTopic, setRemoveFromTopic] = useState()

  useEffect(() => {
    const makeTagFunction = approved => () => ({ postId, provider }) => {
      if (!topicId) return () => {}
      if (postId && provider) {
        const mutation = approved ? VERIFY_POST : REMOVE_FROM_TOPIC
        const variables = approved
          ? { postId, provider, topicId, approved }
          : { postId, provider, topicId }
        client
          .mutate({
            mutation,
            variables
          })
          .then(({ data }) => {
            return data
          })
          .catch(e => {
            console.error("error on tag action handler >>>>>>>>>>>", e)
          })
      }
    }

    setAddToTopic(makeTagFunction(true))

    setRemoveFromTopic(makeTagFunction(false))
  }, [topicId, client])

  return {
    addToTopic,
    removeFromTopic
  }
}

export default useTagger
