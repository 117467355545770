import React, { Component } from "react"
import "./index.scss"
import Icon from "../Icon"
import classNames from "classnames"
import { Button } from "../../shared/shared.ui.button"
import ToastStore from "../../stores/ToastStore"

class Input extends Component {
  constructor(props) {
    super(props)
    this.input = React.createRef()
  }

  componentDidMount() {
    const { getRef } = this.props
    if (getRef) getRef(this.input)
  }

  onChange = e => {
    const { onChange } = this.props
    const { value, name } = e.target

    onChange && onChange(value, name)
  }

  onKeyUp = e => {
    if (e.key === "Enter") this.submit(e.target.value)
  }

  submit = value => {
    const { onSubmit } = this.props
    onSubmit && onSubmit(value)
  }

  clipboardWrite = () => {
    if (window.navigator.clipboard) {
      ToastStore.clearToasts()

      navigator.clipboard.writeText(this.input.current.value).then(
        () =>
          ToastStore.addToast({
            title: "Search Term copied to clipboard.",
            id: "clipboard-succedd"
          }),
        () =>
          ToastStore.addToast({
            title:
              "Oops! Failed to copy Search Term. Search Term is in the console.",
            id: "clipboard-error"
          })
      )
    }
  }

  render() {
    const {
      icon,
      type,
      label,
      placeholder,
      className,
      disabled,
      name,
      copyToClipboard
    } = this.props
    const containerClass = classNames("input-container", className)
    return (
      <div className={containerClass}>
        {label && <span className="input-label">{label}</span>}
        <div
          className={classNames("input-grid", {
            clipboard: copyToClipboard
          })}
        >
          {icon && (
            <div className="grid-element icon-container">
              <Icon icon={icon} />
            </div>
          )}
          <div className="grid-element input-column">
            <input
              type={type}
              placeholder={placeholder}
              onChange={this.onChange}
              onKeyUp={this.onKeyUp}
              ref={this.input}
              disabled={disabled}
              name={name}
            />
          </div>
          {copyToClipboard && (
            <Button
              className="clipboard-button"
              onClick={this.clipboardWrite}
              disabled={!this.input?.current?.value?.length}
            >
              Copy
            </Button>
          )}
        </div>
      </div>
    )
  }
}

Input.defaultProps = {
  type: "text",
  placeholder: "",
  icon: null,
  className: null,
  disabled: false,
  name: null,
  copyToClipboard: false
}

export default Input
