import React, { Fragment } from "react";

export default (
  <>
    <g>
      <path d="M13.344 12.318c-.524 0-1.048 0-1.572-.005-.729-.005-.9-.363-.925-.904V4.027c0-.27.22-.491.49-.491s.49.22.49.491v7.303c.832.005 1.683.005 2.501.005h.989a.492.492 0 010 .983h-.989z" />
      <path d="M11.337 3.327a.702.702 0 00-.697.7v7.392c.013.29.064.567.249.78.185.213.483.32.882.322.525.005 1.049.006 1.573.006H15.317a.703.703 0 00.697-.7c0-.384-.314-.7-.698-.7h-.989c-.75 0-1.527-.001-2.292-.005V4.027c0-.384-.314-.7-.698-.7z" />
      <path d="M11.337 22.555c-2.972 0-5.768-1.16-7.873-3.273A11.12 11.12 0 01.21 11.38c0-2.983 1.155-5.789 3.26-7.902A11.02 11.02 0 0111.337.209c2.971 0 5.767 1.16 7.872 3.273a11.126 11.126 0 013.26 7.902c0 1.332-.23 2.635-.685 3.868a.487.487 0 11-.915-.339 10.15 10.15 0 00.626-3.529C21.49 5.762 16.937 1.192 11.337 1.192c-5.601 0-10.15 4.57-10.15 10.187 0 5.618 4.554 10.188 10.15 10.188.088 0 .176 0 .264-.005a.489.489 0 01.504.477.496.496 0 01-.475.506c-.098.01-.195.01-.293.01z" />
      <path d="M11.337 0a11.235 11.235 0 00-8.015 3.33A11.334 11.334 0 000 11.38c0 3.041 1.178 5.902 3.317 8.048a11.25 11.25 0 008.02 3.335c.095 0 .196-.001.304-.011a.706.706 0 00.672-.717v-.004a.7.7 0 00-.72-.676h-.004c-.08.004-.164.004-.252.004-5.482 0-9.942-4.475-9.942-9.98 0-5.505 4.455-9.98 9.942-9.98 5.488 0 9.945 4.476 9.95 9.986 0 1.19-.206 2.354-.614 3.456a.7.7 0 00.411.895.698.698 0 00.896-.411v-.002c.463-1.256.697-2.583.697-3.938a11.34 11.34 0 00-3.32-8.05A11.248 11.248 0 0011.337 0z" />
      <path d="M22.45 23.794h-8.166c-.485 0-.92-.25-1.165-.674a1.326 1.326 0 010-1.346l4.083-7.102A1.328 1.328 0 0118.367 14c.49 0 .92.25 1.165.673l4.078 7.097c.245.423.245.924 0 1.346a1.33 1.33 0 01-1.16.679zm-4.083-8.812c-.064 0-.22.02-.314.182l-4.087 7.096a.366.366 0 000 .364c.029.054.127.182.313.182h8.166a.35.35 0 00.313-.182.367.367 0 000-.364l-4.083-7.101a.34.34 0 00-.308-.177z" />
      <path d="M18.366 13.79c-.56 0-1.065.295-1.345.779l-4.082 7.102c-.28.484-.28 1.07 0 1.554s.789.777 1.345.777h8.166c.558 0 1.06-.3 1.34-.783a1.54 1.54 0 000-1.554h.001l-4.078-7.096h-.001a1.545 1.545 0 00-1.346-.778zm0 1.4c.032 0 .085-.008.126.068l.002.003 4.084 7.103c.045.08.012.135-.002.16-.007.013-.034.073-.132.073H14.28c-.091 0-.128-.068-.13-.073-.017-.03-.05-.074-.002-.162l4.086-7.094c.047-.081.112-.078.133-.078z" />
      <g>
        <path d="M18.367 20.245a.341.341 0 01-.343-.344v-3.047c0-.191.152-.344.343-.344.19 0 .343.153.343.344v3.047a.344.344 0 01-.343.344z" />
        <path d="M18.366 16.372a.483.483 0 00-.48.482v3.047c0 .265.216.483.48.483.267 0 .483-.22.483-.483v-3.047a.484.484 0 00-.483-.482z" />
      </g>
      <g>
        <path d="M18.318 22.118a.492.492 0 01-.49-.492v-.049c0-.27.22-.491.49-.491s.49.22.49.491v.05c0 .27-.216.49-.49.49z" />
        <path d="M18.317 20.947a.63.63 0 00-.627.63v.049c0 .346.282.63.627.63a.63.63 0 00.63-.63v-.049a.632.632 0 00-.63-.63z" />
      </g>
    </g>
  </>
);
