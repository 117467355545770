import React, { Fragment } from "react";

export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 7V17.4C7 18.4713 8.22299 19.0828 9.08 18.44L12 16.25L14.92 18.44C15.777 19.0828 17 18.4713 17 17.4V7C17 5.89543 16.1046 5 15 5H9C7.89543 5 7 5.89543 7 7Z"
    />
  </>
);
