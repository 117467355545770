import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Button } from "../../shared/shared.ui.button"

import Select from "../../components/Select"
import Input from "../../components/Input"
import "./index.scss"

const SubtopicEditHeader = ({
  handleInputChange,
  handlePostsSearch,
  handleDelete,
  handleSave,
  initialName,
  initialTerm,
  isResetDisabled,
  isSaveDisabled,
  tagList,
  topicTitle,
  categoryTitle,
  subtopicLanguage,
  inputState,
  onReset,
  role
}) => {
  const [nameInputRef, setNameInputRef] = useState(null)
  const [termInputRef, setTermInputRef] = useState(null)

  useEffect(() => {
    if (nameInputRef) nameInputRef.current.value = inputState.name
  }, [nameInputRef])
  useEffect(() => {
    if (termInputRef) termInputRef.current.value = inputState.searchTerm
  }, [termInputRef])

  const resetInputs = () => {
    nameInputRef.current.value = initialName
    termInputRef.current.value = initialTerm

    onReset && onReset()
  }

  return (
    <div className="SubtopicEditHeader">
      <div className="top">
        <div className="inputs">
          <Input
            className="name-input"
            name="name"
            label="Subtopic"
            getRef={inputRef => setNameInputRef(inputRef)}
            onChange={handleInputChange}
            disabled={role.value === "admin"}
          />

          <Select
            value={{ label: subtopicLanguage }}
            label="Language"
            isDisabled
          />
          <Select
            value={{ label: categoryTitle }}
            label="Category"
            isDisabled
          />
          <Select value={{ label: topicTitle }} label="Topic" isDisabled />
        </div>

        <div className="buttons">
          <Button
            className="reset-button"
            appearance="outline"
            disabled={isResetDisabled || role.value === "admin"}
            onClick={resetInputs}
          >
            Reset
          </Button>
          <Button
            className="save-button"
            disabled={isSaveDisabled || role.value === "admin"}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </div>

      <div className="middle">
        <Input
          className="term-input"
          name="searchTerm"
          getRef={inputRef => setTermInputRef(inputRef)}
          onChange={handleInputChange}
          onSubmit={handlePostsSearch}
          icon="search"
          copyToClipboard={role.value === "admin"}
          disabled={role.value === "admin"}
        />
      </div>

      <div className="bottom">
        <div className="tags">
          <span className="tag-title">Related tags</span>
          <p className="tag-list">{tagList ? tagList : "No tags"}</p>
        </div>

        {role.value === "tagger_admin" && (
          <div className="delete-subtopic">
            <Button
              className="delete-button"
              appearance="link"
              icon="trash"
              onClick={handleDelete}
            >
              Delete subtopic
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

const inputStateShape = {
  name: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired
}

const roleShape = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  priority: PropTypes.number.isRequired
}

SubtopicEditHeader.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  handlePostsSearch: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  isResetDisabled: PropTypes.bool.isRequired,
  isSaveDisabled: PropTypes.bool.isRequired,
  tagList: PropTypes.string.isRequired,
  topicTitle: PropTypes.string.isRequired,
  categoryTitle: PropTypes.string.isRequired,
  subtopicLanguage: PropTypes.string.isRequired,
  inputState: PropTypes.shape(inputStateShape).isRequired,
  initialName: PropTypes.string.isRequired,
  initialTerm: PropTypes.string.isRequired,
  role: PropTypes.shape(roleShape).isRequired
}

export default SubtopicEditHeader
