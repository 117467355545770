import React, { Fragment } from "react";

export default (
  <>
    <circle
      cx="11.9894"
      cy="9.25"
      r="2.5"
      stroke="#7B899D"
      strokeWidth="1.5"
      fill="none"
    />
    <rect
      x="2.9375"
      y="4.25"
      width="18.125"
      height="15.5"
      rx="2.75"
      stroke="#7B899D"
      strokeWidth="1.5"
      fill="none"
    />
    <circle cx="17.6875" cy="7.625" r="0.875" fill="#7B899D" />
    <mask id="path-4-inside-1" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8006 19C16.8001 16.7886 14.5744 15.25 11.9894 15.25C9.40434 15.25 7.17867 16.7886 6.17818 19H4.56247C5.64355 15.9414 8.56055 13.75 11.9894 13.75C15.4182 13.75 18.3352 15.9414 19.4163 19H17.8006Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8006 19C16.8001 16.7886 14.5744 15.25 11.9894 15.25C9.40434 15.25 7.17867 16.7886 6.17818 19H4.56247C5.64355 15.9414 8.56055 13.75 11.9894 13.75C15.4182 13.75 18.3352 15.9414 19.4163 19H17.8006Z"
      fill="#7B899D"
    />
    <path
      d="M17.8006 19L15.9784 19.8244L16.5103 21H17.8006V19ZM6.17818 19V21H7.46849L8.00036 19.8244L6.17818 19ZM4.56247 19L2.6768 18.3335L1.73433 21H4.56247V19ZM19.4163 19V21H22.2444L21.3019 18.3335L19.4163 19ZM19.6228 18.1756C18.3104 15.2748 15.3885 13.25 11.9894 13.25V17.25C13.7603 17.25 15.2898 18.3024 15.9784 19.8244L19.6228 18.1756ZM11.9894 13.25C8.59028 13.25 5.66838 15.2748 4.35599 18.1756L8.00036 19.8244C8.68896 18.3024 10.2184 17.25 11.9894 17.25V13.25ZM4.56247 21H6.17818V17H4.56247V21ZM11.9894 11.75C7.68661 11.75 4.03147 14.5008 2.6768 18.3335L6.44815 19.6665C7.25562 17.382 9.43448 15.75 11.9894 15.75V11.75ZM21.3019 18.3335C19.9473 14.5008 16.2921 11.75 11.9894 11.75V15.75C14.5443 15.75 16.7231 17.382 17.5306 19.6665L21.3019 18.3335ZM17.8006 21H19.4163V17H17.8006V21Z"
      fill="#7B899D"
      mask="url(#path-4-inside-1)"
    />
  </>
);
