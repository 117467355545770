import React from "react"
import InfiniteScroll from "react-infinite-scroller"
import PropTypes from "prop-types"
import { Header } from "../../shared/shared.ui.header"
import { Loading } from "../../shared/shared.ui.loading"

import SimplePostCard from "../../components/SimplePostCard"
import "./index.scss"

const SimplePostGrid = ({
  posts,
  postsTotal,
  loading,
  networkStatus,
  error,
  loadMore,
  hasMore
}) => {
  const renderSimplePostGrid = () => {
    if (error) return <p>Error loading posts :(</p>
    if (loading && networkStatus !== 3) return <Loading />
    if (!posts?.length) return <p>No posts found.</p>

    return (
      <>
        <div className="posts-grid-header">
          <Header size="md">
            {postsTotal?.toLocaleString()} video
            {postsTotal !== 1 && "s"}
          </Header>
        </div>

        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          loader={<Loading key={Date.now()} />}
          hasMore={hasMore}
          initialLoad={false}
          className="posts-grid"
        >
          {posts.map(post => (
            <SimplePostCard key={post.postId} {...post} />
          ))}
        </InfiniteScroll>
      </>
    )
  }

  return <section className="SimplePostGrid">{renderSimplePostGrid()}</section>
}

SimplePostGrid.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  postsTotal: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  networkStatus: PropTypes.number.isRequired,
  error: PropTypes.bool,
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired
}

export default SimplePostGrid
