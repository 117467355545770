import React, { Fragment } from "react";

export default (
  <>
    <path d="M4.2129429 14.839847l9.0904961-5.2445097.374607.6493207-9.0904953 5.24451zm.7496324 1.499265l9.0904957-5.24451.374608.649321-9.0904958 5.24451z" />
    <ellipse
      cx="11.312701"
      cy="16.201273"
      rx="1.6331069"
      ry="1.6556334"
      transform="rotate(-30)"
    />
    <path d="M18.099609 2.3203125c-.635931-.2486246-1.352042-.1948997-1.943359.1464844L1.1210938 11.148438c-1.0679526.61658-1.43883475 2.004337-.82226568 3.072265L4.046875 20.712891c.6165554 1.067905 2.0042881 1.438863 3.0722656.822265l15.0351564-8.679687c.59082-.34111.996568-.934968 1.099609-1.609375v-.002L23.974609 6.5c.157441-1.0353383-.428939-2.0503247-1.404297-2.4316406l-4.470703-1.7480469zm-.544921 1.3964844l4.46875 1.7480469c.330346.1291488.522073.4598871.46875.8105468l-.720704 4.7441404c-.03432.225765-.169418.422928-.367187.53711L6.3691406 20.236328c-.3662361.211447-.813977.092742-1.0253906-.273437l-3.7480469-6.492188c-.2114524-.366246-.0908719-.811976.2753907-1.023437L16.90625 3.765625c.197755-.1141695.435781-.1319686.648438-.048828z" />
  </>
);
