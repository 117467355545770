import React, { Component } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import classNames from "classnames";
import { Input } from "../../shared/shared.ui.input";
import { Icon } from "../../shared/shared.ui.icon";

export class GroupMark extends Component {
  label = opts => {
    const { labelKey } = this.props;
    return labelKey ? opts[labelKey] : opts.label;
  };

  value = opts => {
    const { valueKey } = this.props;
    return valueKey ? opts[valueKey] : opts.value;
  };

  onChange = (opts, isActive, e) => {
    e.preventDefault();
    e.stopPropagation();

    const { onChange, type, selecteds } = this.props;
    let value = null;

    if (type === "radio") {
      value = isActive ? null : opts;
    }

    if (type === "checkbox") {
      if (selecteds) {
        value = isActive
          ? selecteds.filter(s => this.value(s) !== this.value(opts))
          : [...selecteds, opts];
      } else {
        value = [opts];
      }
    }

    if (onChange) onChange(value);
  };

  active = opts => {
    const { selecteds } = this.props;

    if (!selecteds) {
      return false;
    }

    if (Array.isArray(selecteds)) {
      return !!(
        selecteds.length &&
        selecteds.filter(s => this.value(s) === this.value(opts)).length > 0
      );
    }
    return this.value(selecteds) === this.value(opts);
  };

  options = () => {
    const { options, filter } = this.props;
    const regEx = new RegExp(filter, "i");

    if (filter) {
      return options.filter(opts => regEx.test(this.label(opts)));
    }

    return options;
  };

  renderLabel({ label, onInputChange }) {
    if (label === "Others") {
      const { groupLabel } = this.props;

      return (
        <Input
          placeholder={label}
          onChange={e => {
            onInputChange(groupLabel, e.target.value);
          }}
        />
      );
    }
    return <span>{label}</span>;
  }

  renderOptions = options =>
    options.map(opts => {
      const value = this.value(opts);
      const label = this.label(opts);
      const active = this.active(opts);
      const containerClass = classNames("option-element", { active });

      return (
        <div
          className={containerClass}
          role="button"
          onClick={e => this.onChange(opts, active, e)}
          key={`${label}-${value}`}
        >
          <div className="mark" />
          <div className="label">{this.renderLabel(opts)}</div>
        </div>
      );
    });

  renderGroup = options => {
    const { groupLabel } = this.props;
    return (
      <>
        <div className="label-container">
          {groupLabel} <Icon icon="arrowDown" />
        </div>
        <div className="mark-list">{this.renderOptions(options)}</div>
      </>
    );
  };

  render() {
    const { groupLabel, type } = this.props;
    const options = this.options();
    const groupClass = classNames(`Insights-ui__group-mark-container ${type}`, {
      hide: !options.length,
    });

    return (
      <div className={groupClass}>
        {groupLabel ? this.renderGroup(options) : this.renderOptions(options)}
      </div>
    );
  }
}

GroupMark.propTypes = {
  type: PropTypes.string,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  filter: PropTypes.string,
  onChange: PropTypes.func,
  selecteds: PropTypes.array,
  options: PropTypes.array,
  groupLabel: PropTypes.string,
};

GroupMark.defaultProps = {
  type: "checkbox",
  labelKey: "label",
  valueKey: "value",
  onChange: () => {},
  selecteds: [],
  options: [],
  filter: "",
  groupLabel: "",
};
