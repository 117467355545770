import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { GroupMark } from "../../shared/shared.ui.group-mark";
import "./index.scss";

class CollapsedElement extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  label = opts => {
    const { labelKey } = this.props;
    return labelKey ? opts[labelKey] : opts.label;
  };

  marks = opts => {
    const { markKey } = this.props;
    return markKey ? opts[markKey] : opts.marks;
  };

  toggle = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  change = value => {
    const { onChange, option, labelKey } = this.props;
    const label = this.label(option);

    if (onChange) {
      onChange({
        [labelKey]: label,
        selecteds: value,
      });
    }
  };

  render() {
    const { open } = this.state;
    const {
      option,
      filter,
      labelKey,
      markKey,
      valueKey,
      selecteds,
      markType,
    } = this.props;
    const marks = this.marks(option);
    const containerClass = classNames("collapsed-element", { open });

    return (
      <div
        className={containerClass}
        onClick={e => {
          e.preventDefault();
          this.toggle();
        }}
        role="button"
      >
        <GroupMark
          groupLabel={this.label(option)}
          filter={filter}
          options={marks}
          type={markType}
          onChange={this.change}
          selecteds={selecteds}
          labelKey={labelKey}
          markKey={markKey}
          valueKey={valueKey}
        />
      </div>
    );
  }
}

CollapsedElement.propTypes = {
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  onChange: PropTypes.func,
  option: PropTypes.object,
  markKey: PropTypes.string,
  filter: PropTypes.string,
  selecteds: PropTypes.array,
  markType: PropTypes.string,
};
CollapsedElement.defaultProps = {
  labelKey: "label",
  valueKey: "value",
  onChange: () => {},
  option: {},
  markKey: "options",
  filter: "",
  selecteds: [],
  markType: "checkbox",
};

export class CollapsedMultiMark extends Component {
  label = opts => {
    const { labelKey } = this.props;
    return labelKey ? opts[labelKey] : opts.label;
  };

  marks = opts => {
    const { markKey } = this.props;
    return markKey ? opts[markKey] : opts.marks;
  };

  change = option => {
    const { onChange, selecteds } = this.props;

    const value = selecteds.filter(v => this.label(v) !== this.label(option));
    if (option.selecteds && option.selecteds.length) value.push(option);

    if (onChange) onChange(value);
  };

  getChildSelecteds = parendLabel => {
    const { selecteds } = this.props;

    return selecteds.reduce((acc, currentValue) => {
      if (parendLabel === this.label(currentValue)) {
        return currentValue.selecteds;
      }
      return acc;
    }, null);
  };

  render() {
    const {
      filter,
      options,
      markType,
      labelKey,
      markKey,
      valueKey,
    } = this.props;

    return (
      <div className="Insights-ui__collapsed-multi-mark-container">
        {options.map(opts => {
          const selectedsToChildren = this.getChildSelecteds(this.label(opts));

          return (
            <CollapsedElement
              filter={filter}
              option={opts}
              selecteds={selectedsToChildren}
              onChange={this.change}
              markType={markType}
              key={this.label(opts)}
              labelKey={labelKey}
              markKey={markKey}
              valueKey={valueKey}
            />
          );
        })}
      </div>
    );
  }
}

CollapsedMultiMark.propTypes = {
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  markKey: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  selecteds: PropTypes.array,
  filter: PropTypes.string,
  markType: PropTypes.string,
};

CollapsedMultiMark.defaultProps = {
  labelKey: "label",
  valueKey: "value",
  markKey: "options",
  options: [],
  onChange: () => {},
  selecteds: [],
  filter: "",
  markType: "checkbox",
};
