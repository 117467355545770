import React, { Fragment } from "react";

export default (
  <>
    <path
      d="M7.42857 19.2594V6.67143H4.17143C2.86857 6.67143 2 7.72043 2 8.55963V11.0772C2 12.336 3.44753 12.9654 4.17134 13.5948L4.17151 19.2594C4.17151 19.2594 4.17143 21.1476 5.8 21.1476C7.42857 21.1476 7.42857 19.2594 7.42857 19.2594Z"
      stroke="#7B899D"
      strokeWidth="1.5"
      fill="none"
    />
    <path
      d="M21 3.37204V16.5001C21 17.8127 19.7662 18.305 19.1493 17.8127C17.1753 16.2374 9.89609 13.2181 7.42858 13.8745C7.42847 11.2491 7.42858 6.70001 7.42858 6.70001C9.89609 6.70001 16.6819 4.68484 18.5325 2.71564C19.7664 1.40271 20.9999 2.05926 21 3.37204Z"
      stroke="#7B899D"
      strokeWidth="1.5"
      fill="none"
    />
  </>
);
