import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {camelCase, kebabCase} from "change-case";
import "./index.scss";
import * as Icons from "./icons";

const iconKeys = Object.keys(Icons);

export const Icon = ({
  icon,
  width,
  height,
  className,
  title,
  viewBoxWidth,
  viewBoxHeight,
  fill,
  ...props
}) => {
  // If icon exists, use it, else use the noicon
  const casedIcon = camelCase(icon);
  const useIcon = iconKeys.indexOf(casedIcon) !== -1 ? casedIcon : "noIcon";
  const componentClass = classNames("Insights-ui__icon", className);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-label={title}
      role="img"
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      style={!!width && !!height ? { width, height } : {}}
      className={componentClass}
      fill={fill}
      {...props}
    >
      {Icons[useIcon]}
    </svg>
  );
};

Icon.propTypes = {
  /** Any CSS class */
  className: PropTypes.string,
  /** Icon name */
  icon: PropTypes.oneOf(
    iconKeys.concat(iconKeys.map((v) => kebabCase(v)))
  ).isRequired,
  /** Icon width */
  width: PropTypes.number,
  /** Icon height */
  height: PropTypes.number,
  /** Text who shows when user leave pointer over the icon */
  title: PropTypes.string,
  /** View box width size */
  viewBoxWidth: PropTypes.number,
  /** View box height size */
  viewBoxHeight: PropTypes.number,
  /** Any CSS color to fill icon */
  fill: PropTypes.string,
};

Icon.defaultProps = {
  width: null,
  height: null,
  title: "",
  className: "",
  viewBoxWidth: 24,
  viewBoxHeight: 24,
  fill: "",
};
