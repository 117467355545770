import gql from "graphql-tag"

export const CREATE_TOPIC_MUTATION = gql`
  mutation createTopicPage_createTopicMutation(
    $title: String!
    $language: String!
    $groupId: Int!
  ) {
    createTopic(title: $title, language: $language, group_id: $groupId) {
      success
      message
    }
  }
`

export const TOPIC_GROUPS_QUERY = gql`
  query createTopicPage_topicGroupsQuery($language: String!) {
    allTopicGroups(language: $language) {
      title
      topicGroupId
    }
  }
`

export const GLOBAL_GROUPS_QUERY = gql`
  query createTopicPage_globalGroupsQuery {
    allGlobalTopicGroups {
      title
      topicGroupId
    }
  }
`
