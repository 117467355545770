import React, { useState, useEffect } from "react"
import "./index.scss"
import { compose, withApollo } from "react-apollo"
import { useLazyQuery, useMutation } from "@apollo/react-hooks"
import Input from "../../components/Input"
import ToastStore from "../../stores/ToastStore"
import PostList from "./PostList"
import PostListHeader from "./PostListHeader"
import ClassificationHeader from "./ClassificationHeader"
import { PAGINATION, LANGUAGE_OPTIONS } from "../../consts"
import {
  CREATOR_QUERY,
  CREATOR_VIDEOS_QUERY,
  ALL_TOPICS_QUERY,
  TAG_POSTS_MUTATION,
  TOPICS_BY_LANGUAGE_QUERY
} from "./query"

const startOfTheDay = new Date(Date.now()).setHours(0, 0, 0, 0)
const oneDayInMl = 24 * 3600 * 1000
const sortByOptions = [
  {
    label: "Views",
    value: "views"
  },
  {
    label: "Likes",
    value: "likes"
  },
  {
    label: "Comments",
    value: "comments"
  },
  {
    label: "Upload date",
    value: "published_at"
  }
]
const dateRangeOptions = [
  {
    label: "All time",
    value: null,
    gte: null
  },
  {
    label: "Last 3 months",
    value: 3,
    gte: startOfTheDay + -90 * oneDayInMl
  },
  {
    label: "Last 6 months",
    value: 6,
    gte: startOfTheDay + -180 * oneDayInMl
  },
  {
    label: "Last 9 months",
    value: 9,
    gte: startOfTheDay + -270 * oneDayInMl
  },
  {
    label: "Last 1 year",
    value: 12,
    gte: startOfTheDay + -365 * oneDayInMl
  }
]

const ClassificationPage = ({ client }) => {
  // local state
  const [searchInputValue, setSearchInputValue] = useState("")
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedPosts, setSelectedPosts] = useState([])
  const [selectedTopic, setSelectedTopic] = useState(null)
  const [selectedLanguage, setSelectedLanguage] = useState(LANGUAGE_OPTIONS[0])
  const [hasMore, setHasMore] = useState(true)
  const [sortBy, setSortBy] = useState(sortByOptions[0])
  const [dateRange, setDateRange] = useState(dateRangeOptions[4])
  const [postVariables, setPostVariables] = useState({})
  const [isSelectAllDisabled, setIsSelectAllDisabled] = useState(true)
  const [isSelectAllActive, setIsSelectAllActive] = useState(false)
  const [topicOptions, setTopicOptions] = useState([])

  // queries and mutations
  const [
    loadAllTopics,
    { loading: allTopicsLoading, error: allTopicsError }
  ] = useLazyQuery(ALL_TOPICS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    client,
    onCompleted: ({ allTopicsWithoutLanguage }) => {
      setTopicOptions(allTopicsWithoutLanguage)
    }
  })
  const [
    loadTopicsByLanguage,
    { loading: topicsByLanguageLoading, error: topicsByLanguageError }
  ] = useLazyQuery(TOPICS_BY_LANGUAGE_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    client,
    onCompleted: ({ allTopics }) => {
      setTopicOptions(allTopics.entities)
    }
  })
  const [
    loadCreator,
    { data: creatorData, loading: creatorLoading, error: creatorError }
  ] = useLazyQuery(CREATOR_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    client
  })
  const [
    loadPosts,
    {
      data: postsData,
      loading: postsLoading,
      error: postsError,
      networkStatus,
      fetchMore,
      refetch
    }
  ] = useLazyQuery(CREATOR_VIDEOS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    client,
    onCompleted: ({ searchPosts: { entities, rows } }) => {
      const postsCount = entities.length
      const limit = PAGINATION.PAGE_SIZE
      const isSelectAllDisabled = postsCount < rows || postsCount === 0
      const hasMore = postsCount >= limit && postsCount < rows

      setHasMore(hasMore)
      setIsSelectAllDisabled(isSelectAllDisabled)
    }
  })
  const [tagPosts] = useMutation(TAG_POSTS_MUTATION, {
    client,
    onError(e) {
      console.error("ClassificationPage @ tagPosts >>>>>", e)

      ToastStore.clearToasts()
      ToastStore.addToast({
        title: "Oops! An error ocurred. Please try again.",
        id: "error"
      })
    },
    onCompleted({
      addListPostsToTopic: addedPosts,
      removeListPostsFromTopic: removedPosts
    }) {
      ToastStore.clearToasts()

      const allApproved =
        addedPosts.every(p => p.approved) && removedPosts.every(p => p.approved)

      if (allApproved) {
        ToastStore.addToast({
          title: "The selected videos were classified.",
          id: "approved"
        })

        setSelectedPosts([])
        refetch({
          lastValue: "",
          lastPostId: ""
        })
      } else {
        ToastStore.addToast({
          title:
            "Oops! We had a problem and the selected videos weren't classified. Please try again.",
          id: "not-approved"
        })

        const notAddedPostIds = addedPosts
          .filter(p => !p.approved)
          .map(p => p.postId)
        const notRemovedPostIds = removedPosts
          .filter(p => !p.approved)
          .map(p => p.postId)

        setSelectedPosts(selectedPosts =>
          selectedPosts.filter(p =>
            [...notAddedPostIds, ...notRemovedPostIds].includes(p.postId)
          )
        )
      }
    }
  })

  // effects
  useEffect(() => {
    // on mount, load topic options
    loadAllTopics()
  }, [])

  useEffect(() => {
    // on search, fetch creator
    try {
      if (!searchTerm || creatorLoading) return

      const { id, provider } = getCreatorVariables(searchTerm)
      const variables = { creatorsInfo: [{ id, provider }] }

      loadCreator({ variables })
      setPostVariables({ id, provider })
      setSelectedPosts([])
    } catch (e) {
      console.error("ClassificationPage @ on search useEffect >>>>>", e)
    }
  }, [searchTerm])

  useEffect(() => {
    // on creator load, fetch posts
    try {
      if (!creatorData) return

      const { id, provider } = postVariables
      const variables = {
        query: {
          creatorId: id,
          providers: [provider],
          publishedAt: { gte: dateRange.gte }
        },
        sort: {
          field: sortBy.value,
          order: "desc"
        }
      }

      loadPosts({ variables })
    } catch (e) {
      console.error("ClassificationPage @ on creator useEffect >>>>>", e)
    }
  }, [creatorData])

  useEffect(() => {
    // on post selection, check if all posts are selected
    if (!postsData?.searchPosts?.rows) return

    if (selectedPosts.length < postsData.searchPosts.rows) {
      setIsSelectAllActive(false)
    } else {
      setIsSelectAllActive(true)
    }
  }, [selectedPosts])

  useEffect(() => {
    // on filters change, refetch
    if (!postsData) return

    const { id, provider } = postVariables
    const variables = {
      query: {
        creatorId: id,
        providers: [provider],
        ...(dateRange.gte && { publishedAt: { gte: dateRange.gte } })
      },
      sort: {
        field: sortBy.value,
        order: "desc"
      }
    }

    setSelectedPosts([])
    refetch(variables)
  }, [dateRange, sortBy])

  useEffect(() => {
    // on topic language select, load correct topic options
    if (selectedLanguage.value === "none") {
      loadAllTopics()
    } else {
      const queryLanguage =
        selectedLanguage.value === "global" ? "all" : selectedLanguage.value

      loadTopicsByLanguage({ variables: { language: queryLanguage } })
    }

    setSelectedTopic(null)
  }, [selectedLanguage])

  // functions
  const handleCreatorSearch = () => {
    setSearchTerm(searchInputValue)
  }

  const getCreatorVariables = searchTerm => {
    const regexSearchTerm = /\S[^/]+\/\w.\/$/
    const parseSearchTerm = regexSearchTerm.exec(searchTerm)
    const regexParamsSearchTerm = /([^/]+\S)\/(\w\w)/
    const paramsSearchTerm = regexParamsSearchTerm.exec(parseSearchTerm)

    const id = paramsSearchTerm?.[1]
    const provider = paramsSearchTerm?.[2]

    return { id, provider }
  }

  const handlePostSelection = post => {
    if (isPostSelected(post)) {
      const postIndex = selectedPosts.findIndex(p => p.postId === post.postId)

      setSelectedPosts(p => [
        ...p.slice(0, postIndex),
        ...p.slice(postIndex + 1)
      ])
    } else {
      setSelectedPosts(p => [...p, post])
    }
  }

  const isPostSelected = post => {
    return !!selectedPosts.find(p => p.postId === post.postId)
  }

  const handleSelectAll = () => {
    if (isSelectAllDisabled || !postsData?.searchPosts?.entities) return

    if (isSelectAllActive) {
      setSelectedPosts([])
    } else {
      setSelectedPosts(postsData.searchPosts.entities)
    }
  }

  const loadMore = async () => {
    if (postsLoading || postsError || !hasMore) return

    const posts = postsData?.searchPosts?.entities
    const lastPost = posts[posts?.length - 1]
    const sortField =
      sortBy.value === "published_at" ? "publishedAt" : sortBy.value
    const lastValue =
      sortField === "publishedAt"
        ? Date.parse(lastPost?.[sortField])?.toString()
        : lastPost?.[sortField]?.toString()
    const lastPostId = lastPost?.postId
    const variables = {
      lastValue,
      lastPostId
    }

    try {
      await fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.searchPosts?.entities?.length) {
            return prev
          }

          if (
            fetchMoreResult.searchPosts.entities.length < PAGINATION.PAGE_SIZE
          ) {
            setHasMore(false)
            setIsSelectAllDisabled(false)
          }

          return Object.assign({}, prev, {
            searchPosts: {
              ...prev.searchPosts,
              entities: [
                ...prev.searchPosts.entities,
                ...fetchMoreResult.searchPosts.entities
              ]
            }
          })
        }
      })
    } catch (e) {
      console.error("ClassificationPage @ loadMore >>>>>", e)
    }
  }

  const handleDateRangeChange = dateRange => {
    setDateRange(dateRange)
  }

  const handleSortByChange = sortBy => {
    setSortBy(sortBy)
  }

  const handleSave = () => {
    if (!selectedTopic?.value || !postVariables?.provider) return

    const { provider } = postVariables
    const topicId = selectedTopic?.value

    const removePostIds = selectedPosts
      ?.filter(p => {
        const topicIds = p.topics.map(t => t.topicId)
        return topicIds.includes(topicId)
      })
      ?.map(p => p.postId)

    const addPostIds = selectedPosts
      ?.filter(p => !removePostIds.includes(p.postId))
      ?.map(p => p.postId)

    const variables = {
      addPostIds,
      removePostIds,
      provider,
      topicId
    }

    try {
      tagPosts({ variables })
    } catch (e) {
      throw new Error("ClassificationPage @ handleSave >>>>>", e)
    }
  }

  const handleCancel = () => {
    setSelectedPosts([])
    setSelectedTopic(null)
  }

  // consts used in render
  const creator = creatorData?.creators?.[0]
  const posts = postsData?.searchPosts?.entities
  const postsCount = postsData?.searchPosts?.rows

  return (
    <div className="ClassificationPage">
      <div className="search-container">
        <Input
          icon="search"
          className="search-input"
          placeholder="Search for a creator url"
          onChange={setSearchInputValue}
          value={searchInputValue}
          onSubmit={handleCreatorSearch}
          disabled={creatorLoading}
        />
      </div>

      <ClassificationHeader
        creator={{
          data: creator,
          loading: creatorLoading,
          error: creatorError
        }}
        topics={{
          data: topicOptions,
          loading: allTopicsLoading || topicsByLanguageLoading,
          error: allTopicsError || topicsByLanguageError
        }}
        selectedLanguage={selectedLanguage}
        languageOptions={LANGUAGE_OPTIONS}
        setSelectedLanguage={setSelectedLanguage}
        selectedTopic={selectedTopic}
        selectedPosts={selectedPosts}
        handleCancel={handleCancel}
        handleSave={handleSave}
        setSelectedTopic={setSelectedTopic}
      />

      <div className="post-list-container">
        {creator && (
          <>
            <PostListHeader
              postsCount={postsCount}
              dateRange={dateRange}
              dateRangeOptions={dateRangeOptions}
              handleDateRangeChange={handleDateRangeChange}
              sortBy={sortBy}
              sortByOptions={sortByOptions}
              handleSortByChange={handleSortByChange}
              isSelectAllDisabled={isSelectAllDisabled}
              isSelectAllActive={isSelectAllActive}
              handleSelectAll={handleSelectAll}
            />

            <PostList
              posts={posts}
              loading={postsLoading}
              networkStatus={networkStatus}
              error={postsError}
              loadMore={loadMore}
              hasMore={hasMore}
              handlePostSelection={handlePostSelection}
              isPostSelected={isPostSelected}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default compose(withApollo)(ClassificationPage)
