import React, { Fragment } from "react";

export default (
  <>
    <radialGradient
      id="loading-gradient-a"
      cx="12"
      cy="12"
      r="12"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset=".9145" stopColor="#e3e3e3" />
      <stop offset="1" stopColor="#bbb" />
    </radialGradient>
    <path
      fill="url(#loading-gradient-a)"
      d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm0 22.1C6.4 22.1 1.9 17.6 1.9 12S6.4 1.9 12 1.9 22.1 6.4 22.1 12 17.6 22.1 12 22.1z"
    />
    <linearGradient
      id="loading-gradient-b"
      x1="10.1716"
      x2="23.7415"
      y1="6.4839"
      y2="6.4839"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#7cd6bb" />
      <stop offset="1" stopColor="#007992" />
    </linearGradient>
    <path
      fill="url(#loading-gradient-b)"
      d="M12 .2c-.4 0-.8.3-.8.8s.3.8.8.8c5.7 0 10.3 4.6 10.3 10.3 0 .4.3.8.8.8.4 0 .8-.3.8-.8C23.8 5.5 18.5.2 12 .2z"
    />
  </>
);
