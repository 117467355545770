import Providers from "./providers"
import Urls from "./urls"
import Roles from "./roles"
import Fields from "./fields"
import Countries from "./countries"

export default {
  Providers,
  Urls,
  Roles,
  Fields,
  Countries
}
