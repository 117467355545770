import React from "react"
import PropTypes from "prop-types"
import { ApolloProvider } from "react-apollo"
import { ApolloClient } from "apollo-client"
import { ApolloLink } from "apollo-link"
import { setContext } from "apollo-link-context"
import { onError } from "apollo-link-error"
import { createUploadLink } from "apollo-upload-client"
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from "apollo-cache-inmemory"
import introspectionQueryResultData from "./fragmentTypes.json"
import { keycloak } from "./utils/auth"
import { REALMS } from "./consts"
import getEnvFromLocation from "./utils/getEnvFromLocation"

const regExValidation = {
  jwt: /jwt/,
  UNAUTHORIZED: /UNAUTHORIZED/
}

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

const env = getEnvFromLocation()

const GraphQLEndPoint = {
  local: "https://dev.tagger.insights.winnin.com/graphql",
  development: "https://dev.tagger.insights.winnin.com/graphql",
  staging: "https://staging.tagger.insights.winnin.com/graphql",
  production: "https://tagger.winnin.com/graphql",
  test: "/graphql"
}

const httpLink = createUploadLink({ uri: GraphQLEndPoint[env] })
// const httpLink = createUploadLink({ uri: "http://localhost:8081/graphql" })

const authLink = setContext(async (operation, { headers }) => {
  const accessToken = keycloak.token

  return {
    headers: {
      ...headers,
      ...(accessToken && { authorization: `${accessToken}` }),
      realm: REALMS[env]
    }
  }
})

const ErrorLink = onError(({ graphQLErrors, networkError }) => {
  console.error("ErrorLink")

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`
      )

      if (
        regExValidation.jwt.test(message) ||
        regExValidation.UNAUTHORIZED.test(message)
      ) {
        keycloak.logout()
      }
    })
  }

  if (networkError) console.error(`[Network error]: ${networkError}`)
})

const link = ApolloLink.from([authLink, ErrorLink, httpLink])

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache({ fragmentMatcher })
})

const AplProvider = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
)

AplProvider.propTypes = {
  children: PropTypes.node
}

export default AplProvider
