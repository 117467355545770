import React, { Fragment } from "react";

export default (
  <>
    <ellipse cx="12" cy="12.1" fill="#ffda6b" rx="12" ry="12" />
    <path d="M12.1 12.6h-8s.2 6.9 7.8 7.3h.2c7.6-.4 7.8-7.3 7.8-7.3h-7.8z" />
    <path
      fill="#f05266"
      d="M5 15.9s2.8-1.3 6.9-1.3c2.1 0 4.5.2 7 1.3 0 0-1.7 4.2-7 4.2S5 15.9 5 15.9z"
    />
    <path d="M4.8 10c-.1 0-.2 0-.2-.2-.1-.1 0-.3.2-.4l2.7-1.3-2.8-1.2c-.2-.1-.2-.3-.2-.4.1-.2.3-.2.4-.1L8.2 8c.1 0 .2.1.2.2s-.1.2-.2.3L4.9 10h-.1zm14.4 0h-.1l-3.3-1.5c-.1-.1-.2-.1-.2-.3s0-.2.1-.2L19 6.4c.1-.1.3 0 .4.1.1.2 0 .3-.1.4l-2.8 1.3 2.7 1.3c.1.1.2.2.1.4.1.1 0 .1-.1.1z" />
  </>
);
