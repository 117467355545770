import React, { Fragment } from "react";

export default (
  <>
    <g fillRule="evenodd" clipRule="evenodd">
      <path d="M13.45 6.91c0 2.81-2.11 5.09-4.72 5.09-2.6 0-4.7-2.28-4.7-5.09 0-2.8 2.1-5.09 4.7-5.09s4.72 2.28 4.72 5.1zm-1.89 0c0 1.69-1.26 3.05-2.83 3.05-1.56 0-2.82-1.36-2.82-3.05 0-1.68 1.26-3.05 2.82-3.05 1.57 0 2.83 1.37 2.83 3.05z" />
      <path d="M8.73 1.57c-2.75 0-4.96 2.4-4.96 5.34s2.21 5.35 4.96 5.35c2.76 0 4.97-2.41 4.97-5.35 0-2.94-2.21-5.34-4.97-5.34zm0 2.54c1.42 0 2.58 1.24 2.58 2.8 0 1.56-1.16 2.8-2.58 2.8-1.4 0-2.57-1.24-2.57-2.8 0-1.56 1.16-2.8 2.57-2.8z" />
    </g>
    <g fillRule="evenodd" clipRule="evenodd">
      <path d="M16.65 17.13v.01c1.37 2.02.15 5.04-2.63 5.04H3.46a3.2 3.2 0 01-2.88-1.83 3.13 3.13 0 01.3-3.29 9.78 9.78 0 017.86-4.04c3.3 0 6.11 1.54 7.9 4.1zm-1.57 1.02c.66.97 0 2.2-1.06 2.2H3.46c-1.06 0-1.72-1.35-1.06-2.2a7.88 7.88 0 016.34-3.3 7.57 7.57 0 016.34 3.3z" />
      <path d="M8.74 12.76c-3.42 0-6.27 1.75-8.06 4.15a3.4 3.4 0 00-.33 3.55c.5 1 1.58 1.97 3.11 1.97h10.56c1.48 0 2.58-.82 3.1-1.9a3.56 3.56 0 00-.25-3.53l-.01-.01-.01-.02a9.73 9.73 0 00-8.1-4.2zm0 2.34a7.3 7.3 0 016.13 3.2c.56.82.01 1.79-.85 1.79H3.46c-.84 0-1.39-1.1-.85-1.79a7.62 7.62 0 016.13-3.2z" />
    </g>
    <path d="M19.76 6.91a1.19 1.19 0 00-1.19 1.19v6.1a1.19 1.19 0 001.19 1.2 1.19 1.19 0 001.19-1.2V8.1a1.19 1.19 0 00-1.19-1.19z" />
    <path d="M16.7 9.97a1.19 1.19 0 00-1.18 1.18 1.19 1.19 0 001.19 1.19h6.1A1.19 1.19 0 0024 11.15a1.19 1.19 0 00-1.19-1.18h-6.1z" />
  </>
);
