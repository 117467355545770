import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useQuery } from "@apollo/react-hooks"
import { withApollo, compose } from "react-apollo"
import { withRouter } from "react-router-dom"

import Loading from "../../../../components/Loading"
import QueryCard from "../../../../components/QueryCard"
import { QUERIES_LIST_QUERY } from "./query"
import "./index.scss"

const Approval = ({ client, location }) => {
  // queries and mutations
  const { data, loading, error, refetch } = useQuery(QUERIES_LIST_QUERY, {
    client,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only"
  })

  // effects
  useEffect(() => {
    if (location?.state?.refetch) {
      refetch()
    }
  }, [location, refetch])

  // rendering
  if (loading) return <Loading />
  if (error)
    return (
      <p>
        Oops! Erro loading terms and queries. Please, refresh the page or try
        again later.
      </p>
    )

  return (
    <div className="Approval">
      {data?.listTopicQueryClassifiers?.map(queryObj => (
        <QueryCard
          key={queryObj.id}
          queryObj={queryObj}
          client={client}
          onUpdateCallback={refetch}
        />
      ))}
    </div>
  )
}

Approval.propTypes = {
  client: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default compose(withApollo)(withRouter(Approval))
