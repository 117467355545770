import React, { Fragment } from "react";

export default (
  <>
    <path
      id="restore-icon"
      d="M15.714 10.571H2v2.286h13.714zm0-4.571H2v2.286h13.714zM2 17.429h9.143v-2.286H2zm22.286-5.143L26 14l-7.989 8-5.154-5.143 1.714-1.714 3.44 3.429z"
      transform="translate(0 0)"
    />
  </>
);
