import React, { useState, useEffect } from "react"
import { compose, withApollo } from "react-apollo"
import InfiniteScroll from "react-infinite-scroller"
import { Loading } from "../../shared/shared.ui.loading"
import { Header } from "../../shared/shared.ui.header"
import { Icon } from "../../shared/shared.ui.icon"

import AppHeader from "../../components/AppHeader"
import Thumbnail from "../../components/Thumbnail"
import searchCircle from "../../assets/search-circle.svg"
import { PROVIDERS } from "../../consts"
import Select from "../../components/Select"
import Input from "../../components/Input"
import {
  useCategoriesAndTopicsSelector,
  useVideoSearch,
  useTagger
} from "../../hooks"
import { ReactComponent as CheckMark } from "../../assets/img/check.svg"
import { ReactComponent as XMark } from "../../assets/img/x.svg"
import "./index.scss"

const START_OF_THE_DAY = new Date().setHours(0, 0, 0, 0)

const countriesOptions = [
  {
    label: "Any Country",
    value: null
  },
  {
    label: "Colombia",
    value: "CO"
  },
  {
    label: "Brazil",
    value: "BR"
  },
  {
    label: "United States",
    value: "US"
  },
  {
    label: "Mexico",
    value: "MX"
  },
  {
    label: "United Kingdom",
    value: "GB"
  }
]

const PROVIDERS_OPTIONS = [
  {
    label: "Instagram",
    value: "in"
  },
  {
    label: "Youtube",
    value: "yt"
  },
  {
    label: "Facebook",
    value: "fb"
  }
]

const SORTBY_OPTIONS = [
  {
    label: "Best Match",
    value: null
  },
  {
    label: "Engagement",
    value: "engagement"
  },
  {
    label: "Most Viewed",
    value: "views"
  },
  {
    label: "Most Recent",
    value: "published_at"
  }
]

const DETECTED_LANGUAGE_OPTIONS = [
  { value: null, label: "All" },
  { value: "por", label: "Portuguese" },
  { value: "eng", label: "English" },
  { value: "spa", label: "Spanish" }
]

const DATERANGE_OPTIONS = [
  {
    label: "All time",
    value: "",
    key: "-",
    filterName: "Period"
  },
  {
    label: "Last 7 days",
    value: "7d",
    key: "0",
    filterName: "Period",
    rangeQuery: { gte: START_OF_THE_DAY + -7 * 24 * 3600 * 1000 }
  },
  {
    label: "Last 30 days",
    value: 1,
    key: "1",
    filterName: "Period",
    rangeQuery: { gte: START_OF_THE_DAY + -30 * 24 * 3600 * 1000 }
  },
  {
    label: "Last 3 months",
    value: 3,
    key: "3",
    filterName: "Period",
    rangeQuery: { gte: START_OF_THE_DAY + -90 * 24 * 3600 * 1000 }
  },
  {
    label: "Last 6 months",
    value: 6,
    key: "6",
    filterName: "Period",
    rangeQuery: { gte: START_OF_THE_DAY + -180 * 24 * 3600 * 1000 }
  },
  {
    label: "Last 9 months",
    value: 9,
    key: "9",
    filterName: "Period",
    rangeQuery: { gte: START_OF_THE_DAY + -270 * 24 * 3600 * 1000 }
  },
  {
    label: "Last 1 Year",
    value: 12,
    key: "12",
    filterName: "Period",
    rangeQuery: { gte: START_OF_THE_DAY + -365 * 24 * 3600 * 1000 }
  }
]

const VideoCard = React.memo(
  ({ video, addToTopic, removeFromTopic, topicId, onTagCallback }) => {
    const { title, thumbnail, tags, postUrl, postId, provider, creator } = video
    const [visible, setVisible] = useState(true)
    const isDisabled = !topicId
    const providerName = (PROVIDERS[provider] || {}).NAME || "Facebook"
    const url =
      postUrl ||
      (provider === "yt" ? `https://www.youtube.com/watch?v=${postId}` : null)

    if (!visible || !video) return null
    const handleConfirm = async () => {
      onTagCallback && onTagCallback(true)
      setVisible(false)
      await addToTopic({
        postId,
        provider
      })
    }
    const handleCancel = async () => {
      onTagCallback && onTagCallback(false)
      setVisible(false)
      await removeFromTopic({
        postId,
        provider
      })
    }

    const renderCreator = creator => {
      if (!creator) return null

      return (
        <div className="post-creator">
          <Thumbnail
            className="post-creator-thumbnail"
            src={creator?.thumbnail || ""}
            alt={creator?.title || ""}
          />
          <p className="post-creator-title">{creator?.title}</p>
        </div>
      )
    }

    return (
      <div className="VideoCard">
        <div
          style={{ backgroundImage: `url('${thumbnail}')` }}
          className="thumbnail"
        />
        <Icon
          icon={providerName.toLowerCase()}
          className={`icon ${provider}`}
        />
        <div className="content-info">
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className={`title ${isDisabled ? "tooltipped" : ""}`}
            aria-label={title}
          >
            {title}
          </a>

          {renderCreator(creator)}

          <div className="tags">
            {tags.map((t, i) => (
              <div key={`${postUrl}-${i}-${t}`} className="tag">
                {t}
              </div>
            ))}
          </div>
        </div>
        <div className="card-controls">
          <div
            aria-label={isDisabled ? "select a topic" : "cancel"}
            onClick={isDisabled ? () => {} : handleCancel}
            className={`button cancel ${isDisabled ? "disabled" : ""}`}
          >
            <XMark />
          </div>

          <div className="divider" />

          <div
            aria-label={isDisabled ? "select a topic" : "confirm"}
            onClick={isDisabled ? () => {} : handleConfirm}
            className={`button confirm ${isDisabled ? "disabled" : ""}`}
          >
            <CheckMark />
          </div>
        </div>
      </div>
    )
  }
)

const VideoListWithBlankState = React.memo(
  ({
    rows,
    videos,
    handleLoadMore,
    hasMore,
    loadingVideos,
    noResults,
    addToTopic,
    removeFromTopic,
    topicId,
    onTagCallback
  }) => {
    if (noResults)
      return (
        <div className="search-blank-state">
          <img src={searchCircle} alt="Search for videos" />
          <Header>No Results Found :(</Header>
        </div>
      )
    return (
      <>
        <div className="count">
          <Header size="md">{rows} Videos</Header>
        </div>
        <div className="search-video-grid-container">
          {loadingVideos && !videos.length ? (
            <Loading />
          ) : (
            <InfiniteScroll
              pageStart={0}
              loadMore={handleLoadMore}
              loader={
                <div key={Date.now()} className="loading-videos-grid">
                  <Loading />
                </div>
              }
              hasMore={hasMore}
              initialLoad={false}
              className="search-videos-grid"
            >
              {videos.map((video, i) => (
                <VideoCard
                  key={`${video.postId}${i}`}
                  index={`${video.postId}${i}`}
                  video={video}
                  addToTopic={addToTopic}
                  removeFromTopic={removeFromTopic}
                  topicId={topicId}
                  onTagCallback={onTagCallback}
                />
              ))}
            </InfiniteScroll>
          )}
        </div>
      </>
    )
  }
)

const SearchPage = ({ client }) => {
  const limit = 32
  const [searchInputValue, setSearchInputValue] = useState("")
  // searchTerm is only changed when search action is commited
  const [searchTerm, setSearchTerm] = useState("")

  const [providers, setProviders] = useState(PROVIDERS_OPTIONS.slice(0, 2))
  const [country, setCountries] = useState(countriesOptions[0])
  const [sortBy, setSortBy] = useState(SORTBY_OPTIONS[0])
  const [detectedLanguage, setDetectedLanguage] = useState(
    DETECTED_LANGUAGE_OPTIONS[0]
  )

  const [dateRange, setDateRange] = useState(DATERANGE_OPTIONS[6])

  const [positiveCounter, setPositiveCounter] = useState(0)
  const increasePositive = () => setPositiveCounter(positiveCounter + 1)
  const [negativeCounter, setNegativeCounter] = useState(0)
  const increaseNegative = () => setNegativeCounter(negativeCounter + 1)

  const onTagCallback = value =>
    value ? increasePositive() : increaseNegative()
  const resetAllCounters = () => {
    setNegativeCounter(0)
    setPositiveCounter(0)
  }

  const [offset, setOffset] = useState(0)

  const {
    language,
    setLanguage,
    languageOptions,
    loadingCategories,
    loadingTopics,
    categoryOptions,
    topicOptions,
    selectedCategory,
    selectCategory,
    selectedTopic,
    selectTopic,
    selectedTopicIds
  } = useCategoriesAndTopicsSelector({
    client
  })

  useEffect(() => {
    const detectedLanguage =
      language.value === "global" ? DETECTED_LANGUAGE_OPTIONS[0] : language
    setDetectedLanguage(detectedLanguage)
  }, [language])

  const { videos, rows, loadingVideos, hasMore, noResults } = useVideoSearch({
    client,
    minEngagement: -1,
    searchTerm,
    providers,
    country: country.value,
    publishedAt: dateRange.rangeQuery,
    limit,
    offset,
    detectedLanguage: detectedLanguage.value,
    notInTopicId: selectedTopicIds[0],
    sortBy
  })

  const handleLoadMore = () => {
    if (loadingVideos) return
    setOffset(videos.length)
  }

  const { addToTopic, removeFromTopic } = useTagger({
    client,
    topicId: selectedTopicIds[0]
  })

  const shouldRenderBlankState = !searchTerm && !videos.length

  return (
    <div className="Search-Page">
      <AppHeader />

      <div className="status-bar">
        <div className="controls">
          <div className="filters">
            <Select
              isMulti
              className="provider-filter-multiselect"
              label="Providers"
              options={PROVIDERS_OPTIONS}
              value={providers}
              onChange={setProviders}
            />
            <Select
              label="Topics Language"
              options={languageOptions}
              value={language}
              onChange={setLanguage}
            />
            <Select
              label="Categories"
              value={selectedCategory}
              options={categoryOptions}
              onChange={selectCategory}
              isDisabled={loadingCategories}
            />
            <Select
              label="Topic"
              options={topicOptions}
              isDisabled={loadingTopics}
              value={selectedTopic}
              onChange={selectTopic}
            />
            <Select
              label="Sort By"
              options={SORTBY_OPTIONS}
              value={sortBy}
              onChange={setSortBy}
            />
            <Select
              label="Date Range"
              options={DATERANGE_OPTIONS}
              value={dateRange}
              onChange={setDateRange}
            />
            <Select
              label="Video Language"
              options={DETECTED_LANGUAGE_OPTIONS}
              value={detectedLanguage}
              onChange={setDetectedLanguage}
            />
            <Select
              label="Creator Country"
              options={countriesOptions}
              value={country}
              onChange={setCountries}
            />
            <div className="counters-container">
              <div className="counter">
                <span>{positiveCounter + negativeCounter}</span>
                Total
              </div>
              <div className="counter positive">
                <span>{positiveCounter}</span>
                Added
              </div>
              <div className="counter negative">
                <span>{negativeCounter}</span>
                Ignored
              </div>
              <button onClick={resetAllCounters} className="reset-counter">
                reset counter
              </button>
            </div>
          </div>
        </div>
        <div className="input-term">
          <Input
            icon="search"
            placeholder={`You can use " ", (  ), OR, AND or NOT to improve your search`}
            onChange={setSearchInputValue}
            value={searchInputValue}
            onSubmit={() => setSearchTerm(searchInputValue)}
          />
        </div>
      </div>

      <div className="page-results">
        {shouldRenderBlankState ? (
          <div className="search-blank-state">
            <img src={searchCircle} alt="Search for videos" />
            <Header>Start by typing a keyword</Header>
          </div>
        ) : (
          <VideoListWithBlankState
            rows={rows}
            videos={videos}
            handleLoadMore={handleLoadMore}
            hasMore={hasMore}
            loadingVideos={loadingVideos}
            noResults={noResults}
            addToTopic={addToTopic}
            removeFromTopic={removeFromTopic}
            topicId={
              selectedTopicIds.length === 1 ? selectedTopicIds[0] : false
            }
            onTagCallback={onTagCallback}
          />
        )}
        {!hasMore && <Header size="lg">End of results</Header>}
      </div>
    </div>
  )
}

export default compose(withApollo)(SearchPage)
