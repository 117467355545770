export default [
  {
    label: "Admin",
    value: "tagger_admin",
    priority: 400
  },
  {
    label: "Editor",
    value: "tagger_editor",
    priority: 350
  },
  {
    label: "Viewer",
    value: "admin",
    priority: 300
  }
]
