import React, { useState, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { useLazyQuery, useMutation } from "@apollo/react-hooks"
import { compose, withApollo } from "react-apollo"
import { Loading } from "../../shared/shared.ui.loading"
import { Button } from "../../shared/shared.ui.button"

import AppHeader from "../../components/AppHeader"
import Select from "../../components/Select"
import Input from "../../components/Input"
import ToastStore from "../../stores/ToastStore"
import { LANGUAGE_OPTIONS } from "../../consts"
import {
  TOPIC_GROUPS_QUERY,
  GLOBAL_GROUPS_QUERY,
  CREATE_TOPIC_MUTATION
} from "./query"
import * as style from "./index.module.scss"

const TopicCreatePage = ({ client, history }) => {
  // consts
  const languageOptions = LANGUAGE_OPTIONS
  const defaultLanguage = languageOptions[0]
  const defaultCategory = {
    label: "All",
    value: null
  }

  // state
  const [language, setLanguage] = useState(defaultLanguage)
  const [category, setCategory] = useState(defaultCategory)
  const [title, setTitle] = useState("")

  // queries and mutations
  const [
    loadTopicGroups,
    { data: topicGroups, loading: topicGroupsLoading, error: topicGroupsError }
  ] = useLazyQuery(TOPIC_GROUPS_QUERY, { client })
  const [
    loadGlobalGroups,
    {
      data: globalGroups,
      loading: globalGroupsLoading,
      error: globalGroupsError
    }
  ] = useLazyQuery(GLOBAL_GROUPS_QUERY, { client })
  const [createTopic, { loading: createTopicLoading }] = useMutation(
    CREATE_TOPIC_MUTATION,
    {
      client,
      onCompleted: useCallback(
        ({ createTopic: { success, message } }) => {
          if (success) {
            history.push("/topics")
          } else {
            ToastStore.clearToasts()
            ToastStore.addToast({
              title: "Oops! Error creating topic.",
              id: "on-completed-error"
            })

            console.error(`TopicCreatePage @ createTopic >>>>> ${message}`)
          }
        },
        [history]
      ),
      onError: useCallback(error => {
        ToastStore.clearToasts()
        ToastStore.addToast({
          title: "Oops! Error creating topic.",
          id: "on-error-error"
        })

        console.error(`TopicCreatePage @ createTopic >>>>> ${error}`)
      }, [])
    }
  )

  // effects
  useEffect(() => {
    if (!language?.value) return

    switch (language.value) {
      case "none":
        setCategory(defaultCategory)
        break

      case "global":
        loadGlobalGroups()
        break

      default:
        loadTopicGroups({ variables: { language: language.value } })
        break
    }

    setCategory(defaultCategory)
  }, [language])

  // functions
  const getCategoryOptions = () => {
    const options =
      language.value === "global"
        ? globalGroups?.allGlobalTopicGroups
        : topicGroups?.allTopicGroups

    if (!options?.length) {
      return [defaultCategory]
    }

    return [
      defaultCategory,
      ...options?.map(({ title, topicGroupId }) => ({
        label: title,
        value: topicGroupId
      }))
    ]
  }
  const handleSave = () => {
    const variables = {
      title,
      language: language.value,
      groupId: category.value
    }

    createTopic({ variables })
  }

  // rendering
  const isCategoryDisabled = language.value === "none"
  const isSaveDisabled =
    language.value === "none" || !category.value || !title.length
  const categoryOptions = getCategoryOptions()

  return (
    <>
      <AppHeader />
      <div className={`TopicCreatePage ${style.root}`}>
        <div className={style.pageHeader}>
          <div className={style.inputs}>
            <Select
              label="Language"
              options={languageOptions}
              value={language}
              onChange={setLanguage}
            />
            <Select
              label="Category"
              options={categoryOptions}
              value={category}
              onChange={setCategory}
              isLoading={topicGroupsLoading || globalGroupsLoading}
              isDisabled={isCategoryDisabled}
            />
            <Input
              placeholder="Give this topic a name"
              label="Topic title"
              value={title}
              onChange={setTitle}
            />
          </div>

          <div className={style.save}>
            <Button disabled={isSaveDisabled} onClick={handleSave}>
              Save
            </Button>
          </div>
        </div>

        {(topicGroupsError || globalGroupsError) && (
          <div className={style.error}>
            Error loading category options. Please, try again.
          </div>
        )}

        {createTopicLoading && (
          <div className={style.loading}>
            <Loading />
          </div>
        )}
      </div>
    </>
  )
}

TopicCreatePage.propTypes = {
  client: PropTypes.object,
  history: PropTypes.object
}

export default compose(withApollo)(TopicCreatePage)
