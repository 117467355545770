const tagList = JSON.stringify({
  "top 10": "Top 10s",
  "10 most": "Top 10s",
  "top 10 list": "Top 10s",
  "10 melhores": "Top 10s",
  "top ten": "Top 10s",
  "best top 10": "Top 10s",
  "top 10 most viewed": "Top 10s",
  compilation: "Compilations",
  compilations: "Compilations",
  mixtape: "Compilations",
  "best of": "Compilations",
  "melhores momentos": "Compilations",
  "fail compilation": "Compilations",
  "video compilation": "Compilations",
  comp: "Compilations",
  Listicle: "Listicle",
  Listicles: "Listicle",
  playlist: "Listicle",
  "the listicle": "Listicle",
  "listicle video": "Listicle",
  "listicles examples": "Listicle",
  "dance tutorial": "Explainer",
  dancetutorial: "Explainer",
  dicas: "Explainer",
  curiosidades: "Explainer",
  Explainer: "Explainer",
  "explainer video": "Explainer",
  "explainer videos": "Explainer",
  "video explainer": "Explainer",
  explain: "Explainer",
  "animated explainer videos": "Explainer",
  "30 second explainer videos": "Explainer",
  diy: "How - to",
  "do it yourself": "How - to",
  "how to": "How - to",
  tutorial: "How - to",
  howto: "How - to",
  "how to make": "How - to",
  "how-to": "How - to",
  "como fazer": "How - to",
  "faça você mesmo": "How - to",
  tutoriais: "How - to",
  "passo a passo": "How - to",
  "passo-a-passo": "How - to",
  "como estudar": "Educational",
  descomplica: "Educational",
  "dicas de estudo": "Educational",
  review: "Commentary",
  "movie review": "Commentary",
  "make up review": "Commentary",
  "makeup review": "Commentary",
  reviews: "Commentary",
  resenha: "Commentary",
  crítica: "Commentary",
  "vale a pena assistir": "Commentary",
  testando: "Commentary",
  "full review": "Commentary",
  " review": "Commentary",
  vlog: "Vlog",
  vlogs: "Vlog",
  Rotina: "Vlog",
  "daily vlog": "Vlog",
  "school vlog": "Vlog",
  "travel vlog": "Vlog",
  "student vlog": "Vlog",
  "college vlog": "Vlog",
  "family vlogs": "Vlog",
  "high school vlog": "Vlog",
  "university vlog": "Vlog",
  Unboxing: "Unboxing",
  recebidos: "Unboxing",
  recebido: "Unboxing",
  "recebidos do mês": "Unboxing",
  Unboxings: "Unboxing",
  "unboxing makeup": "Unboxing",
  "toy unboxing": "Unboxing",
  "asmr unboxing": "Unboxing",
  "xiaomi unboxing": "Unboxing",
  "fortnite unboxing": "Unboxing",
  "mystery box unboxing": "Unboxing",
  "apple unboxing": "Unboxing",
  "unboxing hauls": "Unboxing",
  "tech unboxing": "Unboxing",
  entrevista: "Interview",
  interview: "Interview",
  entrevistas: "Interview",
  "full interview": "Interview",
  "tips interview": "Interview",
  "exclusive interview": "Interview",
  "stand up comedy": "Comedians",
  "stand-up comedy": "Comedians",
  "standup comedy": "Comedians",
  standup: "Comedians",
  comedians: "Comedians",
  comedian: "Comedians",
  comedy: "Comedians",
  "male comedians": "Comedians",
  "female comedians": "Comedians",
  "funny comedian": "Comedians",
  "best comedian ever": "Comedians",
  "funniest comedian ever": "Comedians",
  "Music Video": "Music Video",
  music: "Music Video",
  "Music Videos": "Music Video",
  "New music Video": "Music Video",
  "New music Videos": "Music Video",
  "New music": "Music Video",
  official: "Official",
  "video oficial": "Official",
  "official video": "Official",
  "official music video": "Official",
  "official audio": "Official",
  Cover: "Covers",
  covers: "Covers",
  "pop cover": "Covers",
  "hit cover": "Covers",
  "cover song": "Covers",
  "covers song": "Covers",
  "acoustic cover": "Covers",
  "acoustic covers": "Covers",
  parody: "Parody",
  paródia: "Parody",
  parodia: "Parody",
  paródias: "Parody",
  parodias: "Parody",
  "song parody": "Parody",
  "parody songs": "Parody",
  "cartoon parody": "Parody",
  "disney parody": "Parody",
  "kids parody": "Parody",
  challenge: "Challenge",
  challenges: "Challenge",
  desafio: "Challenge",
  desafios: "Challenge",
  "tente não rir": "Challenge",
  "tente nao rir": "Challenge",
  "food challenge": "Challenge",
  "eating challenge": "Challenge",
  "best challenge": "Challenge",
  competition: "Competition",
  competitions: "Competition",
  "dog competition": "Competition",
  "dance competition": "Competition",
  react: "Reaction",
  reagindo: "Reaction",
  reação: "Reaction",
  reaction: "Reaction",
  "full reaction": "Reaction",
  reacting: "Reaction",
  "reacting to": "Reaction",
  "reaction mashup": "Reaction",
  Pranks: "Pranks",
  Prank: "Pranks",
  "pranks gone wrong": "Pranks",
  "prank gone wrong": "Pranks",
  "funny prank": "Pranks",
  "funny pranks": "Pranks",
  "pranks wars": "Pranks",
  "prank call": "Pranks",
  "couple pranks": "Pranks",
  pranking: "Pranks",
  pegadinha: "Pranks",
  trollagem: "Pranks",
  trollei: "Pranks",
  "trollei minha namorada": "Pranks",
  "trollei meu namorado": "Pranks",
  troll: "Pranks",
  trollando: "Pranks",
  "trollei meu amigo": "Pranks",
  "prank videos": "Pranks",
  narrative: "Narrative",
  narratives: "Narrative",
  "the narrative": "Narrative",
  episode: "Web Series",
  "web series": "Web Series",
  "new web series": "Web Series",
  satire: "Parodies",
  parodies: "Parodies",
  sátira: "Parodies",
  satira: "Parodies",
  sketch: "Sketch",
  sketchs: "Sketch"
})

export default JSON.parse(tagList)
