import { COUNTRIES } from "../consts"

const ALL = {
  NAME: "All",
  ALPHA_2: "",
  ALPHA_3: "ALL",
  CODE: 0
}

const OTHER = {
  NAME: "Other",
  ALPHA_2: "",
  ALPHA_3: "OTH",
  CODE: 0
}

const byKeyValue = (key, value) => {
  if (value && typeof value === "string") {
    const all = /^all$/i
    const other = /^others?|oth$/i
    if (all.test(value)) return ALL
    if (other.test(value)) return OTHER
  }
  if (!key && typeof value === "string") {
    const uv = value.toUpperCase()
    const c = COUNTRIES.find(
      v => v.ALPHA_2 === uv || v.ALPHA_3 === uv || v.NAME.toUpperCase === uv
    )
    if (c) return c
  } else {
    const c = COUNTRIES.find(v => v[key] === value)
    if (c) return c
  }
  return {}
}

const byValue = v => byKeyValue(false, v)

export default {
  byValue,
  byKeyValue
}
