export default [
  {
    NAME: "Algeria",
    ALPHA_2: "DZ",
    ALPHA_3: "DZA",
    CODE: 12
  },
  {
    NAME: "Argentina",
    ALPHA_2: "AR",
    ALPHA_3: "ARG",
    CODE: 32
  },
  {
    NAME: "Australia",
    ALPHA_2: "AU",
    ALPHA_3: "AUS",
    CODE: 36
  },
  {
    NAME: "Austria",
    ALPHA_2: "AT",
    ALPHA_3: "AUT",
    CODE: 40
  },
  {
    NAME: "Bangladesh",
    ALPHA_2: "BD",
    ALPHA_3: "BGD",
    CODE: 50
  },
  {
    NAME: "Belgium",
    ALPHA_2: "BE",
    ALPHA_3: "BEL",
    CODE: 56
  },
  {
    NAME: "Brazil",
    ALPHA_2: "BR",
    ALPHA_3: "BRA",
    CODE: 76
  },
  {
    NAME: "Canada",
    ALPHA_2: "CA",
    ALPHA_3: "CAN",
    CODE: 124
  },
  {
    NAME: "Chile",
    ALPHA_2: "CL",
    ALPHA_3: "CHL",
    CODE: 152
  },
  {
    NAME: "Colombia",
    ALPHA_2: "CO",
    ALPHA_3: "COL",
    CODE: 170
  },
  {
    NAME: "Czech Republic",
    ALPHA_2: "CZ",
    ALPHA_3: "CZE",
    CODE: 203
  },
  {
    NAME: "Egypt",
    ALPHA_2: "EG",
    ALPHA_3: "EGY",
    CODE: 818
  },
  {
    NAME: "Finland",
    ALPHA_2: "FI",
    ALPHA_3: "FIN",
    CODE: 246
  },
  {
    NAME: "France",
    ALPHA_2: "FR",
    ALPHA_3: "FRA",
    CODE: 250
  },
  {
    NAME: "Germany",
    ALPHA_2: "DE",
    ALPHA_3: "DEU",
    CODE: 276
  },
  {
    NAME: "Greece",
    ALPHA_2: "GR",
    ALPHA_3: "GRC",
    CODE: 300
  },
  {
    NAME: "Hong Kong",
    ALPHA_2: "HK",
    ALPHA_3: "HKG",
    CODE: 344
  },
  {
    NAME: "Hungary",
    ALPHA_2: "HU",
    ALPHA_3: "HUN",
    CODE: 348
  },
  {
    NAME: "India",
    ALPHA_2: "IN",
    ALPHA_3: "IND",
    CODE: 356
  },
  {
    NAME: "Indonesia",
    ALPHA_2: "ID",
    ALPHA_3: "IDN",
    CODE: 360
  },
  {
    NAME: "Iraq",
    ALPHA_2: "IQ",
    ALPHA_3: "IRQ",
    CODE: 368
  },
  {
    NAME: "Israel",
    ALPHA_2: "IL",
    ALPHA_3: "ISR",
    CODE: 376
  },
  {
    NAME: "Italy",
    ALPHA_2: "IT",
    ALPHA_3: "ITA",
    CODE: 380
  },
  {
    NAME: "Japan",
    ALPHA_2: "JP",
    ALPHA_3: "JPN",
    CODE: 392
  },
  {
    NAME: "South Korea",
    ALPHA_2: "KR",
    ALPHA_3: "KOR",
    CODE: 410
  },
  {
    NAME: "Malaysia",
    ALPHA_2: "MY",
    ALPHA_3: "MYS",
    CODE: 458
  },
  {
    NAME: "Mexico",
    ALPHA_2: "MX",
    ALPHA_3: "MEX",
    CODE: 484
  },
  {
    NAME: "Morocco",
    ALPHA_2: "MA",
    ALPHA_3: "MAR",
    CODE: 504
  },
  {
    NAME: "Netherlands",
    ALPHA_2: "NL",
    ALPHA_3: "NLD",
    CODE: 528
  },
  {
    NAME: "Pakistan",
    ALPHA_2: "PK",
    ALPHA_3: "PAK",
    CODE: 586
  },
  {
    NAME: "Peru",
    ALPHA_2: "PE",
    ALPHA_3: "PER",
    CODE: 604
  },
  {
    NAME: "Philippines",
    ALPHA_2: "PH",
    ALPHA_3: "PHL",
    CODE: 608
  },
  {
    NAME: "Poland",
    ALPHA_2: "PL",
    ALPHA_3: "POL",
    CODE: 616
  },
  {
    NAME: "Portugal",
    ALPHA_2: "PT",
    ALPHA_3: "PRT",
    CODE: 620
  },
  {
    NAME: "Romania",
    ALPHA_2: "RO",
    ALPHA_3: "ROU",
    CODE: 642
  },
  {
    NAME: "Russian Federation",
    ALPHA_2: "RU",
    ALPHA_3: "RUS",
    CODE: 643
  },
  {
    NAME: "Saudi Arabia",
    ALPHA_2: "SA",
    ALPHA_3: "SAU",
    CODE: 682
  },
  {
    NAME: "Singapore",
    ALPHA_2: "SG",
    ALPHA_3: "SGP",
    CODE: 702
  },
  {
    NAME: "Spain",
    ALPHA_2: "ES",
    ALPHA_3: "ESP",
    CODE: 724
  },
  {
    NAME: "Sweden",
    ALPHA_2: "SE",
    ALPHA_3: "SWE",
    CODE: 752
  },
  {
    NAME: "Switzerland",
    ALPHA_2: "CH",
    ALPHA_3: "CHE",
    CODE: 756
  },
  {
    NAME: "Taiwan",
    ALPHA_2: "TW",
    ALPHA_3: "TWN",
    CODE: 158
  },
  {
    NAME: "Thailand",
    ALPHA_2: "TH",
    ALPHA_3: "THA",
    CODE: 764
  },
  {
    NAME: "Turkey",
    ALPHA_2: "TR",
    ALPHA_3: "TUR",
    CODE: 792
  },
  {
    NAME: "Ukraine",
    ALPHA_2: "UA",
    ALPHA_3: "UKR",
    CODE: 804
  },
  {
    NAME: "United Kingdom",
    ALPHA_2: "GB",
    ALPHA_3: "GBR",
    CODE: 826
  },
  {
    NAME: "United States",
    ALPHA_2: "US",
    ALPHA_3: "USA",
    CODE: 840
  },
  {
    NAME: "Viet Nam",
    ALPHA_2: "VN",
    ALPHA_3: "VNM",
    CODE: 704
  }
]
