import React, { Fragment } from "react";

export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.9593 1.50922L17.8861 7.58247L16.4719 6.16825L22.5451 0.0950054L23.9593 1.50922Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4978 0.998779C15.4978 0.446495 15.9455 -0.0012207 16.4978 -0.0012207H23C23.5523 -0.0012207 24 0.446495 24 0.998779V7.501C24 8.05329 23.5523 8.501 23 8.501C22.4477 8.501 22 8.05329 22 7.501V1.99878H16.4978C15.9455 1.99878 15.4978 1.55106 15.4978 0.998779Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 18.9988C15.866 18.9988 19 15.8648 19 11.9988C19 8.13279 15.866 4.99878 12 4.99878C8.13401 4.99878 5 8.13279 5 11.9988C5 15.8648 8.13401 18.9988 12 18.9988ZM12 20.9988C16.9706 20.9988 21 16.9693 21 11.9988C21 7.02822 16.9706 2.99878 12 2.99878C7.02944 2.99878 3 7.02822 3 11.9988C3 16.9693 7.02944 20.9988 12 20.9988Z"
    />
  </>
);
