import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Header } from "../../shared/shared.ui.header";
import "./index.scss";

export const Card = ({ children, title, hoverEffect, className, ...props }) => {
  const classes = classNames("Insights-ui__card", { hoverEffect }, className);
  return (
    <div className={classes} {...props}>
      {!!title && (
        <Header weight="semi" size="md">
          {title}
        </Header>
      )}
      {children}
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  /** Card's Title */
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /** Defines wether the card should have a hover effect */
  hoverEffect: PropTypes.bool,
  className: PropTypes.string,
};

Card.defaultProps = {
  children: null,
  title: "",
  hoverEffect: false,
  className: "",
};
