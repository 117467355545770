import React from "react"
import PropTypes from "prop-types"
import { Card } from "../../shared/shared.ui.card"
import { useMutation } from "@apollo/react-hooks"
import { withRouter } from "react-router-dom"
import { useKeycloak } from "@react-keycloak/web"

import { ReactComponent as CheckMark } from "../../assets/img/check.svg"
import { ReactComponent as XMark } from "../../assets/img/x.svg"
import HighlightedQuery from "../HighlightedQuery"
import ToastStore from "../../stores/ToastStore"
import { APPROVE_QUERY_MUTATION } from "./query"
import "./index.scss"

const QueryCard = ({ queryObj, client, onUpdateCallback = null, history }) => {
  // hooks
  const { keycloak } = useKeycloak()

  // consts
  const keycloakUsername = keycloak?.tokenParsed?.name

  // queries and mutations
  const [approveQuery, { loading }] = useMutation(APPROVE_QUERY_MUTATION, {
    client,
    onCompleted: ({ updateTopicQueryClassifier: { success, message } }) => {
      if (success) {
        onUpdateCallback && onUpdateCallback()

        ToastStore.clearToasts()
        ToastStore.addToast({
          title: "Query approved.",
          id: "approve-query-success"
        })
      } else {
        console.error(`QueryCard @ approveQuery >>>>> ${message}`)

        ToastStore.clearToasts()
        ToastStore.addToast({
          title: "Oops! Error approving query. Please, try again.",
          id: "approve-query-error"
        })
      }
    },
    onError: error => {
      console.error(`QueryCard @ approveQuery >>>>> ${error}`)

      ToastStore.clearToasts()
      ToastStore.addToast({
        title: "Oops! Error approving query. Please, try again.",
        id: "approve-query-error"
      })
    }
  })

  // functions
  const handleApprove = () => {
    const variables = {
      queryIdStr: queryObj.id
    }

    approveQuery({ variables })
  }

  const handleReject = () => {
    const rootUrl = "/queries/approval"

    history.push({
      pathname: `${rootUrl}/reject`,
      state: {
        backgroundPage: {
          pathname: rootUrl
        },
        queryObj
      }
    })
  }

  // rendering
  const username = queryObj?.user?.name
  const topicTitle = queryObj?.topic?.title
  const isSameUser = username === keycloakUsername

  return (
    <Card hoverEffect className="QueryCard">
      <p className="query-card-title">
        <strong>{username}</strong> has created a new query in{" "}
        <strong>{topicTitle}</strong> topic
      </p>

      <HighlightedQuery className="query-card-query" query={queryObj.query} />

      <div className="query-card-actions">
        <button
          disabled={loading || isSameUser}
          className="query-card-button"
          onClick={handleReject}
        >
          <XMark />
          Reject
        </button>

        <button
          disabled={loading || isSameUser}
          className="query-card-button"
          onClick={handleApprove}
        >
          <CheckMark />
          Approve
        </button>
      </div>
    </Card>
  )
}

QueryCard.propTypes = {
  queryObj: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onUpdateCallback: PropTypes.func
}

export default withRouter(QueryCard)
