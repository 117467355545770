const FACEBOOK = {
  KEY: "fb",
  NAME: "Facebook",
  COLOR: "#3B5999",
  POST_TYPES: {
    IMAGE: 1,
    VIDEO: 2,
    TEXT: 3,
    LINK: 4,
    OFFER: 5,
    OTHER: 6
  }
}

const INSTAGRAM = {
  KEY: "in",
  NAME: "Instagram",
  COLOR: "#FCAF45",
  POST_TYPES: {
    IMAGE: 1,
    VIDEO: 2,
    TEXT: 3,
    OTHER: 4
  }
}

const YOUTUBE = {
  KEY: "yt",
  NAME: "Youtube",
  COLOR: "#FF0000",
  POST_TYPES: {
    VIDEO: 2,
    OTHER: null
  }
}

const TIKTOK = {
  KEY: "tt",
  NAME: "TikTok",
  COLOR: "#000000",
  POST_TYPES: {
    VIDEO: 2,
  }
}

const Providers = {
  NAMES: [
    {
      KEY: "fb",
      TITLE: "facebook"
    },
    {
      KEY: "in",
      TITLE: "instagram"
    },
    {
      KEY: "yt",
      TITLE: "youtube"
    },
    {
      KEY: "tt",
      TITLE: "TIKTOK"
    }
  ],
  yt: YOUTUBE,
  fb: FACEBOOK,
  in: INSTAGRAM,
  tt: TIKTOK
}

export default Providers
