import React, { Fragment } from "react";

export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 5.38135C1 2.61992 3.23858 0.381348 6 0.381348C8.76142 0.381348 11 2.61992 11 5.38135C11 8.14277 8.76142 10.3813 6 10.3813C3.23858 10.3813 1 8.14277 1 5.38135ZM6 2.38135C4.34315 2.38135 3 3.72449 3 5.38135C3 7.0382 4.34315 8.38135 6 8.38135C7.65685 8.38135 9 7.0382 9 5.38135C9 3.72449 7.65685 2.38135 6 2.38135Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 12.8813C12 10.9484 13.567 9.38135 15.5 9.38135C17.433 9.38135 19 10.9484 19 12.8813C19 14.8143 17.433 16.3813 15.5 16.3813C13.567 16.3813 12 14.8143 12 12.8813ZM15.5 11.3813C14.6716 11.3813 14 12.0529 14 12.8813C14 13.7098 14.6716 14.3813 15.5 14.3813C16.3284 14.3813 17 13.7098 17 12.8813C17 12.0529 16.3284 11.3813 15.5 11.3813Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 17.8813C0 14.8438 2.46243 12.3813 5.5 12.3813C8.53757 12.3813 11 14.8438 11 17.8813C11 20.9189 8.53757 23.3813 5.5 23.3813C2.46243 23.3813 0 20.9189 0 17.8813ZM5.5 14.3813C3.567 14.3813 2 15.9484 2 17.8813C2 19.8143 3.567 21.3813 5.5 21.3813C7.433 21.3813 9 19.8143 9 17.8813C9 15.9484 7.433 14.3813 5.5 14.3813Z"
    />
  </>
);
