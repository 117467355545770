import React, { Component } from "react"
import PropTypes from "prop-types"
import { Switch, Route, withRouter, Redirect } from "react-router-dom"
import { withApollo, compose } from "react-apollo"
import { observer, inject } from "mobx-react"

import PrivateRoute from "./PrivateRoute"
import HomePage from "./pages/HomePage"
import TaggingPage from "./pages/TaggingPage"
import WellDonePage from "./pages/WellDonePage"
import SearchPageOld from "./pages/SearchPageOld"
import TopicListPage from "./pages/TopicListPage"
import TopicCreatePage from "./pages/TopicCreatePage"
import TopicEditPage from "./pages/TopicEditPage"
import SubtopicListPage from "./pages/SubtopicListPage"
import SubtopicCreatePage from "./pages/SubtopicCreatePage"
import SubtopicEditPage from "./pages/SubtopicEditPage"
import LoginPage from "./pages/LoginPage"
import WizardPageNew from "./pages/WizardPageNew"
import CreatorsPage from "./pages/CreatorsPage"
import QueriesPage from "./pages/QueriesPage"
import TopicSuccessModal from "./components/TopicSuccessModal"
import SubtopicDeleteModal from "./components/SubtopicDeleteModal"
import SubtopicSuccessModal from "./components/SubtopicSuccessModal"
import QueryEditModal from "./components/QueryEditModal"
import QueryDeleteModal from "./components/QueryDeleteModal"
import QueryRejectModal from "./components/QueryRejectModal"
import QueryModerateModal from "./components/QueryModerateModal"

@inject("UserStore")
@observer
class Router extends Component {
  render() {
    const { UserStore, location } = this.props
    const backgroundPage = location?.state?.backgroundPage

    return (
      <>
        <Switch location={backgroundPage || location}>
          <Route path="/login" exact component={LoginPage} />
          <PrivateRoute path="/" exact component={HomePage} />
          <PrivateRoute path="/home" exact component={HomePage} />
          <PrivateRoute path="/search" exact component={SearchPageOld} />
          <PrivateRoute path="/tagging" exact component={TaggingPage} />
          <PrivateRoute path="/well-done" exact component={WellDonePage} />
          <PrivateRoute exact path="/topics" component={TopicListPage} />
          <PrivateRoute exact path="/topics/new" component={TopicCreatePage} />
          <PrivateRoute
            path="/topics/edit/:topicId"
            component={TopicEditPage}
          />
          <PrivateRoute exact path="/subtopics" component={SubtopicListPage} />
          <PrivateRoute
            exact
            path="/subtopics/new"
            component={SubtopicCreatePage}
          />
          <PrivateRoute
            exact
            path="/subtopics/edit/:subtopicId"
            component={SubtopicEditPage}
          />
          <PrivateRoute
            path="/wizard/:provider/:postId"
            exact
            render={props => (
              <WizardPageNew user={UserStore.userInfo} {...props} />
            )}
          />
          <PrivateRoute path="/creators" component={CreatorsPage} />
          <PrivateRoute path="/queries" component={QueriesPage} />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>

        {backgroundPage && (
          <>
            <PrivateRoute
              exact
              path="/topics/edit/:topicId/queries/edit"
              component={QueryEditModal}
            />
            <PrivateRoute
              exact
              path="/topics/edit/:topicId/queries/delete"
              component={QueryDeleteModal}
            />
            <PrivateRoute
              exact
              path="/queries/approval/reject"
              component={QueryRejectModal}
            />
            <PrivateRoute
              exact
              path="/queries/status/moderate"
              component={QueryModerateModal}
            />
            <PrivateRoute
              exact
              path="/queries/status/delete"
              component={QueryDeleteModal}
            />
            <PrivateRoute
              exact
              path="/queries/status/edit"
              component={QueryEditModal}
            />
            <PrivateRoute
              exact
              path="/topic/success"
              component={TopicSuccessModal}
            />
            <PrivateRoute
              exact
              path="/subtopic/delete"
              component={SubtopicDeleteModal}
            />
            <PrivateRoute
              exact
              path="/subtopic/success/:action"
              component={SubtopicSuccessModal}
            />
          </>
        )}
      </>
    )
  }
}

Router.propTypes = {
  UserStore: PropTypes.object,
  location: PropTypes.object
}

export default compose(withApollo)(withRouter(Router))
