import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./index.scss";

export const Loading = ({ small, medium, large, className, topMargin }) => {
  const containerClass = classNames("Insights-ui__loading", className);
  const classes = classNames("loading", {
    small,
    medium,
    large,
    topMargin,
  });
  return (
    <div className={containerClass}>
      <div className={classes}>
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </div>
    </div>
  );
};

Loading.propTypes = {
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  topMargin: PropTypes.bool,
  className: PropTypes.string,
};

Loading.defaultProps = {
  small: true,
  medium: false,
  topMargin: false,
  large: false,
  className: "",
};
