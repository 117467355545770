import React, { useState } from "react"
import { useMutation } from "@apollo/react-hooks"
import PropTypes from "prop-types"
import { Button } from "../../shared/shared.ui.button"

import CenteredModal from "../CenteredModal"
import Textarea from "../Textarea"
import ToastStore from "../../stores/ToastStore"
import { REJECT_QUERY_MUTATION } from "./query"
import { client } from "../../ApolloProvider"
import "./index.scss"

const QueryRejectModal = ({ history, location: { state } }) => {
  // consts
  const { backgroundPage, queryObj } = state

  // state
  const [rejectFeedback, setRejectFeedback] = useState("")

  // queries and mutations
  const [rejectQuery, { data, loading, error }] = useMutation(
    REJECT_QUERY_MUTATION,
    {
      client,
      onCompleted: ({ updateTopicQueryClassifier: { success, message } }) => {
        if (success) {
          history.push({
            pathname: backgroundPage.pathname,
            state: { refetch: true }
          })

          ToastStore.clearToasts()
          ToastStore.addToast({
            title: "Query rejected.",
            id: "reject-query-success"
          })
        } else {
          console.error(`QueryRejectModal @ rejectQuery >>>>> ${message}`)
        }
      },
      onError: error => {
        console.error(`QueryRejectModal @ rejectQuery >>>>> ${error}`)
      }
    }
  )

  // functions
  const closeModal = () => {
    history.push({
      pathname: backgroundPage.pathname
    })
  }

  const handleQueryReject = () => {
    const variables = {
      queryIdStr: queryObj.id,
      feedback: rejectFeedback
    }

    rejectQuery({ variables })
  }

  // rendering
  const isRejectButtonDisabled = !rejectFeedback?.length
  return (
    <CenteredModal className="QueryRejectModal" closeModal={closeModal}>
      <p>Why do you want to reject this query?</p>

      <Textarea
        value={rejectFeedback}
        onChange={event => setRejectFeedback(event.target.value)}
        rows={14}
      />

      <div className="reject-actions">
        <Button disabled={loading} appearance="outline" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          disabled={loading || isRejectButtonDisabled}
          onClick={handleQueryReject}
        >
          {loading ? "Rejecting..." : "Reject query"}
        </Button>
      </div>

      {(error || data?.updateTopicQueryClassifier?.success === false) && (
        <p className="reject-error">
          Oops! An error occurred. Please, try rejecting again.
        </p>
      )}
    </CenteredModal>
  )
}

QueryRejectModal.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default QueryRejectModal
