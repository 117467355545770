function DownloadURI(uri, name) {
  const link = document.createElement("a")
  link.download = name
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

function DownloadBlob(blob, filename) {
  const a = document.createElement("a")
  a.style = "display: none"
  document.body.appendChild(a)

  const url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = filename
  a.click()
  window.URL.revokeObjectURL(url)
}

export default {
  DownloadURI,
  DownloadBlob
}
