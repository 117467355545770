import React, { Fragment } from "react";

export default (
  <>
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5419 10.622L23.5505 10.6345C24.0984 11.4337 24.2153 12.603 23.5055 13.4277C22.7424 14.4674 21.2663 16.3155 19.3257 17.9206C17.3779 19.5316 14.8388 21 12 21C9.16115 21 6.62204 19.5316 4.67426 17.9206C2.71016 16.296 1.2219 14.4225 0.467032 13.3903L0.458049 13.378L0.449444 13.3655C-0.0984472 12.5663 -0.215325 11.397 0.494455 10.5723C1.2576 9.53266 2.73367 7.68454 4.67426 6.07943C6.62204 4.46838 9.16115 3 12 3C14.8388 3 17.3779 4.46838 19.3257 6.07943C21.2898 7.70399 22.7781 9.57748 23.5329 10.6097L23.5419 10.622ZM22.7257 12.8C23.0914 12.4 23.0914 11.7333 22.7257 11.2C21.2631 9.2 16.9972 4 12 4C7.00275 4 2.73682 9.2 1.27422 11.2C0.908567 11.6 0.908567 12.2667 1.27422 12.8C2.73682 14.8 7.00275 20 12 20C16.9972 20 21.2631 14.8 22.7257 12.8Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12ZM16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z"
      />
    </g>
  </>
);
