import { useState, useEffect } from "react"
import ToastStore from "../stores/ToastStore.js"
import gql from "graphql-tag"

const BLOCK_CREATOR_TOPIC_QUERY = gql`
  mutation BLOCK_CREATOR_TOPIC_QUERY(
    $creatorId: String!
    $provider: String!
    $topic: [Int!]!
    $deleted: Boolean! = false
  ) {
    blockCreatorTopic(
      creatorId: $creatorId
      provider: $provider
      topic: $topic
      deleted: $deleted
    )
  }
`

const BLOCK_CREATOR_LANGUAGE = gql`
  mutation BLOCK_CREATOR_LANGUAGE(
    $creatorId: String!
    $provider: String!
    $language: [String!]!
    $deleted: Boolean! = false
  ) {
    blockCreatorLanguage(
      creatorId: $creatorId
      provider: $provider
      language: $language
      deleted: $deleted
    )
  }
`

const useBlockCreator = function({
  topics,
  language,
  isRequestBlockCreator,
  client,
  creatorIdProvider
}) {
  const [isBlockCreatorTopic, setBlockCreatorTopic] = useState(false)
  const [isLoadingTopic, setLoadingTopic] = useState(false)
  const [isLoadingLanguage, setLoadingLanguage] = useState(false)
  const [isBlockCreatorLanguage, setBlockCreatorLanguage] = useState(false)

  useEffect(() => {
    const topicConditional = () => {
      if (Array.isArray(topics)) return topics.map(topic => topic.id)
      if (topics) return [topics.id]
      return null
    }
    const infoCreator = {
      language: language.value,
      topics: topicConditional()
    }

    async function blockCreatorLanguage() {
      try {
        if (isLoadingLanguage) return
        setLoadingLanguage(true)
        setBlockCreatorLanguage(false)

        const mutation = BLOCK_CREATOR_LANGUAGE

        const variables = {
          creatorId: creatorIdProvider.creatorId,
          provider: creatorIdProvider.provider,
          language: infoCreator.language
        }

        const {
          data: { blockCreatorLanguage }
        } = await client.mutate({
          mutation,
          variables
        })
        if (!blockCreatorLanguage) {
          ToastStore.clearToasts()
          ToastStore.addToast({
            title: "Oops! Unable to save. Please try again.",
            id: "error"
          })
        }
        setLoadingLanguage(false)
        setBlockCreatorLanguage(blockCreatorLanguage)
      } catch (e) {
        console.error("error getting blockCreatorLanguage >>>>>>>>>>>", e)
        setLoadingLanguage(false)
        setBlockCreatorLanguage(false)
      }
    }

    async function blockCreatorTopic() {
      try {
        if (isLoadingTopic) return
        setBlockCreatorTopic(false)
        setLoadingTopic(true)

        const mutation = BLOCK_CREATOR_TOPIC_QUERY

        const variables = {
          creatorId: creatorIdProvider.creatorId,
          provider: creatorIdProvider.provider,
          topic: infoCreator.topics
        }

        const {
          data: { blockCreatorTopic }
        } = await client.mutate({
          mutation,
          variables
        })
        if (!blockCreatorTopic) {
          ToastStore.clearToasts()
          ToastStore.addToast({
            title: "Oops! Unable to save. Please try again.",
            id: "error"
          })
        }
        setBlockCreatorTopic(blockCreatorTopic)
        setLoadingTopic(false)
      } catch (e) {
        console.error("error getting blockCreatorTopic >>>>>>>>>>>", e)
        setBlockCreatorTopic(false)
        setLoadingTopic(false)
      }
    }

    if (infoCreator.language !== "none") blockCreatorLanguage()
    if (infoCreator.topics) blockCreatorTopic()
  }, [isRequestBlockCreator])

  return {
    isBlockCreatorTopic,
    setBlockCreatorTopic,
    isBlockCreatorLanguage,
    isLoadingTopic,
    isLoadingLanguage,
    setBlockCreatorLanguage
  }
}

export default useBlockCreator
