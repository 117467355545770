import envUrls from "./envUrl"

const types = {
  image: "images",
  svg: "svg"
}

const defaultUrl = (imageName, type = types.image) =>
  `${envUrls.files}/assets/images/${type}/${imageName}`

const IMAGES = {
  illustrationLogin: defaultUrl("illustration-login-step.svg", types.svg),
  illustrationDiscovery: defaultUrl("illustration-discovery.svg", types.svg),
  illustrationVideo: defaultUrl("illustration-video.svg", types.svg),
  illustrationInfluencers: defaultUrl(
    "illustration-influencers.svg",
    types.svg
  ),
  illustrationCreatorNotFound: defaultUrl(
    "illustration-creator-not-found.svg",
    types.svg
  ),
  illustrationLock: defaultUrl("illustration-lock.svg", types.svg),
  illustrationFallingWoman: defaultUrl(
    "illustration-falling-woman.svg",
    types.svg
  ),
  illustrationLostCouple: defaultUrl("illustration-lost-couple.svg", types.svg),
  illustrationLostCoupleDark: defaultUrl(
    "illustration-lost-couple-dark.svg",
    types.svg
  ),
  illustrationOnboardingStart: defaultUrl(
    "illustration_onboarding_start.svg",
    types.svg
  ),
  illustrationModalMobile: defaultUrl(
    "Illustration-modal-mobile.svg",
    types.svg
  )
}

export default IMAGES
